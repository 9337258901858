import React from 'react';
import { Link } from 'react-router-dom';
import bn from 'utils/bemnames';

const bem = bn.create('header');

class Header extends React.Component {
  render() {
    return (
      <div className={`${bem.b()} d-flex align-items-center`}>
        <div className='ml-3 sidebar-icon'>
          <i
            className='fa fa-fw fa-bars'
            onClick={() => this.props.openCloseSidebar()}></i>
        </div>
        <div className='title'>High Performance Market Maker Rabbit</div>
        <div className='logout-section ml-auto mr-3'>
          <Link to='/logout'>
            <i className='fas fa-sign-in-alt mr-1' />
            <span>Logout</span>
          </Link>
        </div>
      </div>
    );
  }
}

export default Header;
