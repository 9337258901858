import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActivityGeneratorModal from './components';
import ActivityGenerator from '../../../redux/modules/ActivityGenerator';
import UI from '../../../redux/modules/UI';

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      hideAddUpdateModal: UI.actions.hideAddUpdateModal,
      acceptAddUpdateModal: UI.actions.acceptAddUpdateModal,
      loadSymbols: ActivityGenerator.actions.loadSymbols
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    activityGenerator: ActivityGenerator.selectors.activityGenerator(state),
    exchanges: ActivityGenerator.selectors.exchanges(state),
    targetSymbols: ActivityGenerator.selectors.targetSymbols(state),
    activityGeneratorAlgorithm: ActivityGenerator.selectors.activityGeneratorAlgorithm(
      state
    ),
    addUpdateModal: UI.selectors.addUpdateModal(state)
  };
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ActivityGeneratorModal);
