import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MarketMakerModal from './components';
import MarketMaker from '../../../redux/modules/MarketMaker';
import UI from '../../../redux/modules/UI';

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      hideAddUpdateModal: UI.actions.hideAddUpdateModal,
      acceptAddUpdateModal: UI.actions.acceptAddUpdateModal,
      loadSymbols: MarketMaker.actions.loadSymbols
      //setField: MarketMaker.actions.setField;
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    marketMaker: MarketMaker.selectors.marketMaker(state),
    exchanges: MarketMaker.selectors.exchanges(state),
    sourceSymbols: MarketMaker.selectors.sourceSymbols(state),
    targetSymbols: MarketMaker.selectors.targetSymbols(state),
    addUpdateModal: UI.selectors.addUpdateModal(state)
  };
};

export default connect(mapStateToProps, mapActionsToProps)(MarketMakerModal);
