import * as c from './constants';

const initialState = {
  status: {
    pending: false,
    error: false,
    success: false
  },
  exchangeKeys: [],
  exchangeKey: null,
  isDataUpdated: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.LOAD_EXCHANGE_KEYS_PENDING:
    case c.DELETE_EXCHANGE_KEY_PENDING:
    case c.ADD_UPDATE_EXCHANGE_KEY_PENDING: {
      return {
        ...state,
        status: { pending: true, error: false, success: false }
      };
    }

    case c.LOAD_EXCHANGE_KEYS_SUCCESS: {
      return {
        ...state,
        status: { pending: false, error: false, success: true },
        exchangeKeys: action.payload.exchangeKeys,
        isDataUpdated: true
      };
    }

    case c.LOAD_EXCHANGE_KEY_SUCCESS: {
      return {
        ...state,
        exchangeKey: action.payload
      };
    }

    case c.LOAD_EXCHANGE_KEYS_ERROR:
    case c.DELETE_EXCHANGE_KEY_ERROR:
    case c.ADD_UPDATE_EXCHANGE_KEY_ERROR: {
      return {
        ...state,
        status: { pending: false, error: action.payload, success: false }
      };
    }

    case c.DELETE_EXCHANGE_KEY_SUCCESS:
    case c.ADD_UPDATE_EXCHANGE_KEY_SUCCESS: {
      return {
        ...state,
        status: { pending: false, error: false, success: true },
        exchangeKeys: action.payload,
        isDataUpdated: true
      };
    }

    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.path]: action.payload.value
      };
    }

    case c.SET_DATA_UPDATED: {
      return {
        ...state,
        isDataUpdated: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
