import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';

import Exchanges from '../../../../../../redux/modules/Exchanges';

const ParamountdaxClient = ({ exchange, setUIField }) => {
  const [selectedKey, setSelectedKey] = useState(null);
  const [accessKeys, setAccessKeys] = useState(null);

  useEffect(() => {
    if (!exchange.accessKeys.length) return;
    const key = exchange.accessKeys[0].key;
    const accessKeys = [{ value: key, label: key }];
    accessKeys.push({
      value: key === 'apiKey' ? 'apiKeyV2' : 'apiKey',
      label: key === 'apiKey' ? 'apiKeyV2' : 'apiKey'
    });
    setAccessKeys(accessKeys);
    setSelectedKey(accessKeys[0]);

    return () => resetState();
  }, [exchange.accessKeys]);

  const resetState = () => {
    setSelectedKey(null);
    setAccessKeys(null);
  };

  const handleChangeAccessKey = ({ target: { value } }) => {
    if (!Array.isArray(exchange.accessKeys)) return;

    const accessKeys = [{ key: selectedKey.value, value }];
    setUIField('exchange', { ...exchange, accessKeys });
  };

  const handleSelectKey = (selectedKey) => {
    setSelectedKey(selectedKey);
    const keys = { ...exchange.accessKeys[0], key: selectedKey.value };
    setUIField('exchange', { ...exchange, accessKeys: [keys] });
  };

  if (!accessKeys) return null;

  return (
    <div className='row'>
      <div className='form-group col-6'>
        <label className='first-letter-upper'>Key name</label>
        <Select
          value={selectedKey}
          options={accessKeys}
          onChange={handleSelectKey}
        />
      </div>
      <div className='form-group col-6'>
        <label className='first-letter-upper'>Key Value</label>
        <input
          type='text'
          className='form-control'
          value={exchange.accessKeys[0].value}
          onChange={handleChangeAccessKey}
        />
      </div>
    </div>
  );
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      setUIField: Exchanges.actions.setField
    },
    dispatch
  );
};

export default connect(null, mapActionsToProps)(ParamountdaxClient);
