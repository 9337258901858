import * as ac from './actionCreators';
import * as constants from '../../../constants';
import * as api from '../../../api';
import { getErrorMessage } from '../../../utils/error';

export function loadInitialData() {
  return async (dispatch) => {
    dispatch(ac.loadInitialData.pending());

    try {
      const hedgingPairs = await api.getHedgingPairs();
      hedgingPairs.sort((a, b) => (a.pair > b.pair ? 1 : -1));

      const currencies = await api.getCurrencies();
      currencies.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.loadInitialData.success({ hedgingPairs, currencies }));
    } catch (err) {
      dispatch(ac.loadInitialData.error(getErrorMessage(err)));
    }
  };
}

export function deleteHedgingPair(name) {
  return async (dispatch) => {
    dispatch(ac.deleteHedgingPair.pending());

    try {
      await api.deleteHedgingPair(name);
      const hedgingPairs = await api.getHedgingPairs();
      hedgingPairs.sort((a, b) => (a.pair > b.pair ? 1 : -1));

      dispatch(ac.deleteHedgingPair.success(hedgingPairs));
    } catch (err) {
      dispatch(ac.deleteHedgingPair.error(getErrorMessage(err)));
    }
  };
}

export function addUpdateHedgingPair(hedgingPair) {
  return async (dispatch, getState) => {
    dispatch(ac.addUpdateHedgingPair.pending());

    try {
      //WE DONT HAVE ABILITY TO UPDATE
      // const { actionType } = getState().UI.addUpdateModal;

      //
      // if (actionType === constants.UPDATE) {
      //   await api.updateHedgingPair(hedgingPair.pair, hedgingPair);
      // } else {
      await api.addHedgingPair(hedgingPair);
      // }
      const hedgingPairs = await api.getHedgingPairs();
      hedgingPairs.sort((a, b) => (a.pair > b.pair ? 1 : -1));

      dispatch(ac.addUpdateHedgingPair.success(hedgingPairs));
    } catch (err) {
      dispatch(ac.addUpdateHedgingPair.error(getErrorMessage(err)));
    }
  };
}

export function startHedgingPair(entity) {
  return async (dispatch) => {
    dispatch(ac.startHedgingPair.pending());

    try {
      await api.startHedgingPair(entity);
      const hedgingPairs = await api.getHedgingPairs();
      hedgingPairs.sort((a, b) => (a.pair > b.pair ? 1 : -1));

      dispatch(ac.startHedgingPair.success(hedgingPairs));
    } catch (err) {
      dispatch(ac.startHedgingPair.error(getErrorMessage(err)));
    }
  };
}

export function stopHedgingPair(entity) {
  return async (dispatch) => {
    dispatch(ac.stopHedgingPair.pending());

    try {
      await api.stopHedgingPair(entity);
      const hedgingPairs = await api.getHedgingPairs();
      hedgingPairs.sort((a, b) => (a.pair > b.pair ? 1 : -1));

      dispatch(ac.stopHedgingPair.success(hedgingPairs));
    } catch (err) {
      dispatch(ac.stopHedgingPair.error(getErrorMessage(err)));
    }
  };
}

export function setField(path, value) {
  return (dispatch) => dispatch(ac.setField({ path, value }));
}

export function setDataUpdated(isDataUpdated) {
  return (dispatch) => dispatch(ac.setDataUpdated(isDataUpdated));
}
