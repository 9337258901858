import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CurrencyModal from './components';
import UI from '../../../redux/modules/UI';
import Currencies from '../../../redux/modules/Currencies';

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      hideAddUpdateModal: UI.actions.hideAddUpdateModal,
      acceptAddUpdateModal: UI.actions.acceptAddUpdateModal
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    currency: Currencies.selectors.currency(state),
    addUpdateModal: UI.selectors.addUpdateModal(state)
  };
};

export default connect(mapStateToProps, mapActionsToProps)(CurrencyModal);
