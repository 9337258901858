import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import ErrorComp from '../../../../components/ErrorComp';
import ExchangeSettings from './ExchangeSettings';

import bn from '../../../../utils/bemnames';
import * as constants from '../../../../constants';

const className = 'cr-modal-info';
const bem = bn.create('modal');

class ExchangeInfoModal extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      status: { pending, success },
      exchange,
      addUpdateModal
    } = this.props;

    if (
      prevProps.status.pending !== pending &&
      !pending &&
      success &&
      addUpdateModal.show &&
      exchange
    ) {
      this.props.showAlertModal({
        id: exchange.name,
        entityName: constants.EXCHANGE,
        actionType: constants.EDIT_EXCHANGE,
        withoutAcceptButton: true
      });
    }
  }

  renderMapping = () => {
    const { exchange } = this.props;
    if (!(exchange && exchange.mapping)) return null;

    return exchange.mapping.map((element, index) => {
      return (
        <div key={index} className='row'>
          <div className='form-group col-6'>
            <label htmlFor='internalSymbol'>Internal Symbol</label>
            <input
              type='text'
              value={element['internalSymbol']}
              className='form-control'
              disabled
            />
          </div>
          <div className='form-group col-6'>
            <label htmlFor='exchangeSymbol'>Exchange Symbol</label>
            <input
              type='text'
              value={element['exchangeSymbol']}
              className='form-control'
              disabled
            />
          </div>
        </div>
      );
    });
  };

  constructForm = () => {
    const {
      exchange,
      status: { error },
      editSettings
    } = this.props;

    if (!exchange) return;

    const showSettings = () => exchange.exchangeClient !== 'feed_generator';

    return (
      <>
        {error && <ErrorComp error={error}></ErrorComp>}
        <Tabs>
          <TabList>
            <Tab>Basic</Tab>
            {showSettings() && <Tab>Settings</Tab>}
            <Tab>Mapping</Tab>
          </TabList>

          <TabPanel>
            <div className='row'>
              <div className='form-group col-6'>
                <label htmlFor='name'>Exchange</label>
                <input
                  type='text'
                  value={exchange.name}
                  className='form-control'
                  disabled
                />
              </div>
              <div className='form-group col-6'>
                <label htmlFor='name'>Exchange Client</label>
                <input
                  type='text'
                  value={exchange.exchangeClient}
                  className='form-control'
                  disabled
                />
              </div>
            </div>
          </TabPanel>
          {showSettings() && (
            <TabPanel>
              <ExchangeSettings
                editSettings={editSettings}
                exchange={exchange}
              />
            </TabPanel>
          )}
          <TabPanel>{this.renderMapping()}</TabPanel>
        </Tabs>
      </>
    );
  };

  render() {
    const { show, actionType, entityName } = this.props.addUpdateModal;
    const { hideAddUpdateModal } = this.props;

    const toggleModal = () => {
      hideAddUpdateModal();
    };

    return (
      <Modal
        className={className}
        isOpen={
          show &&
          entityName === constants.EXCHANGE &&
          actionType === constants.INFO
        }
        toggle={toggleModal}>
        <ModalHeader className={`${bem.e('header')}`} toggle={toggleModal}>
          {`Exchange Details`}
        </ModalHeader>
        <ModalBody>{this.constructForm()}</ModalBody>
      </Modal>
    );
  }
}

export default ExchangeInfoModal;
