import * as ac from './actionCreators';
import * as api from '../../../api';
import { getErrorMessage } from '../../../utils/error';
import moment from 'moment';

export function loadData() {
  return async (dispatch, getState) => {
    dispatch(ac.loadData.pending());

    try {
      const startDay = moment(getState().PnlBalance.filters.startDay).format(
        'YYYY-MM-DD'
      );
      const endDay = moment(getState().PnlBalance.filters.endDay).format(
        'YYYY-MM-DD'
      );
      const data = await api.getPnlBalances(startDay, endDay);

      dispatch(ac.loadData.success({ pnlBalances: data.reports }));
    } catch (err) {
      dispatch(ac.loadData.error(getErrorMessage(err)));
    }
  };
}

export function resetMeta() {
  return (dispatch) => dispatch(ac.resetMeta());
}

export function setPage(page) {
  return (dispatch) => {
    return dispatch(ac.setPage(page));
  };
}

export function setLimit(limit) {
  return (dispatch) => {
    return dispatch(ac.setLimit(limit));
  };
}

export function setField(path, value) {
  return (dispatch) => {
    return dispatch(ac.setField({ path, value }));
  };
}

export function resetPage() {
  return (dispatch) => {
    return dispatch(ac.resetPage());
  };
}
