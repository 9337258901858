import NewPage from 'components/NewPage';
import React from 'react';
import { Col, Row } from 'reactstrap';
import CustomTable from '../../../../components/CustomTable';
import ErrorComp from '../../../../components/ErrorComp';
import * as constants from '../../../../constants';

class HedgingConfig extends React.Component {
  constructor(props) {
    super(props);

    this.table = React.createRef();

    this.state = {
      tableHedgingPairs: [],
      tableColumnNames: this.getTableColumnNames()
    };

    this.props.setUIField('currentEntityName', constants.HEDGING);
  }

  componentDidMount() {
    this.props.loadInitialData();
    this.props.addOpenedTab({
      to: '/hedging/configuration',
      name: 'Hedging configuration'
    });
  }

  componentDidUpdate() {
    const { openedTabs } = this.props;

    if (
      !openedTabs.find((openedTab) => openedTab.to === '/hedging/configuration')
    ) {
      this.props.history.push(openedTabs[openedTabs.length - 1].to);
    } else {
      if (this.props.isDataUpdated) {
        this.setState({ tableHedgingPairs: this.getTableHedgingPairs() });
        this.props.setDataUpdated(false);
      }
    }
  }

  getTableColumnNames = () => {
    return [
      {
        header: '№',
        accessor: 'id'
      },
      {
        header: 'Pair',
        accessor: 'pair'
      },
      {
        header: 'Last Update',
        accessor: 'lastUpdated'
      },
      {
        header: 'Actions',
        accessor: 'actions',
        className: 'actions'
      }
    ];
  };

  getTableHedgingPairs = () => {
    const { hedgingPairs } = this.props;

    return hedgingPairs.map((hedgingPair, index) => {
      const actions = (
        <div className='d-flex align-content-center justify-content-center'>
          {/* <button
            className='btn btn-sm btn-primary mr-2 h-26'
            onClick={() => this.handleUpdateIconClick(marketMaker.name)}>
            <i className='fa fa-edit'></i> Edit
          </button> */}
          <button
            className='btn btn-sm btn-primary mr-2'
            onClick={() => this.handleDeleteIconClick(hedgingPair.pair)}>
            <i className='fas fa-trash-alt'></i> Delete
          </button>
          {hedgingPair.is_turned_on ? (
            <button
              className='btn btn-sm btn-danger'
              onClick={() => this.handleStopIconClick(hedgingPair)}>
              <i className='fas fa-stop'></i> Stop
            </button>
          ) : (
            <button
              className='btn btn-sm btn-primary'
              onClick={() => this.handleStartIconClick(hedgingPair)}>
              <i className='fas fa-play'></i> Start
            </button>
          )}
        </div>
      );
      return {
        id: index,
        pair: hedgingPair.pair,
        lastUpdated: '2020/05/17 07:29:01',
        actions
      };
    });
  };

  handleDeleteIconClick = (name) => {
    this.props.showAlertModal({
      id: name,
      entityName: constants.HEDGING,
      actionType: constants.DELETE
    });
  };
  //WE DONT HAVE ABILITY TO UPDATE
  //   handleUpdateIconClick = async (name) => {
  //     await this.props.loadMarketMaker(name);

  //     this.props.showAddUpdateModal({
  //       entityName: constants.MARKET_MAKER,
  //       actionType: constants.UPDATE
  //     });
  //   };

  handleStartIconClick = async (entity) => {
    this.props.showAlertModal({
      id: entity.pair,
      entityName: constants.HEDGING,
      actionType: constants.HEDGING_START,
      entity: {
        ...entity,
        is_turned_on: true
      }
    });
  };

  handleStopIconClick = async (entity) => {
    this.props.showAlertModal({
      id: entity.pair,
      entityName: constants.HEDGING,
      actionType: constants.HEDGING_STOP,
      entity: {
        ...entity,
        is_turned_on: false
      }
    });
  };

  render() {
    const {
      status: { error }
    } = this.props;

    const { tableColumnNames, tableHedgingPairs } = this.state;

    return (
      <NewPage
        title=''
        breadcrumbs={[{ name: 'Hedging configuration', active: true }]}>
        <Row>
          <Col md='12' sm='12' xs='12'>
            {error && <ErrorComp error={error}></ErrorComp>}
            <CustomTable
              className='market-maker-table'
              columns={tableColumnNames}
              data={tableHedgingPairs}
            />
          </Col>
        </Row>
      </NewPage>
    );
  }
}

export default HedgingConfig;
