import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

class Chart extends React.Component {
  constructor(props) {
    super();
  }

  componentDidMount() {
    am4core.addLicense('ch-custom-attribution');
    let chart = am4core.create('chartdiv', am4charts.XYChart);

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    const pnlTotalSeries = chart.series.push(new am4charts.LineSeries());
    pnlTotalSeries.dataFields.valueY = 'pnlTotal';
    pnlTotalSeries.dataFields.dateX = 'date';
    pnlTotalSeries.tooltipText = '{pnlTotal}';
    pnlTotalSeries.tooltip.pointerOrientation = 'vertical';
    pnlTotalSeries.name = 'PNL Total';
    pnlTotalSeries.strokeWidth = 3;
    pnlTotalSeries.stroke = am4core.color('green');
    pnlTotalSeries.tooltip.getFillFromObject = false;
    pnlTotalSeries.tooltip.background.fill = am4core.color('green');

    chart.cursor = new am4charts.XYCursor();
    chart.legend = new am4charts.Legend();

    this.chart = chart;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isDataUpdated !== this.props.isDataUpdated &&
      this.props.isDataUpdated
    ) {
      const data = this.props.data.map((item) => {
        const date = new Date(item.timestamp);
        const pnlTotal = item.pnlTotal;

        return {
          date,
          pnlTotal
        };
      });

      this.chart.data = data;
    }

    if (
      prevProps.isDataLoading !== this.props.isDataLoading &&
      this.props.isDataLoading
    ) {
      this.chart.data = [];
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return <div id='chartdiv' style={{ width: '100%', height: '500px' }}></div>;
  }
}

export default Chart;
