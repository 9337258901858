import React from 'react';
import { Alert } from 'reactstrap';

const ErrorComp = (props) => {
  return (
    <div>
      <Alert color='danger'>{props.error}</Alert>
    </div>
  );
};

export default ErrorComp;
