import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ExchangeModal from './components';
import Exchanges from '../../../redux/modules/Exchanges';
import UI from '../../../redux/modules/UI';

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      hideAddUpdateModal: UI.actions.hideAddUpdateModal,
      acceptAddUpdateModal: UI.actions.acceptAddUpdateModal
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    exchangeClients: Exchanges.selectors.exchangeClients(state),
    addUpdateModal: UI.selectors.addUpdateModal(state)
  };
};

export default connect(mapStateToProps, mapActionsToProps)(ExchangeModal);
