import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PnlBalance from './components';
import PnlBalanceState from '../../../redux/modules/PnlBalance';
import UIState from '../../../redux/modules/UI';

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      loadData: PnlBalanceState.actions.loadData,
      setField: PnlBalanceState.actions.setField,
      resetMeta: PnlBalanceState.actions.resetMeta,
      setPage: PnlBalanceState.actions.setPage,
      setLimit: PnlBalanceState.actions.setLimit,
      resetPage: PnlBalanceState.actions.resetPage,
      setUIField: UIState.actions.setField,
      addOpenedTab: UIState.actions.addOpenedTab
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  status: PnlBalanceState.selectors.status(state),
  pnlBalances: PnlBalanceState.selectors.pnlBalances(state),
  filters: PnlBalanceState.selectors.filters(state),
  meta: PnlBalanceState.selectors.meta(state),
  isDataUpdated: PnlBalanceState.selectors.isDataUpdated(state),
  openedTabs: UIState.selectors.openedTabs(state)
});

export default connect(mapStateToProps, mapActionsToProps)(PnlBalance);
