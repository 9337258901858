import React from 'react';
import { Input } from 'reactstrap';
import { forceNumeric } from '../utils/numbers';
import { Field, ErrorMessage } from 'formik';

class FormikInputNumber extends React.Component {
  render = () => {
    const {
      name,
      conditionalDisableFieldName = null,
      conditionalDisableFieldValue = null
    } = this.props;

    return (
      <>
        <Field name={name}>
          {({ field, form, meta }) => (
            <Input
              name={field.name}
              value={field.value}
              type='text'
              className={
                'form-control' +
                (meta.error && meta.touched ? ' is-invalid' : '')
              }
              onChange={(e) => {
                let { value } = e.target;
                value = forceNumeric(value);
                e.target.value = value;
                field.onChange(e);
              }}
              disabled={
                conditionalDisableFieldName !== null &&
                conditionalDisableFieldValue !== null
                  ? form.values[conditionalDisableFieldName] ===
                    conditionalDisableFieldValue
                  : false
              }
            />
          )}
        </Field>
        <ErrorMessage
          name={name}
          component='div'
          className='invalid-feedback'
        />
      </>
    );
  };
}

export default FormikInputNumber;
