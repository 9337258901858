import * as ac from './actionCreators';
import * as constants from '../../../constants';
import * as api from '../../../api';
import { getErrorMessage } from '../../../utils/error';

export function loadInitialData() {
  return async (dispatch) => {
    dispatch(ac.loadInitialData.pending());

    try {
      const activityGenerators = await api.getActivityGenerators();
      activityGenerators.sort((a, b) => (a.name > b.name ? 1 : -1));

      const exchanges = await api.getExchanges();
      exchanges.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.loadInitialData.success({ activityGenerators, exchanges }));
    } catch (err) {
      dispatch(ac.loadInitialData.error(getErrorMessage(err)));
    }
  };
}

export function loadActivityGenerator(name) {
  return async (dispatch) => {
    const activityGenerator = await api.getActivityGenerator(name);
    const targetExchange = await api.getExchange(
      activityGenerator.targetExchange
    );
    targetExchange.mapping.sort((a, b) =>
      a.internalSymbol > b.internalSymbol ? 1 : -1
    );

    dispatch(
      ac.setField({ path: 'activityGenerator', value: activityGenerator })
    );
    dispatch(
      ac.setField({ path: 'targetSymbols', value: targetExchange.mapping })
    );
  };
}

export function deleteActivityGenerator(name) {
  return async (dispatch) => {
    dispatch(ac.deleteActivityGenerator.pending());

    try {
      await api.deleteActivityGenerator(name);
      const activityGenerators = await api.getActivityGenerators();
      activityGenerators.sort((a, b) => (a.name > b.name ? 1 : -1));
      dispatch(ac.deleteActivityGenerator.success(activityGenerators));
    } catch (err) {
      dispatch(ac.deleteActivityGenerator.error(getErrorMessage(err)));
    }
  };
}

export function addUpdateActivityGenerator(activityGenerator) {
  return async (dispatch, getState) => {
    dispatch(ac.addUpdateActivityGenerator.pending());

    try {
      const { actionType } = getState().UI.addUpdateModal;

      if (actionType === constants.UPDATE) {
        await api.updateActivityGenerator(activityGenerator.name, {
          settings: { ...activityGenerator.settings }
        });
      } else {
        await api.addActivityGenerator(activityGenerator);
      }
      const activityGenerators = await api.getActivityGenerators();
      activityGenerators.sort((a, b) => (a.name > b.name ? 1 : -1));
      dispatch(ac.addUpdateActivityGenerator.success(activityGenerators));
    } catch (err) {
      dispatch(ac.addUpdateActivityGenerator.error(getErrorMessage(err)));
    }
  };
}

export function startActivityGenerator(name) {
  return async (dispatch) => {
    dispatch(ac.startActivityGenerator.pending());

    try {
      await api.startActivityGenerator(name);
      const activityGenerators = await api.getActivityGenerators();
      activityGenerators.sort((a, b) => (a.name > b.name ? 1 : -1));
      dispatch(ac.startActivityGenerator.success(activityGenerators));
    } catch (err) {
      dispatch(ac.startActivityGenerator.error(getErrorMessage(err)));
    }
  };
}

export function stopActivityGenerator(name) {
  return async (dispatch) => {
    dispatch(ac.stopActivityGenerator.pending());

    try {
      await api.stopActivityGenerator(name);
      const activityGenerators = await api.getActivityGenerators();
      activityGenerators.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.stopActivityGenerator.success(activityGenerators));
    } catch (err) {
      dispatch(ac.stopActivityGenerator.error(getErrorMessage(err)));
    }
  };
}

export function loadSymbols(exchangeName, targetFieldName) {
  return async (dispatch) => {
    const exchange = await api.getExchange(exchangeName);
    exchange.mapping.sort((a, b) =>
      a.internalSymbol > b.internalSymbol ? 1 : -1
    );

    dispatch(ac.setField({ path: targetFieldName, value: exchange.mapping }));
  };
}

export function loadAllSymbols() {
  return async (dispatch) => {
    const symbols = await api.getSymbols();
    symbols.sort((a, b) => (a.name > b.name ? 1 : -1));

    dispatch(ac.setField({ path: 'targetSymbols', value: symbols }));
  };
}

export function getActivityGeneratorSimulator(payload) {
  return async (dispatch) => {
    dispatch(ac.getActivityGeneratorSimulator.pending());

    try {
      const { candleData: data } = await api.getActivityGeneratorSimulator(
        payload
      );

      dispatch(ac.getActivityGeneratorSimulator.success(data));
    } catch (err) {
      dispatch(ac.getActivityGeneratorSimulator.error(getErrorMessage(err)));
    }
  };
}

export function setField(path, value) {
  return (dispatch) => dispatch(ac.setField({ path, value }));
}

export function setDataUpdated(isDataUpdated) {
  return (dispatch) => dispatch(ac.setDataUpdated(isDataUpdated));
}
