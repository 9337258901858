import React from 'react';
import { Field } from 'formik';

class FormikInputSelect extends React.Component {
  render = () => {
    const {
      name,
      options,
      onChange,
      conditionalDisableFieldName = null,
      conditionalDisableFieldValue = null,
      disabled = false
    } = this.props;

    return (
      <>
        <Field name={name}>
          {({ field, form, meta }) => {
            return (
              <div>
                <select
                  {...field}
                  className={
                    'form-control' +
                    (meta.error && meta.touched ? ' is-invalid' : '')
                  }
                  onChange={(e) => {
                    if (onChange) {
                      onChange(e, form, field);
                    } else {
                      field.onChange(e);
                    }
                  }}
                  disabled={
                    (conditionalDisableFieldName !== null &&
                    conditionalDisableFieldValue !== null
                      ? form.values[conditionalDisableFieldName] ===
                        conditionalDisableFieldValue
                      : false) || disabled
                  }>
                  <option value=''></option>
                  {options.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                {meta.touched && meta.error && (
                  <div className='invalid-feedback'>{meta.error}</div>
                )}
              </div>
            );
          }}
        </Field>
      </>
    );
  };
}

export default FormikInputSelect;
