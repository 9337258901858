const module = 'ACTIVITY_GENERATOR';

export const LOAD_INITIAL_DATA_PENDING = `${module}/LOAD_INITIAL_DATA_PENDING`;
export const LOAD_INITIAL_DATA_SUCCESS = `${module}/LOAD_INITIAL_DATA_SUCCESS`;
export const LOAD_INITIAL_DATA_ERROR = `${module}/LOAD_INITIAL_DATA_ERROR`;

export const GET_ACTIVITY_GENERATOR_SIMULATOR_PENDING = `${module}/GET_ACTIVITY_GENERATOR_SIMULATOR_PENDING`;
export const GET_ACTIVITY_GENERATOR_SIMULATOR_SUCCESS = `${module}/GET_ACTIVITY_GENERATOR_SIMULATOR_SUCCESS`;
export const GET_ACTIVITY_GENERATOR_SIMULATOR_ERROR = `${module}/GET_ACTIVITY_GENERATOR_SIMULATOR_ERROR`;

export const DELETE_ACTIVITY_GENERATOR_PENDING = `${module}/DELETE_ACTIVITY_GENERATOR_PENDING`;
export const DELETE_ACTIVITY_GENERATOR_SUCCESS = `${module}/DELETE_ACTIVITY_GENERATOR_SUCCESS`;
export const DELETE_ACTIVITY_GENERATOR_ERROR = `${module}/DELETE_ACTIVITY_GENERATOR_ERROR`;

export const ADD_UPDATE_ACTIVITY_GENERATOR_PENDING = `${module}/ADD_UPDATE_ACTIVITY_GENERATOR_PENDING`;
export const ADD_UPDATE_ACTIVITY_GENERATOR_SUCCESS = `${module}/ADD_UPDATE_ACTIVITY_GENERATOR_SUCCESS`;
export const ADD_UPDATE_ACTIVITY_GENERATOR_ERROR = `${module}/ADD_UPDATE_ACTIVITY_GENERATOR_ERROR`;

export const START_ACTIVITY_GENERATOR_PENDING = `${module}/START_ACTIVITY_GENERATOR_PENDING`;
export const START_ACTIVITY_GENERATOR_SUCCESS = `${module}/START_ACTIVITY_GENERATOR_SUCCESS`;
export const START_ACTIVITY_GENERATOR_ERROR = `${module}/START_ACTIVITY_GENERATOR_ERROR`;

export const STOP_ACTIVITY_GENERATOR_PENDING = `${module}/STOP_ACTIVITY_GENERATOR_PENDING`;
export const STOP_ACTIVITY_GENERATOR_SUCCESS = `${module}/STOP_ACTIVITY_GENERATOR_SUCCESS`;
export const STOP_ACTIVITY_GENERATOR_ERROR = `${module}/STOP_ACTIVITY_GENERATOR_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;

export const SET_DATA_UPDATED = `${module}/SET_DATA_UPDATED`;
