import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import bn from 'utils/bemnames';
import * as constants from '../../../../constants';

const className = 'cr-modal';
const bem = bn.create('modal');

class AlertModal extends React.Component {
  render() {
    const { id, entityName, show, actionType, withoutAcceptButton = false } = this.props.alertModal;
    const { hideAlertModal, acceptAlertModal } = this.props;
    let title = '';
    let message = '';
    let fullEntityName = '';

    switch (entityName) {
      case constants.MARKET_MAKER:
        fullEntityName = ' Market Maker';
        break;
      case constants.FEED_GENERATOR:
        fullEntityName = ' Feed Generator';
        break;
      case constants.ACTIVITY_GENERATOR:
        fullEntityName = ' Activity Generator';
        break;
      case constants.HEDGING:
        fullEntityName = ' Hedging Pair';
        break;
      case constants.EXCHANGE:
        fullEntityName = ' Exchange';
        break;
      default:
    }

    switch (actionType) {
      case constants.DELETE:
        title = `Delete ${fullEntityName}`;
        message = `Do you really want to delete ${id} ${fullEntityName}?`;
        break;
      case constants.MM_START:
        title = `Start ${fullEntityName}`;
        message = `Do you really want to start ${id} ${fullEntityName}?`;
        break;
      case constants.MM_STOP:
        title = `Stop ${fullEntityName}`;
        message = `Do you really want to stop ${id} ${fullEntityName}?`;
        break;
      case constants.AG_START:
        title = `Start ${fullEntityName}`;
        message = `Do you really want to start ${id} ${fullEntityName}?`;
        break;
      case constants.AG_STOP:
        title = `Stop ${fullEntityName}`;
        message = `Do you really want to stop ${id} ${fullEntityName}?`;
        break;
      case constants.FG_START:
        title = `Start ${fullEntityName}`;
        message = `Do you really want to start ${id} ${fullEntityName}?`;
        break;
      case constants.FG_STOP:
        title = `Stop ${fullEntityName}`;
        message = `Do you really want to stop ${id} ${fullEntityName}?`;
        break;
      case constants.HEDGING_START:
        title = `Start ${fullEntityName}`;
        message = `Do you really want to start ${id} ${fullEntityName}?`;
        break;
      case constants.HEDGING_STOP:
        title = `Stop ${fullEntityName}`;
        message = `Do you really want to stop ${id} ${fullEntityName}?`;
        break;
      case constants.RECONNECT:
        title = `Reconnect ${fullEntityName}`;
        message = `Do you really want to reconnect ${id} ${fullEntityName}?`;
        break;
      case constants.EDIT_EXCHANGE:
        title = `${fullEntityName} Updated`;
        message = `The ${id} ${fullEntityName} update was done successfully!`;
        break;
      default:
    }

    return (
      <Modal className={className} isOpen={show} toggle={hideAlertModal}>
        <ModalHeader className={`${bem.e('header')}`} toggle={hideAlertModal}>
          {title}
        </ModalHeader>
        <ModalBody color='red'>{message}</ModalBody>
        {!withoutAcceptButton && (  
        <ModalFooter>
          <div className='btn btn-sm btn-primary' onClick={acceptAlertModal}>
            {constants.ACCEPT.charAt(0).toUpperCase() +
              constants.ACCEPT.slice(1)}
          </div>
        </ModalFooter>
        )}
      </Modal>
    );
  }
}

export default AlertModal;
