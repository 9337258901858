import * as ac from './actionCreators';
import * as constants from '../../../constants';
import * as api from '../../../api';
import { getErrorMessage } from '../../../utils/error';

export function loadInitialData() {
  return async (dispatch) => {
    dispatch(ac.loadInitialData.pending());

    try { 
      const marketMakers = await api.getMarketMakers();
      marketMakers.sort((a, b) => (a.name > b.name ? 1 : -1));

      const exchanges = await api.getExchanges();
      exchanges.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.loadInitialData.success({ marketMakers, exchanges }));
    } catch (err) {
      dispatch(ac.loadInitialData.error(getErrorMessage(err)));
    }
  };
}

export function loadMarketMaker(name) {
  return async (dispatch) => {
    const marketMaker = await api.getMarketMaker(name);
    const sourceExchange = await api.getExchange(marketMaker.sourceExchange);
    const targetExchange = await api.getExchange(marketMaker.targetExchange);
    sourceExchange.mapping.sort((a, b) =>
      a.internalSymbol > b.internalSymbol ? 1 : -1
    );
    targetExchange.mapping.sort((a, b) =>
      a.internalSymbol > b.internalSymbol ? 1 : -1
    );

    dispatch(ac.setField({ path: 'marketMaker', value: marketMaker }));
    dispatch(
      ac.setField({ path: 'sourceSymbols', value: sourceExchange.mapping })
    );
    dispatch(
      ac.setField({ path: 'targetSymbols', value: targetExchange.mapping })
    );
  };
}

export function deleteMarketMaker(name) {
  return async (dispatch) => {
    dispatch(ac.deleteMarketMaker.pending());

    try {
      await api.deleteMarketMaker(name);
      const marketMakers = await api.getMarketMakers();
      marketMakers.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.deleteMarketMaker.success(marketMakers));
    } catch (err) {
      dispatch(ac.deleteMarketMaker.error(getErrorMessage(err)));
    }
  };
}

export function addUpdateMarketMaker(marketMaker) {
  return async (dispatch, getState) => {
    dispatch(ac.addUpdateMarketMaker.pending());

    try {
      const { actionType } = getState().UI.addUpdateModal;

      if (actionType === constants.UPDATE) {
        await api.updateMarketMaker(marketMaker.name, marketMaker);
      } else {
        await api.addMarketMaker(marketMaker);
      }
      const marketMakers = await api.getMarketMakers();
      marketMakers.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.addUpdateMarketMaker.success(marketMakers));
    } catch (err) {
      dispatch(ac.addUpdateMarketMaker.error(getErrorMessage(err)));
    }
  };
}

export function startMarketMaker(name) {
  return async (dispatch) => {
    dispatch(ac.startMarketMaker.pending());

    try {
      await api.startMarketMaker(name);
      const marketMakers = await api.getMarketMakers();
      marketMakers.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.startMarketMaker.success(marketMakers));
    } catch (err) {
      dispatch(ac.startMarketMaker.error(getErrorMessage(err)));
    }
  };
}

export function stopMarketMaker(name) {
  return async (dispatch) => {
    dispatch(ac.stopMarketMaker.pending());

    try {
      await api.stopMarketMaker(name);
      const marketMakers = await api.getMarketMakers();
      marketMakers.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.stopMarketMaker.success(marketMakers));
    } catch (err) {
      dispatch(ac.stopMarketMaker.error(getErrorMessage(err)));
    }
  };
}

export function loadSymbols(exchangeName, targetFieldName) {
  return async (dispatch) => {
    const exchange = await api.getExchange(exchangeName);
    exchange.mapping.sort((a, b) =>
      a.internalSymbol > b.internalSymbol ? 1 : -1
    );

    dispatch(ac.setField({ path: targetFieldName, value: exchange.mapping }));
  };
}

export function setField(path, value) {
  return (dispatch) => dispatch(ac.setField({ path, value }));
}

export function setDataUpdated(isDataUpdated) {
  return (dispatch) => dispatch(ac.setDataUpdated(isDataUpdated));
}
