import * as c from './constants';
import { asyncActionCreator, actionCreator } from '../../../utils/redux';

export const loadInitialData = asyncActionCreator(
  c.LOAD_INITIAL_DATA_PENDING,
  c.LOAD_INITIAL_DATA_SUCCESS,
  c.LOAD_INITIAL_DATA_ERROR
);

export const setField = actionCreator(c.SET_FIELD);

// export const setDataUpdated = actionCreator(c.SET_DATA_UPDATED);
