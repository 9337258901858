import * as c from './constants';
import { FEED_GEN_RAND_WALK_ALGO } from '../../../constants';

const initialState = {
  status: {
    pending: false,
    error: false,
    success: false
  },
  feedGeneratorAlgorithm: FEED_GEN_RAND_WALK_ALGO,
  feedGenerators: [],
  feedGenerator: null,
  exchanges: [],
  symbols: [],
  isDataUpdated: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.LOAD_INITIAL_DATA_PENDING:
    case c.DELETE_FEED_GENERATOR_PENDING:
    case c.ADD_UPDATE_FEED_GENERATOR_PENDING:
    case c.START_FEED_GENERATOR_PENDING:
    case c.STOP_FEED_GENERATOR_PENDING: {
      return {
        ...state,
        status: { pending: true, error: false, success: false }
      };
    }

    case c.LOAD_INITIAL_DATA_SUCCESS: {
      return {
        ...state,
        status: { pending: false, error: false, success: true },
        feedGenerators: action.payload.feedGenerators,
        exchanges: action.payload.exchanges,
        isDataUpdated: true
      };
    }

    case c.LOAD_INITIAL_DATA_ERROR:
    case c.DELETE_FEED_GENERATOR_ERROR:
    case c.ADD_UPDATE_FEED_GENERATOR_ERROR:
    case c.START_FEED_GENERATOR_ERROR:
    case c.STOP_FEED_GENERATOR_ERROR: {
      return {
        ...state,
        status: { pending: false, error: action.payload, success: false }
      };
    }

    case c.DELETE_FEED_GENERATOR_SUCCESS:
    case c.ADD_UPDATE_FEED_GENERATOR_SUCCESS:
    case c.START_FEED_GENERATOR_SUCCESS:
    case c.STOP_FEED_GENERATOR_SUCCESS: {
      return {
        ...state,
        status: { pending: false, error: false, success: true },
        feedGenerators: action.payload,
        isDataUpdated: true
      };
    }

    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.path]: action.payload.value
      };
    }

    case c.SET_DATA_UPDATED: {
      return {
        ...state,
        isDataUpdated: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
