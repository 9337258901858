import React, { memo } from 'react';
import classNames from 'classnames';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Tooltip from './Tooltip';

const InfoIconTooltip = ({ containerClassName, ...rest }) => {
  return (
    <div className={classNames('info-icon-tooltip', containerClassName)}>
      <Tooltip {...rest}>
        <InfoOutlinedIcon style={{ fontSize: '15px' }} />
      </Tooltip>
    </div>
  );
};

export default memo(InfoIconTooltip);