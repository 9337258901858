import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FeedGeneratorModal from './components';
import FeedGenerator from '../../../redux/modules/FeedGenerator';
import UI from '../../../redux/modules/UI';

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadExchangeMapping: FeedGenerator.actions.loadExchangeMapping,
      setFeedGeneratorField: FeedGenerator.actions.setField,
      hideAddUpdateModal: UI.actions.hideAddUpdateModal,
      acceptAddUpdateModal: UI.actions.acceptAddUpdateModal
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    feedGenerator: FeedGenerator.selectors.feedGenerator(state),
    exchanges: FeedGenerator.selectors.exchanges(state),
    symbols: FeedGenerator.selectors.symbols(state),
    feedGeneratorAlgorithm: FeedGenerator.selectors.feedGeneratorAlgorithm(
      state
    ),
    addUpdateModal: UI.selectors.addUpdateModal(state)
  };
};

export default connect(mapStateToProps, mapActionsToProps)(FeedGeneratorModal);
