import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AddEntityButton from './components';
import UI from '../../redux/modules/UI';

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      showAddUpdateModal: UI.actions.showAddUpdateModal
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  currentEntityName: UI.selectors.currentEntityName(state)
});

export default connect(mapStateToProps, mapActionsToProps)(AddEntityButton);
