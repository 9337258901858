import * as c from './constants';
import { asyncActionCreator, actionCreator } from '../../../utils/redux';

export const loadCurrencies = asyncActionCreator(
  c.LOAD_CURRENCIES_PENDING,
  c.LOAD_CURRENCIES_SUCCESS,
  c.LOAD_CURRENCIES_ERROR
);

export const addUpdateCurrency = asyncActionCreator(
  c.ADD_UPDATE_CURRENCY_PENDING,
  c.ADD_UPDATE_CURRENCY_SUCCESS,
  c.ADD_UPDATE_CURRENCY_ERROR
);

export const setField = actionCreator(c.SET_FIELD);

export const setDataUpdated = actionCreator(c.SET_DATA_UPDATED);
