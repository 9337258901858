import NewPage from 'components/NewPage';
import React from 'react';
import { Col, Row } from 'reactstrap';
import Chart from './Chart';
import DateTimeFilter from '../../../../components/DateTimeFilter';
import CustomTable from '../../../../components/CustomTable';
import ErrorComp from '../../../../components/ErrorComp';
import Loader from '../../../../components/Loader';
import * as constants from '../../../../constants';
import { CSVLink } from "react-csv";

class PnlTrade extends React.Component {
  constructor(props) {
    super(props);

    this.table = React.createRef();

    this.state = {
      tableColumnNames: this.getTableColumnNames()
    };

    this.props.setUIField('currentEntityName', constants.PNL_BALANCE);
  }

  getTableColumnNames = () => {
    return [
      {
        header: '№',
        accessor: 'id'
      },
      {
        header: 'Date & time',
        accessor: 'timestamp'
      },
      {
        header: 'Symbol',
        accessor: 'symbol'
      },
      {
        header: 'Client order price',
        accessor: 'originalOrderPrice'
      },
      {
        header: 'Client order amount',
        accessor: 'originalOrderAmount'
      },
      {
        header: 'Binance order side',
        accessor: 'hedgeOrderSide'
      },
      {
        header: 'Binance order price',
        accessor: 'hedgeOrderPrice'
      },
      {
        header: 'Binance order amount',
        accessor: 'hedgeOrderAmount'
      },
      {
        header: 'Hedge commission',
        accessor: 'hedgeCommission'
      },
      {
        header: 'PNL',
        accessor: 'pnl'
      },
      {
        header: 'PNL Total',
        accessor: 'pnlTotal'
      },
      {
        header: 'Error Message',
        accessor: 'errorMessage',
        inputType: 'textarea'
      }
    ];
  };

  componentDidMount() {
    this.props.loadData();
    this.props.addOpenedTab({
      to: '/hedging/pnl-trade',
      name: 'PNL trade'
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { openedTabs } = this.props;

    if (
      !openedTabs.find((openedTab) => openedTab.to === '/hedging/pnl-trade')
    ) {
      this.props.history.push(openedTabs[openedTabs.length - 1].to);
    }

    if (this.props.isDataUpdated) {
      this.props.setField('isDataUpdated', false);
    }
  }

  componentWillUnmount() {
    this.props.resetPage();
  }

  filter = () => {
    this.props.setField('pnlTrades', []);
    this.props.setField('pnlTradesTable', []);
    this.props.resetMeta();
    this.props.loadData();
  };

  render() {
    const {
      status: { error, pending },
      pnlTotal,
      pnlTrades,
      pnlTradesTable,
      isDataUpdated,
      setField
    } = this.props;

    const { tableColumnNames } = this.state;

    const headers = tableColumnNames.map((item) => {
      return {
        label: item.header,
        key: item.accessor
      };
    });

    return (
      <NewPage
        title=''
        breadcrumbs={[{ name: 'PNL Trade', active: true }]}
        disableAddButton>
        <Row>
          <Col md='12' sm='12' xs='12'>
            {error && <ErrorComp error={error}></ErrorComp>}
            <div className='d-flex align-items-center'>
              <DateTimeFilter
                filters={this.props.filters}
                setField={this.props.setField}
                filter={this.filter}
                isDisabled={pending}
              />
              <div className='ml-auto pnl-total'>
                <CSVLink data={pnlTradesTable} headers={headers} className='mr-2'>
                  Export CSV
                </CSVLink>
                PNL Total: {pnlTotal}
              </div>
            </div>
            <Chart
              data={pnlTrades}
              setField={setField}
              isDataUpdated={isDataUpdated}
              isDataLoading={pending}
            />
            <Loader isLoading={pending}>
              <CustomTable
                columns={tableColumnNames}
                data={pnlTradesTable}
                withPagination
                meta={this.props.meta}
                setPage={this.props.setPage}
                setLimit={this.props.setLimit}
              />
            </Loader>
          </Col>
        </Row>
      </NewPage>
    );
  }
}

export default PnlTrade;
