import React from 'react';
import PropTypes from 'utils/propTypes';
import AlertModal from '../Modals/Alert';
//TODO: understand why no mudle foind error without index
import AddEntityButton from '../AddEntityButton/index';
import CurrencyModal from '../Modals/CurrencyModal';
import SymbolModal from '../Modals/SymbolModal';
import MarketMakerModal from '../Modals/MarketMakerModal';
import ActivityGeneratorModal from '../Modals/ActivityGenerator';
import FeedGeneratorModal from '../Modals/FeedGenerator';
import ExchangeModal from '../Modals/ExchangeModal';
import ExchangeInfoModal from '../Modals/ExchangeInfoModal';
import HedgingModal from '../Modals/HedgingModal';
import HedgingExchangeKeyModal from '../Modals/HedgingExchangeKey';
import {
  feedGeneratorAlgorithms,
  activityGeneratorAlgorithms,
  eventTypes
} from '../../config';
import Select from 'react-select';
import bn from 'utils/bemnames';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const bem = bn.create('page');
const eventTypeSelectOptions = eventTypes.map(
  (eventType) => {
    return { label: eventType, value: eventType };
  }
);
const feedGeneratorAlgorithmSelectOptions = feedGeneratorAlgorithms.map(
  (algorithm) => {
    return { label: algorithm, value: algorithm };
  }
);
const activityGeneratorAlgorithmSelectOptions = activityGeneratorAlgorithms.map(
  (algorithm) => {
    return { label: algorithm, value: algorithm };
  }
);

const NewPage = ({
  title,
  dataLoading = false,
  withMarketMakerFilter,
  withEventTypeFilter,
  withFeedGeneratorAlgoFilter,
  withActivityGeneratorAlgoFilter,
  eventType,
  marketMaker,
  marketMakers,
  feedGeneratorAlgorithm,
  activityGeneratorAlgorithm,
  marketMakerSetField,
  eventTypeSetField,
  feedGeneratorSetField,
  activityGeneratorSetField,
  breadcrumbs,
  disableAddButton,
  tag: Tag,
  className,
  children,
  ...restProps
}) => {
  const classes = bem.b('pr-3', className);

  const marketMakerSelectOptions = marketMakers.map((item) => ({
    label: item.name,
    value: item.name
  }));

  return (
    <Tag className={classes} {...restProps}>
      <div className={`${bem.e('header')} d-flex align-items-center`}>
        {breadcrumbs && (
          <Breadcrumb className={bem.e('breadcrumb')}>
            {breadcrumbs.length &&
              breadcrumbs.map(({ name, active }, index) => (
                <BreadcrumbItem key={index} active={active}>
                  {name}
                </BreadcrumbItem>
              ))}
          </Breadcrumb>
        )}
        {withMarketMakerFilter && (
          <Select
            value={marketMakerSelectOptions.find(
              (item) => item.value === marketMaker
            )}
            options={marketMakerSelectOptions}
            onChange={(ev) =>
              marketMakerSetField('marketMaker', ev.value)
            }
            isDisabled = {dataLoading}
          />
        )}
        {withEventTypeFilter && (
          <Select
            value={eventTypeSelectOptions.find(
              (item) => item.value === eventType
            )}
            options={eventTypeSelectOptions}
            onChange={(ev) =>
              eventTypeSetField('eventType', ev.value)
            }
            isDisabled = {dataLoading}
          />
        )}
        {withFeedGeneratorAlgoFilter && (
          <Select
            value={feedGeneratorAlgorithmSelectOptions.find(
              (item) => item.value === feedGeneratorAlgorithm
            )}
            options={feedGeneratorAlgorithmSelectOptions}
            onChange={(ev) =>
              feedGeneratorSetField('feedGeneratorAlgorithm', ev.value)
            }
          />
        )}
        {withActivityGeneratorAlgoFilter && (
          <Select
            value={activityGeneratorAlgorithmSelectOptions.find(
              (item) => item.value === activityGeneratorAlgorithm
            )}
            options={activityGeneratorAlgorithmSelectOptions}
            onChange={(ev) =>
              activityGeneratorSetField('activityGeneratorAlgorithm', ev.value)
            }
          />
        )}
        {!disableAddButton && <AddEntityButton />}
      </div>
      {children}
      <CurrencyModal />
      <SymbolModal />
      <ExchangeModal />
      <ExchangeInfoModal />
      <MarketMakerModal />
      <ActivityGeneratorModal />
      <FeedGeneratorModal />
      <HedgingModal />
      <HedgingExchangeKeyModal />
      <AlertModal />
    </Tag>
  );
};

NewPage.propTypes = {
  tag: PropTypes.component,
  className: PropTypes.string,
  children: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.bool
    })
  )
};

NewPage.defaultProps = {
  tag: 'div'
};

export default NewPage;
