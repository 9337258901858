import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import bn from '../../../../utils/bemnames';
import * as constants from '../../../../constants';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import FormikInputSelect from '../../../FormikInputSelect';
import * as Yup from 'yup';
import 'react-datetime/css/react-datetime.css';

const className = 'cr-modal';
const bem = bn.create('modal');

const getInitialValues = () => {
  return {
    exchange: '',
    apiKey: '',
    url: '',
    secretKey: ''
  };
};

class HedgingExchangeKeyModal extends React.Component {

    state = {
        selectedExchange: ''
    };  

  componentDidUpdate(prevProps) {

    if (this.props.addUpdateModal.entityName === constants.HEDGING_EXCHANGE_KEY){

      if(prevProps.addUpdateModal.show && !this.props.addUpdateModal.show)
        this.setState({
          selectedExchange: ''
        });
      
      const {addUpdateModal: { actionType }, exchangeKey: entity} = this.props;
      if(!prevProps.addUpdateModal.show && this.props.addUpdateModal.show && actionType === constants.UPDATE && entity){
        this.setState({
          selectedExchange: entity.exchange
        });
      }
    }
  }

  constructInitialValues = () => {
    const { exchangeKey: entity } = this.props;

    if (entity) {
      let initialBaseValues = {
        exchange: entity.exchange,
        apiKey: entity.keys.apiKey
      };

      if (entity.exchange === 'paramountdax')
        return {
          ...initialBaseValues,
          url: entity.keys.url
        };
      else if (entity.exchange === 'binance') {
        return {
          ...initialBaseValues,
          secretKey: entity.keys.secretKey
        };
      }
    } else return getInitialValues();
  };

  constructForm = () => {
    const {
      addUpdateModal: { actionType }
    } = this.props;

    const exchangeOptions = [
      {
        label: 'paramountdax',
        value: 'paramountdax'
      },
      {
        label: 'binance',
        value: 'binance'
      }
    ];

    const exchangeOnChange = (e, form, field) => {
        const { value } = e.target;

        this.setState({
          selectedExchange: value
        });
      field.onChange(e);
    };

    let validationSchemaObject = {
        exchange: Yup.string().required('Exchange is required'),
        apiKey: Yup.string().required('Api key is required'),
        // url: Yup.string().required('Url is required')

        url: Yup.string()
            .when('exchange', {
                is: 'paramountdax',
                then: Yup.string().required('Url is required')
            }),
        secretKey: Yup.string()
            .when('exchange', {
                is: 'binance',
                then: Yup.string().required('Secret key is required')
            }),
    };

    return (
      <Formik
        initialValues={this.constructInitialValues()}
        validationSchema={Yup.object().shape(validationSchemaObject)}
        onSubmit={(fields) => {
          let entity = {
            exchange: fields.exchange,
            keys: {
              apiKey: fields.apiKey
            }
          };

          if (fields.exchange === 'paramountdax') {
            entity = {
              ...entity,
              keys: {
                ...entity.keys,
                url: fields.url
              }
            };
        }
        else if (fields.exchange === 'binance') {
            entity = {
                ...entity,
                keys: {
                ...entity.keys,
                secretKey: fields.secretKey
                }
            };

          }
          this.props.acceptAddUpdateModal(entity);
        }}>
        {({ errors, status, touched }) => {


          return (
            <Form>
              <div className='row'>
                <div className='form-group col-12'>
                    <label htmlFor='exchange'>Exchange</label>
                    <FormikInputSelect
                        name='exchange'
                        options={exchangeOptions}
                        onChange={exchangeOnChange}
                        // conditionalDisableFieldName='enableLeadingPair'
                        // conditionalDisableFieldValue={false}
                        disabled={actionType === constants.UPDATE}
                    />
                </div>
                </div>
                <div className='row'>
                    <div className='form-group col-12'>
                        <label htmlFor='apiKey'>Api key</label>
                        <Field
                            name='apiKey'
                            type='text'
                            className={
                              'form-control' +
                              (errors.apiKey && touched.apiKey ? ' is-invalid' : '')
                            }
                        />
                        <ErrorMessage 
                          name='apiKey'
                          component='div'
                          className='invalid-feedback'
                        />
                    </div>
                </div>
              {this.state.selectedExchange === 'paramountdax' && (
                  <div className='row'>
                    <div className='form-group col-12'>
                        <label htmlFor='url'>Url</label>
                        <Field
                            name='url'
                            type='text'
                            className={
                              'form-control' +
                              (errors.url && touched.url ? ' is-invalid' : '')
                            }
                        />
                        <ErrorMessage
                          name='url'
                          component='div'
                          className='invalid-feedback'
                        />
                    </div>
                  </div>
              )}
              {this.state.selectedExchange === 'binance' && (
                  <div className='row'>
                    <div className='form-group col-12'>
                        <label htmlFor='secretKey'>Secret key</label>
                        <Field
                            name='secretKey'
                            type='text'
                            className={
                              'form-control' +
                              (errors.secretKey && touched.secretKey ? ' is-invalid' : '')
                            }
                        />
                        <ErrorMessage
                          name='secretKey'
                          component='div'
                          className='invalid-feedback'
                        />
                    </div>
                  </div>
              )}

              <div className='float-right'>
                <button type='submit' className='btn btn-primary'>
                  Accept
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  render() {
    const { show, actionType, entityName } = this.props.addUpdateModal;
    const { hideAddUpdateModal } = this.props;

    return (
      <Modal
        className={className}
        isOpen={show && entityName === constants.HEDGING_EXCHANGE_KEY}
        toggle={hideAddUpdateModal}>
        <ModalHeader
          className={`${bem.e('header')}`}
          toggle={hideAddUpdateModal}>
          {`${actionType} Hedging Exchange Key`}
        </ModalHeader>
        <ModalBody>{this.constructForm()}</ModalBody>
      </Modal>
    );
  }
}

export default HedgingExchangeKeyModal;
