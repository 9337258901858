import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MainLayout from './MainLayout/components';
import UI from '../../redux/modules/UI';

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      //addOpenedTab: UI.actions.addOpenedTab,
      deleteOpenedTab: UI.actions.deleteOpenedTab
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  openedTabs: UI.selectors.openedTabs(state)
});

export default connect(mapStateToProps, mapActionsToProps)(MainLayout);
