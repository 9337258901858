import * as c from './constants';

const initialState = {
  openedTabs: [],
  alertModal: {
    id: null,
    entity: null,
    entityName: null,
    actionType: null,
    message: null,
    show: false
  },
  addUpdateModal: {
    entityName: null,
    show: false,
    actionType: null
  },
  dynamicModal: {
    id: null,
    dynamicModalType: null,
    entityName: null,
    show: false,
    fields: []
  },
  currentEntityName: null
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.ADD_OPENED_TAB: {
      if (
        !state.openedTabs.find(
          (openedTab) => openedTab.name === action.payload.name
        )
      ) {
        let newOpenTabs = [...state.openedTabs];

        if (newOpenTabs.length > 5) newOpenTabs.shift();

        return {
          ...state,
          openedTabs: [...newOpenTabs, action.payload]
        };
      }
      return {
        ...state
      };
    }
    case c.DELETE_OPENED_TAB: {
      return {
        ...state,
        openedTabs: state.openedTabs.filter(
          (openedTab) => openedTab.name !== action.payload.name
        )
      };
    }

    case c.SHOW_ALERT_MODAL: {
      return {
        ...state,
        alertModal: { show: true, ...action.payload }
      };
    }
    case c.HIDE_ALERT_MODAL: {
      return {
        ...state,
        alertModal: { ...initialState.alertModal }
      };
    }

    case c.SHOW_ADD_UPDATE_MODAL: {
      return {
        ...state,
        addUpdateModal: { show: true, ...action.payload }
      };
    }
    case c.HIDE_ADD_UPDATE_MODAL: {
      return {
        ...state,
        addUpdateModal: {
          ...initialState.addUpdateModal,
          entityName: action.payload
        }
      };
    }

    case c.SHOW_DYNAMIC_MODAL: {
      return {
        ...state,
        dynamicModal: { show: true, ...action.payload }
      };
    }
    case c.HIDE_DYNAMIC_MODAL: {
      return {
        ...state,
        dynamicModal: { ...initialState.dynamicModal }
      };
    }

    case c.SET_FIELD: {
      const { name, value } = action.payload;

      return {
        ...state,
        [name]: value
      };
    }

    default: {
      return state;
    }
  }
};
