const module = 'SYMBOLS';

export const LOAD_INITIAL_DATA_PENDING = `${module}/LOAD_INITIAL_DATA_PENDING`;
export const LOAD_INITIAL_DATA_SUCCESS = `${module}/LOAD_INITIAL_DATA_SUCCESS`;
export const LOAD_INITIAL_DATA_ERROR = `${module}/LOAD_INITIAL_DATA_ERROR`;

export const DELETE_SYMBOL_PENDING = `${module}/DELETE_SYMBOL_PENDING`;
export const DELETE_SYMBOL_SUCCESS = `${module}/DELETE_SYMBOL_SUCCESS`;
export const DELETE_SYMBOL_ERROR = `${module}/DELETE_SYMBOL_ERROR`;

export const ADD_UPDATE_SYMBOL_PENDING = `${module}/ADD_UPDATE_SYMBOL_PENDING`;
export const ADD_UPDATE_SYMBOL_SUCCESS = `${module}/ADD_UPDATE_SYMBOL_SUCCESS`;
export const ADD_UPDATE_SYMBOL_ERROR = `${module}/ADD_UPDATE_SYMBOL_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;

export const SET_DATA_UPDATED = `${module}/SET_DATA_UPDATED`;
