import * as c from './constants';
import { asyncActionCreator, actionCreator } from '../../../utils/redux';

export const loadEvents = asyncActionCreator(
  c.LOAD_EVENTS_PENDING,
  c.LOAD_EVENTS_SUCCESS,
  c.LOAD_EVENTS_ERROR
);

export const setField = actionCreator(c.SET_FIELD);

export const setDataUpdated = actionCreator(c.SET_DATA_UPDATED);
