import NewPage from 'components/NewPage';
import React from 'react';
import { Col, Row } from 'reactstrap';
import CustomTable from '../../../../components/CustomTable';
import ErrorComp from '../../../../components/ErrorComp';
import * as constants from '../../../../constants';

const tableParamountdaxColumnNames = [
    {
        header: 'Api Key',
        accessor: 'apiKey',
        className: 'api-key'
    },
    {
        header: 'Url',
        accessor: 'url'
    },
    {
      header: 'Actions',
      accessor: 'actions',
      className: 'actions'
    }
];
const tableBinanceColumnNames = [
    {
        header: 'Api Key',
        accessor: 'apiKey',
        className: 'api-key'
    },
    {
        header: 'Secret Key',
        accessor: 'secretKey'
    },
    {
      header: 'Actions',
      accessor: 'actions',
      className: 'actions'
    }
];

class ExchangeKeys extends React.Component {
  constructor(props) {
    super(props);

    this.table = React.createRef();

    this.state = {
      tableExchangeKeys: [],
    };

    this.props.setUIField('currentEntityName', constants.HEDGING_EXCHANGE_KEY);
  }

  componentDidMount() {
    this.props.loadExchangeKeys();
    this.props.addOpenedTab({
      to: '/hedging/exchange-keys',
      name: 'exchange keys'
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { openedTabs } = this.props;

    if (
      !openedTabs.find(
        (openedTab) =>
          openedTab.to === '/hedging/exchange-keys'
      )
    ) {
      this.props.history.push(openedTabs[openedTabs.length - 1].to);
    } else {
      if (this.props.isDataUpdated) {
        this.setState({
          tableExchangeKeys: this.getTableExchangeKeys()
        });
        this.props.setDataUpdated(false);
      }
    }
  }


  getTableExchangeKeys = () => {

    return this.props.exchangeKeys.map((exchangeKey, index) => {
      const actions = (
        <div>
          <button
            className='btn btn-sm btn-primary mr-2 h-26'
            onClick={() => this.handleUpdateIconClick(exchangeKey.exchange)}>
            <i className='fa fa-edit'></i> Edit
          </button>
          <button
            className='btn btn-sm btn-primary mr-2'
            onClick={() => this.handleDeleteIconClick(exchangeKey.exchange)}>
            <i className='fas fa-trash-alt'></i> Delete
          </button>
        </div>
      );

      let result = {
        exchange: exchangeKey.exchange,
        actions
      };

      if (exchangeKey.exchange === 'paramountdax'){
        result= {
            ...result,
            apiKey: exchangeKey.keys.apiKey,
            url: exchangeKey.keys.url,
        };
      }
      else if (
        exchangeKey.exchange === 'binance'
      ) {
        result= {
            ...result,
            apiKey: exchangeKey.keys.apiKey,
            secretKey: exchangeKey.keys.secretKey,
        };
      }

      return {
        ...result,
        actions
      };
    });
  };

  handleDeleteIconClick = (exchange) => {
    this.props.showAlertModal({
      id: exchange,
      entityName: constants.HEDGING_EXCHANGE_KEY,
      actionType: constants.DELETE
    });
  };

  handleUpdateIconClick = async (exchange) => {
    this.props.loadExchangeKey(this.props.exchangeKeys.find((item) => item.exchange === exchange));

    this.props.showAddUpdateModal({
      entityName: constants.HEDGING_EXCHANGE_KEY,
      actionType: constants.UPDATE
    });
  };

  render() {
    const {
      status: { error }
    } = this.props;

    const { tableExchangeKeys } = this.state;

    return (
      <NewPage
        title=''
        breadcrumbs={[{ name: 'Hedging Exchange Keys', active: true }]}>
        <Row>
          <Col md='12' sm='12' xs='12'>
            {error && <ErrorComp error={error}></ErrorComp>}
            <div className='title'>Paramountdax</div>
            <CustomTable
              className='hedging-exchange-keys-table'
              columns={tableParamountdaxColumnNames}
              data={tableExchangeKeys.filter((item) => item.exchange === 'paramountdax')}
            />
            <div className='title'>Binance</div>
            <CustomTable
              className='hedging-exchange-keys-table'
              columns={tableBinanceColumnNames}
              data={tableExchangeKeys.filter((item) => item.exchange === 'binance')}
            />
          </Col>
        </Row>
      </NewPage>
    );
  }
}

export default ExchangeKeys;
