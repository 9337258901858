import * as c from './constants';
import { asyncActionCreator, actionCreator } from '../../../utils/redux';

export const loadInitialData = asyncActionCreator(
  c.LOAD_INITIAL_DATA_PENDING,
  c.LOAD_INITIAL_DATA_SUCCESS,
  c.LOAD_INITIAL_DATA_ERROR
);

export const deleteFeedGenerator = asyncActionCreator(
  c.DELETE_FEED_GENERATOR_PENDING,
  c.DELETE_FEED_GENERATOR_SUCCESS,
  c.DELETE_FEED_GENERATOR_ERROR
);

export const addUpdateFeedGenerator = asyncActionCreator(
  c.ADD_UPDATE_FEED_GENERATOR_PENDING,
  c.ADD_UPDATE_FEED_GENERATOR_SUCCESS,
  c.ADD_UPDATE_FEED_GENERATOR_ERROR
);

export const startFeedGenerator = asyncActionCreator(
  c.START_FEED_GENERATOR_PENDING,
  c.START_FEED_GENERATOR_SUCCESS,
  c.START_FEED_GENERATOR_ERROR
);

export const stopFeedGenerator = asyncActionCreator(
  c.START_FEED_GENERATOR_PENDING,
  c.START_FEED_GENERATOR_SUCCESS,
  c.START_FEED_GENERATOR_ERROR
);

export const setField = actionCreator(c.SET_FIELD);

export const setDataUpdated = actionCreator(c.SET_DATA_UPDATED);
