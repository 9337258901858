import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import Currencies from './modules/Currencies/reducer';
import Symbols from './modules/Symbols/reducer';
import Exchanges from './modules/Exchanges/reducer';
import MarketMaker from './modules/MarketMaker/reducer';
import MarketMakerOrderBooks from './modules/MarketMakerOrderBooks/reducer';
import ActivityGenerator from './modules/ActivityGenerator/reducer';
import FeedGenerator from './modules/FeedGenerator/reducer';
import Monitoring from './modules/Monitoring/reducer';
import PnlBalance from './modules/PnlBalance/reducer';
import PnlTrade from './modules/PnlTrade/reducer';
import Hedging from './modules/Hedging/reducer';
import HedgingExchangeKeys from './modules/HedgingExchangeKeys/reducer';
import UI from './modules/UI/reducer';
import Events from './modules/Events/reducer';

const appReducer = combineReducers({
  routing: routerReducer,
  Currencies,
  Symbols,
  Exchanges,
  MarketMaker,
  ActivityGenerator,
  FeedGenerator,
  Monitoring,
  PnlBalance,
  PnlTrade,
  Hedging,
  HedgingExchangeKeys,
  MarketMakerOrderBooks,
  UI,
  Events
});

export default (state, action) => {
  return appReducer(state, action);
};
