import Bignumber from 'bignumber.js';

let Registry = {};

export function getBNInstance(precision) {
  if (!Registry[precision]) {
    const BN = Bignumber.clone({
      DECIMAL_PLACES: precision,
      ROUNDING_MODE: Bignumber.ROUND_DOWN
    });

    Registry[precision] = BN;
  }
  return Registry[precision];
}

export function format(number = 0, prec = 8, mode = null, withCommas = true) {
  const BN = getBNInstance(prec);
  return withCommas
    ? BN(number || 0).toFormat(prec, mode)
    : BN(number || 0).toFixed(prec, mode);
}

export function forceNumeric(nr) {
  return nr
    .replace(',', '.')
    .replace(/[^0-9.]/g, '')
    .replace(/((\d+)?\.(\d+)?)\.(.*)/g, '$1');
}

export function isNumeric(str) {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
}

export function removeZerosFromEnd(num) {
  let numStr = num.toString();
  let integerPart = numStr.split('.')[0];
  let decimalPart = numStr.split('.')[1];

  if (!decimalPart) return numStr;

  let i = decimalPart.length - 1;

  for (; i >= 0; i--) {
    if (decimalPart[i] !== '0') break;
  }

  if (i < 0) return integerPart;

  return `${integerPart}.${decimalPart.slice(0, i + 1)}`;
}
