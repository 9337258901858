import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem, NavLink as BSNavLink } from 'reactstrap';
import bn from 'utils/bemnames';
import { urlIcons } from '../../config';

const bem = bn.create('content-header');

const parseStr = (str) => {
  const result = str.charAt(0).toUpperCase() + str.slice(1);

  if (result.length > 12) return result.slice(0, 12) + '...';

  return result;
};

class ContentHeader extends React.Component {
  render() {
    return (
      <div className={bem.b()}>
        <Nav>
          {this.props.openedTabs.map(({ name, to }, index) => {
            const currIcon = urlIcons.find((item) => item.to === to).icon;
            const navItemClassName =
              to === window.location.pathname ? 'active' : '';

            return (
              <NavItem key={index} className={navItemClassName}>
                <i className={`fa fa-fw fa-lg fas ${currIcon} mr-2`}></i>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className='first-letter-uppercase sub-menu-item'
                  tag={NavLink}
                  to={to}
                  activeClassName='active'
                  exact={false}>
                  <span className=''>{parseStr(name)}</span>
                </BSNavLink>
                {this.props.openedTabs.length > 1 && (
                  <span className='icon'>
                    <i
                      className='fas fa-times mr-2 mb-1'
                      onClick={() =>
                        this.props.deleteOpenedTab({ name, to })
                      }></i>
                  </span>
                )}
              </NavItem>
            );
          })}
        </Nav>
      </div>
    );
  }
}

export default ContentHeader;
