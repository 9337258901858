import * as ac from './actionCreators';
import * as constants from '../../../constants';
import * as api from '../../../api';
import { getErrorMessage } from '../../../utils/error';

export function loadInitialData(entityName) {
  return async (dispatch) => {
    dispatch(ac.loadInitialData.pending());

    try {
      const exchanges = await api.getExchanges();
      const marketMakers = await api.getMarketMakers();
      const symbols = await api.getSymbols();
      exchanges.sort((a, b) => (a.name > b.name ? 1 : -1));
      marketMakers.sort((a, b) => (a.name > b.name ? 1 : -1));
      symbols.sort((a, b) => (a.name > b.name ? 1 : -1));

      let bests = {};

      if (entityName === constants.EXCHANGE) {
        bests = await api.getExchangeBests();
      } else if (entityName === constants.MARKET_MAKER) {
        bests = await api.getMarketMakerBests();
      }

      dispatch(
        ac.loadInitialData.success({ exchanges, marketMakers, symbols, bests })
      );
    } catch (err) {
      dispatch(ac.loadInitialData.error(getErrorMessage(err)));
    }
  };
}

export function loadBests(entityName) {
  return async (dispatch) => {
    let bests = {};

    if (entityName === constants.EXCHANGE) {
      bests = await api.getExchangeBests();
    } else if (entityName === constants.MARKET_MAKER) {
      bests = await api.getMarketMakerBests();
    }
    dispatch(ac.setField({ path: 'bests', value: bests }));
  };
}

export function setField(path, value) {
  return (dispatch) => dispatch(ac.setField({ path, value }));
}
