const module = 'MARKET_MAKER';

export const LOAD_INITIAL_DATA_PENDING = `${module}/LOAD_INITIAL_DATA_PENDING`;
export const LOAD_INITIAL_DATA_SUCCESS = `${module}/LOAD_INITIAL_DATA_SUCCESS`;
export const LOAD_INITIAL_DATA_ERROR = `${module}/LOAD_INITIAL_DATA_ERROR`;

export const DELETE_MARKET_MAKER_PENDING = `${module}/DELETE_MARKET_MAKER_PENDING`;
export const DELETE_MARKET_MAKER_SUCCESS = `${module}/DELETE_MARKET_MAKER_SUCCESS`;
export const DELETE_MARKET_MAKER_ERROR = `${module}/DELETE_MARKET_MAKER_ERROR`;

export const ADD_UPDATE_MARKET_MAKER_PENDING = `${module}/ADD_UPDATE_MARKET_MAKER_PENDING`;
export const ADD_UPDATE_MARKET_MAKER_SUCCESS = `${module}/ADD_UPDATE_MARKET_MAKER_SUCCESS`;
export const ADD_UPDATE_MARKET_MAKER_ERROR = `${module}/ADD_UPDATE_MARKET_MAKER_ERROR`;

export const START_MARKET_MAKER_PENDING = `${module}/START_MARKET_MAKER_PENDING`;
export const START_MARKET_MAKER_SUCCESS = `${module}/START_MARKET_MAKER_SUCCESS`;
export const START_MARKET_MAKER_ERROR = `${module}/START_MARKET_MAKER_ERROR`;

export const STOP_MARKET_MAKER_PENDING = `${module}/STOP_MARKET_MAKER_PENDING`;
export const STOP_MARKET_MAKER_SUCCESS = `${module}/STOP_MARKET_MAKER_SUCCESS`;
export const STOP_MARKET_MAKER_ERROR = `${module}/STOP_MARKET_MAKER_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;

export const SET_DATA_UPDATED = `${module}/SET_DATA_UPDATED`;
