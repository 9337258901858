import * as c from './constants';
import moment from 'moment';

//by default one week
const defaultEndDateTime = new Date();
const defaultStartDateTime = new Date();
defaultStartDateTime.setDate(defaultEndDateTime.getDate() - 1);

const initialState = {
  status: {
    pending: false,
    error: false,
    success: false
  },
  pnlTrades: [],
  pnlTradesTable: [],
  pnlTotal: '0',
  filters: {
    startDateTime: defaultStartDateTime,
    endDateTime: defaultEndDateTime,
    customCommissionPercent: '0',
  },
  meta: {
    page: 1,
    limit: 50,
    count: 0
  },
  isDataUpdated: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.LOAD_DATA_PENDING: {
      return {
        ...state,
        status: { pending: true, error: false, success: false }
      };
    }

    case c.LOAD_DATA_SUCCESS: {
      const count = Math.ceil(
        action.payload.pnlTrades.length / state.meta.limit
      );
      let pnlTotal = 0;
      let pnlTradesTable = [];
      let pnlTrades = action.payload.pnlTrades.map((el, index) => {
        pnlTotal += el.pnl;

        pnlTradesTable.push({
          id: index,
          timestamp: moment(el.timestamp).format('YYYY-MM-DD HH:mm:ss'),
          symbol: el.symbol,
          originalOrderPrice: el.original_order_price,
          originalOrderAmount: el.original_order_amount,
          hedgeOrderSide: el.hedge_order_side,
          hedgeOrderPrice: el.hedge_order_price,
          hedgeOrderAmount: el.hedge_order_amount,
          hedgeCommission: el.hedge_commission,
          pnl: el.pnl,
          pnlTotal: pnlTotal.toFixed(2),
          errorMessage: el.error_msg || ''
        });

        return {
          ...el,
          pnlTotal: pnlTotal.toFixed(2)
        };
      });

      pnlTrades = pnlTrades.reverse();
      pnlTradesTable = pnlTradesTable.reverse();

      return {
        ...state,
        status: { pending: false, error: false, success: true },
        pnlTrades,
        pnlTotal: pnlTotal.toFixed(2),
        pnlTradesTable,
        meta: {
          ...state.meta,
          count
        },
        isDataUpdated: true
      };
    }

    case c.LOAD_DATA_ERROR: {
      return {
        ...state,
        status: { pending: false, error: action.payload, success: false }
      };
    }
    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.path]: action.payload.value
      };
    }

    case c.RESET_META: {
      return {
        ...state,
        meta: {
          ...initialState.meta
        }
      };
    }

    case c.SET_PAGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          page: action.payload
        }
      };
    }

    case c.SET_LIMIT: {
      const limit = action.payload;

      return {
        ...state,
        meta: {
          page: 1,
          limit,
          count: Math.ceil(state.pnlTrades.length / limit)
        }
      };
    }

    case c.RESET_PAGE: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
};
