import React from 'react';
import { Table } from 'reactstrap';
import { isEmpty } from 'lodash';
const className = 'cr-monitoring-table';

class MonitoringTable extends React.Component {
  shouldComponentUpdate() {
    const { data } = this.props;

    if (Object.keys(data).length) this.prevData = data;

    return true;
  }

  renderThead = () => {
    const { columns, withoutSymbolColumn } = this.props;

    return (
      !isEmpty(columns) && (
        <thead>
          <tr>
            {!withoutSymbolColumn && (
              <th>Symbols</th>
            )}
            {columns.map((column, index) => {
              return (
                <th key={index} className='data-column'>
                  {column.name}
                </th>
              );
            })}
          </tr>
        </thead>
      )
    );
  };

  renderTbody() {
    const { columns, withoutSymbolColumn, rows } = this.props;

    if (rows) {
      return (
        <tbody>
          {rows.map((row, index) => {
            return (
              <tr key={index}>
                {!withoutSymbolColumn && (
                <td className='align-middle'>
                  <div className='d-flex align-items-center justify-content-center symbol-cell'>
                    {row.name.toUpperCase()}
                  </div>
                </td>)}
                {columns.map((column, index) => {
                  return <td key={index}>{this.renderCell(column, row)}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      );
    }

    return (
      <tbody>
        <tr>
          {columns.map((column, index) => {
            return <td key={index}>{this.renderCell(column)}</td>;
          })}
        </tr>
      </tbody>
    );
  }

  renderCell = (column, row = null) => {
    const { data } = this.props;

    if (row) {
      return (
        <div>
          {data[column.name] && data[column.name][row.name] && (
            <div className='d-flex data-cell align-items-center'>
              {this.renderPrice(column.name, 'ask', row.name) !== '-' ? (
                <div className='d-flex justify-content-end  align-items-end cell ask'>
                  {this.renderPrice(column.name, 'ask', row.name)}
                </div>
              ) : (
                <div className='d-flex justify-content-center align-items-center cell ask'>
                  {'-'}
                </div>
              )}
              {this.renderPrice(column.name, 'bid', row.name) !== '-' ? (
                <div className='d-flex justify-content-end  align-items-end cell bid'>
                  {this.renderPrice(column.name, 'bid', row.name)}
                </div>
              ) : (
                <div className='d-flex justify-content-center align-items-center cell bid'>
                  {'-'}
                </div>
              )}
            </div>
          )}
          {data[column.name] && data[column.name][row.name] && (
            <div className='date-time-cell d-flex align-items-center justify-content-center'>
              {data[column.name][row.name]['ask'] ||
              data[column.name][row.name]['bid']
                ? new Date().toLocaleString()
                : '-'}
            </div>
          )}
        </div>
      );
    }

    return (
      <div>
        {data[column.name] && (
          <div className='d-flex data-cell align-items-center'>
            {this.renderPrice(column.name, 'ask') !== '-' ? (
              <span className='d-flex justify-content-end  align-items-end cell ask'>
                {this.renderPrice(column.name, 'ask')}
              </span>
            ) : (
              <span className='d-flex justify-content-center  align-items-center cell ask'>
                {'-'}
              </span>
            )}
            {this.renderPrice(column.name, 'bid') !== '-' ? (
              <span className='d-flex justify-content-end  align-items-end cell bid'>
                {this.renderPrice(column.name, 'bid')}
              </span>
            ) : (
              <span className='d-flex justify-content-center  align-items-center cell bid'>
                {'-'}
              </span>
            )}
          </div>
        )}
        {data[column.name] && (
          <div className='date-time-cell d-flex  align-items-center justify-content-center'>
            {data[column.name]['ask'] || data[column.name]['bid']
              ? new Date().toLocaleString()
              : '-'}
          </div>
        )}
      </div>
    );
  };

  formCellPrice = (newPrice) => {
    let newPriceArr = newPrice.toString().split('.');
    let newPriceLeftPart = '';
    let newPriceMiddlePart = '';
    let newPriceRightPart = '';

    if (!isEmpty(newPriceArr)) {
      if (!newPriceArr[1]) newPriceArr.push('00');
      else if (newPriceArr[1].length < 2) newPriceArr[1] = `${newPriceArr[1]}0`;

      if (newPriceArr[1].length === 2) {
        newPriceRightPart = newPriceArr[1][1];
        newPriceMiddlePart = `${newPriceArr[0][newPriceArr[0].length - 1]}.${
          newPriceArr[1][0]
        }`;

        let sliceIndex = newPriceArr[0].length - 1;
        if (newPriceArr[0].length > 1)
          newPriceLeftPart = newPriceArr[0].slice(0, sliceIndex);
      } else if (newPriceArr[1].length > 2) {
        newPriceRightPart = newPriceArr[1][newPriceArr[1].length - 1];
        newPriceMiddlePart = `${newPriceArr[1][newPriceArr[1].length - 3]}${
          newPriceArr[1][newPriceArr[1].length - 2]
        }`;

        if (newPriceArr[1].length === 3)
          newPriceLeftPart = `${newPriceArr[0]}.`;
        else if (newPriceArr[1].length > 3) {
          let sliceIndex = newPriceArr[1].length - 3;
          newPriceLeftPart = `${newPriceArr[0]}.${newPriceArr[1].slice(
            0,
            sliceIndex
          )}`;
        }
      }
    }

    return { newPriceLeftPart, newPriceMiddlePart, newPriceRightPart };
  };

  renderPrice = (columnName, indicator, rowName = null) => {
    const { data } = this.props;
    let newPrice = null;
    let oldPrice = null;

    if (rowName) {
      if (
        data[columnName] &&
        data[columnName][rowName] &&
        data[columnName][rowName][indicator]
      ) {
        newPrice = data[columnName][rowName][indicator];

        if (
          this.prevData &&
          this.prevData[columnName] &&
          this.prevData[columnName][rowName] &&
          this.prevData[columnName][rowName][indicator]
        ) {
          oldPrice = this.prevData[columnName][rowName][indicator];
        } else oldPrice = newPrice;

        const {
          newPriceLeftPart,
          newPriceMiddlePart,
          newPriceRightPart
        } = this.formCellPrice(newPrice);

        if (newPrice >= oldPrice)
          return (
            <div className='d-flex align-items-end'>
              <div>
                {newPriceLeftPart}
                <span className='middle'>{newPriceMiddlePart}</span>{' '}
              </div>
              <div className='d-flex flex-column arrow-section'>
                <span>{newPriceRightPart}</span>
                <i className='fas fa-chevron-up'></i>
              </div>
            </div>
          );
        else if (newPrice < oldPrice)
          return (
            <div className='d-flex align-items-end'>
              <div>
                {newPriceLeftPart}
                <span className='middle'>{newPriceMiddlePart}</span>{' '}
              </div>
              <div className='d-flex flex-column arrow-section'>
                <span>{newPriceRightPart}</span>
                <i className='fas fa-chevron-down'></i>
              </div>
            </div>
          );
      }

      return '-';
    }

    if (data[columnName] && data[columnName][indicator]) {
      newPrice = data[columnName][indicator];

      const {
        newPriceLeftPart,
        newPriceMiddlePart,
        newPriceRightPart
      } = this.formCellPrice(newPrice);

      if (
        this.prevData &&
        this.prevData[columnName] &&
        this.prevData[columnName][indicator]
      )
        oldPrice = this.prevData[columnName][indicator];
      else oldPrice = newPrice;

      if (newPrice >= oldPrice)
        return (
          <div className='d-flex align-items-end'>
            <div>
              {newPriceLeftPart}
              <span className='middle'>{newPriceMiddlePart}</span>{' '}
            </div>
            <div className='d-flex flex-column arrow-section'>
              <span>{newPriceRightPart}</span>
              <i className='fas fa-chevron-up'></i>
            </div>
          </div>
        );
      else if (newPrice < oldPrice)
        return (
          <div className='d-flex align-items-end'>
            <div>
              {newPriceLeftPart}
              <span className='middle'>{newPriceMiddlePart}</span>{' '}
            </div>
            <div className='d-flex flex-column arrow-section'>
              <span>{newPriceRightPart}</span>
              <i className='fas fa-chevron-down'></i>
            </div>
          </div>
        );

      return newPrice;
    }

    return '-';
  };

  render() {
    return (
      <div className='table-wrapper'>
        <Table className={`table-bordered text-center ${className}`}>
          {this.renderThead()}
          {this.renderTbody()}
        </Table>
      </div>
    );
  }
}

export default MonitoringTable;
