import * as c from './constants';

const initialState = {
  status: {
    pending: false,
    error: false,
    success: false
  },
  hedgingPairs: [],
  //WE DONT HAVE ABILITY TO UPDATE
  // marketMaker: null,
  currencies: [],
  isDataUpdated: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.LOAD_INITIAL_DATA_PENDING:
    case c.DELETE_HEDGING_PAIR_PENDING:
    case c.ADD_UPDATE_HEDGING_PAIR_PENDING:
    case c.START_HEDGING_PAIR_PENDING:
    case c.STOP_HEDGING_PAIR_PENDING: {
      return {
        ...state,
        status: { pending: true, error: false, success: false }
      };
    }

    case c.LOAD_INITIAL_DATA_SUCCESS: {
      const { hedgingPairs, currencies } = action.payload;

      return {
        ...state,
        status: { pending: false, error: false, success: true },
        hedgingPairs,
        currencies,
        isDataUpdated: true
      };
    }

    case c.LOAD_INITIAL_DATA_ERROR:
    case c.DELETE_HEDGING_PAIR_ERROR:
    case c.ADD_UPDATE_HEDGING_PAIR_ERROR:
    case c.START_HEDGING_PAIR_ERROR:
    case c.STOP_HEDGING_PAIR_ERROR: {
      return {
        ...state,
        status: { pending: false, error: action.payload, success: false }
      };
    }

    case c.DELETE_HEDGING_PAIR_SUCCESS:
    case c.ADD_UPDATE_HEDGING_PAIR_SUCCESS:
    case c.START_HEDGING_PAIR_SUCCESS:
    case c.STOP_HEDGING_PAIR_SUCCESS: {
      return {
        ...state,
        status: { pending: false, error: false, success: true },
        hedgingPairs: action.payload,
        isDataUpdated: true
      };
    }

    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.path]: action.payload.value
      };
    }

    case c.SET_DATA_UPDATED: {
      return {
        ...state,
        isDataUpdated: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
