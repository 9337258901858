import { api, api2, api3 } from '../config';
import axios from 'axios';

//TODO: make it universal, create route according to enityName
export const getCurrencies = async () => {
  let result = await axios.get(`${api}/currency`);
  return result.data;
};

export const addCurrency = async (entity) => {
  return await axios.post(`${api}/currency`, { ...entity });
};

export const updateCurrency = async (name, entity) => {
  return await axios.put(`${api}/currency/${name}`, { ...entity });
};

export const getSymbols = async () => {
  let result = await axios.get(`${api}/symbol`);
  return result.data;
};

export const addSymbol = async (entity) => {
  return await axios.post(`${api}/symbol`, { ...entity });
};

export const updateSymbol = async (name, entity) => {
  return await axios.put(`${api}/symbol/${name}`, { ...entity });
};

export const getExchanges = async () => {
  let result = await axios.get(`${api}/exchange`);
  return result.data;
};

export const getExchangeClients = async () => {
  let result = await axios.get(`${api}/exchange/available`);
  return result.data;
};

export const getExchange = async (name) => {
  let result = await axios.get(`${api}/exchange/${name}`);
  return result.data;
};

export const getExchangeBests = async () => {
  let result = await axios.get(`${api}/exchange/bests`);
  return result.data.bests;
};

export const addExchange = async (entity) => {
  return await axios.post(`${api}/exchange`, { ...entity });
};

export const updateExchange = async (name, entity) => {
  return await axios.put(`${api}/exchange/${name}`, { ...entity });
};

export const addExchangeAccessKey = async (name, entity) => {
  return await axios.post(`${api}/exchange/${name}/key`, { ...entity });
};

export const addExchangeMapping = async (name, entity) => {
  return await axios.post(`${api}/exchange/${name}/mapping`, { ...entity });
};

export const updateExchangeMapping = async (name, entity) => {
  return await axios.put(`${api}/exchange/${name}/mapping`, { ...entity });
};

export const reconnectExchange = async (name) => {
  return await axios.get(`${api}/exchange/${name}/reconnect`);
};

export const editExchangeAccessKey = async (name, entity) => {
  return await axios.post(`${api}/exchange/${name}/key`, { ...entity });
};

export const editExchangeSettings = async (name, entity) => {
  return await axios.put(`${api}/exchange/${name}`, { ...entity });
};

export const getMarketMakers = async () => {
  let result = await axios.get(`${api}/market-maker`);
  return result.data;
};

export const getMarketMaker = async (name) => {
  let result = await axios.get(`${api}/market-maker/${name}`);
  return result.data;
};

export const getMarketMakerBests = async () => {
  let result = await axios.get(`${api}/market-maker/bests`);
  return result.data.bests;
};

export const addMarketMaker = async (entity) => {
  return await axios.post(`${api}/market-maker`, { ...entity });
};

export const updateMarketMaker = async (name, entity) => {
  return await axios.put(`${api}/market-maker/${name}`, { ...entity });
};

export const deleteMarketMaker = async (name) => {
  return await axios.delete(`${api}/market-maker/${name}`);
};

export const startMarketMaker = async (name) => {
  return await axios.get(`${api}/market-maker/${name}/start`);
};

export const stopMarketMaker = async (name) => {
  return await axios.get(`${api}/market-maker/${name}/stop`);
};

export const getActivityGenerators = async () => {
  let result = await axios.get(`${api}/activity-generator`);
  return result.data;
};

export const getActivityGenerator = async (name) => {
  let result = await axios.get(`${api}/activity-generator/${name}`);
  return result.data;
};

export const getActivityGeneratorSimulator = async (data) => {
  let result = await axios.post(`${api}/simulator`, { ...data });

  return result.data;
};

export const addActivityGenerator = async (entity) => {
  return await axios.post(`${api}/activity-generator`, { ...entity });
};

export const updateActivityGenerator = async (name, entity) => {
  return await axios.put(`${api}/activity-generator/${name}`, { ...entity });
};

export const deleteActivityGenerator = async (name) => {
  return await axios.delete(`${api}/activity-generator/${name}`);
};

export const startActivityGenerator = async (name) => {
  return await axios.get(`${api}/activity-generator/${name}/start`);
};

export const stopActivityGenerator = async (name) => {
  return await axios.get(`${api}/activity-generator/${name}/stop`);
};

export const getFeedGenerators = async () => {
  let result = await axios.get(`${api}/feed`);
  return result.data;
};

export const getFeedGenerator = async (name) => {
  let result = await axios.get(`${api}/feed/${name}`);
  return result.data;
};

export const addFeedGenerator = async (entity) => {
  return await axios.post(`${api}/feed`, { ...entity });
};

export const updateFeedGenerator = async (name, entity) => {
  return await axios.put(`${api}/feed/${name}`, { ...entity });
};

export const deleteFeedGenerator = async (name) => {
  return await axios.delete(`${api}/feed/${name}`);
};

export const startFeedGenerator = async (name) => {
  return await axios.get(`${api}/feed/${name}/start`);
};

export const stopFeedGenerator = async (name) => {
  return await axios.get(`${api}/feed/${name}/stop`);
};

export const getPnlBalances = async (startDay, endDay) => {
  let result = await axios.get(`${api2}/reports`, {
    params: { startDay, endDay }
  });
  return result.data;
};

export const getPnlTrades = async (
  startDateTime,
  endDateTime,
  customCommissionPercent
) => {
  let params = { startDateTime, endDateTime };

  if (customCommissionPercent) params = { ...params, customCommissionPercent };

  let result = await axios.get(`${api3}/hedges`, {
    params
  });
  return result.data;
};

export const getHedgingPairs = async () => {
  let result = await axios.get(`${api3}/hedge_pairs`);
  return result.data.hedge_pairs;
};

export const addHedgingPair = async (entity) => {
  return await axios.post(`${api3}/hedge_pairs`, { ...entity });
};

// export const updateHedgingPair = async (name, entity) => {
//   return await axios.put(`${api3}/hedge_pairs/${name}`, { ...entity });
// };

export const deleteHedgingPair = async (name) => {
  return await axios.delete(`${api3}/hedge_pairs?pair=${name}`);
};

export const startHedgingPair = async (entity) => {
  return await axios.post(`${api3}/hedge_pairs`, { ...entity });
};

export const stopHedgingPair = async (entity) => {
  return await axios.post(`${api3}/hedge_pairs`, { ...entity });
};

export const getEvents = async (eventType) => {
  let result = await axios.post(`${api}/events`, { eventType });
  return result.data.events;
};

export const getHedgingExchangeKeys = async () => {
  let result = await axios.get(`${api3}/exchange_keys`);
  return result.data.exchange_keys;
};

export const addUpdateHedgingExchangeKey = async (entity) => {
  return await axios.post(`${api3}/exchange_keys`, { ...entity });
};

export const deleteHedgingExchangeKey = async (exchange) => {
  return await axios.delete(`${api3}/exchange_keys?exchange=${exchange}`);
};

export const getMarketMakerOrderBooks = async (marketMaker) => {
  let result = await axios.get(`${api}/market-maker/${marketMaker}/orderbooks`);

  return result.data.orderbook;
};

export const publishExchangeOrderBook = async (name, symbol) => {
  return await axios.post(
    `${api}/exchange/${name}/order-book-publishing?symbol=${symbol}`
  );
};
