const module = 'PNL_TRADE';

export const LOAD_DATA_PENDING = `${module}/LOAD_DATA_PENDING`;
export const LOAD_DATA_SUCCESS = `${module}/LOAD_DATA_SUCCESS`;
export const LOAD_DATA_ERROR = `${module}/LOAD_DATA_ERROR`;

export const SET_PAGE = `${module}/SET_PAGE`;
export const SET_LIMIT = `${module}/SET_LIMIT`;
export const SET_FIELD = `${module}/SET_FIELD`;
export const RESET_META = `${module}/SET_META`;
export const RESET_PAGE = `${module}/RESET_PAGE`;
