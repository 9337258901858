import * as c from './constants';
import { asyncActionCreator, actionCreator } from '../../../utils/redux';

export const loadInitialData = asyncActionCreator(
  c.LOAD_INITIAL_DATA_PENDING,
  c.LOAD_INITIAL_DATA_SUCCESS,
  c.LOAD_INITIAL_DATA_ERROR
);

export const addUpdateExchange = asyncActionCreator(
  c.ADD_UPDATE_EXCHANGE_PENDING,
  c.ADD_UPDATE_EXCHANGE_SUCCESS,
  c.ADD_UPDATE_EXCHANGE_ERROR
);

export const reconnectExchange = asyncActionCreator(
  c.RECONNECT_EXCHANGE_PENDING,
  c.RECONNECT_EXCHANGE_SUCCESS,
  c.RECONNECT_EXCHANGE_ERROR
);

export const editAccessKey = asyncActionCreator(
  c.EDIT_ACCESS_KEY_PENDING,
  c.EDIT_ACCESS_KEY_SUCCESS,
  c.EDIT_ACCESS_KEY_ERROR
);

export const editSettings = asyncActionCreator(
  c.EDIT_SETTINGS_PENDING,
  c.EDIT_SETTINGS_SUCCESS,
  c.EDIT_SETTINGS_ERROR
);

export const publishOrderBook = asyncActionCreator(
  c.PUBLISH_ORDER_BOOK_PENDING,
  c.PUBLISH_ORDER_BOOK_SUCCESS,
  c.PUBLISH_ORDER_BOOK_ERROR
);

export const setField = actionCreator(c.SET_FIELD);

export const setDataUpdated = actionCreator(c.SET_DATA_UPDATED);
