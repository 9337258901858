import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AlertModal from './components';
import UI from '../../../redux/modules/UI';

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      hideAlertModal: UI.actions.hideAlertModal,
      acceptAlertModal: UI.actions.acceptAlertModal
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  alertModal: UI.selectors.alertModal(state)
});

export default connect(mapStateToProps, mapActionsToProps)(AlertModal);
