import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Exchanges from '../../../../../../redux/modules/Exchanges';

const ParamountdaxClient = ({ exchange, setUIField }) => {
  const [url, setUrl] = useState('');
  const [account, setAccount] = useState('');

  useEffect(() => {
    const url = exchange.settings.url
      ? exchange.settings.url.replace('https://', '')
      : '';
    const account = exchange.settings.account ? exchange.settings.account : '';
    setUrl(url);
    setAccount(account);

    return () => resetState();
  }, [exchange.settings]);

  const resetState = () => {
    setUrl('');
    setAccount('');
  };

  const generateUrl = (url) => {
    let result = url;
    const substr = 'https://';
    const index = url.indexOf(substr);
    if (index !== 0) result = `${substr}${url}`;
    return result;
  };

  const handleChangeInput = ({ target: { name, value } }) => {
    const settings = {
      ...exchange.settings,
      [name]: name === 'url' ? generateUrl(value) : value
    };
    setUIField('exchange', { ...exchange, settings });
  };

  return (
    <>
      <div className='row'>
        <div className='form-group col-6'>
          <label className='first-letter-upper'>
            Url (<b>https://</b> - will be added automatically)
          </label>
          <input
            type='text'
            value={url}
            name='url'
            className='form-control'
            onChange={handleChangeInput}
          />
        </div>
        <div className='form-group col-6'>
          <label className='first-letter-upper'>Account</label>
          <input
            type='text'
            value={account}
            name='account'
            onChange={handleChangeInput}
            className='form-control'
          />
        </div>
      </div>
    </>
  );
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      setUIField: Exchanges.actions.setField
    },
    dispatch
  );
};

export default connect(null, mapActionsToProps)(ParamountdaxClient);
