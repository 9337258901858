import * as c from './constants';
import { EVENT_TYPE_ERROR  } from '../../../constants';
import { orderBy } from 'lodash';

const initialState = {
  status: {
    pending: false,
    error: false,
    success: false
  },
  eventType: EVENT_TYPE_ERROR,
  events: [],
  isDataUpdated: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.LOAD_EVENTS_PENDING: {
      return {
        ...state,
        status: { pending: true, error: false, success: false }
      };
    }

    case c.LOAD_EVENTS_SUCCESS: {

      let data = [];

      Object.keys(action.payload).forEach((item) => {
        data.push({
          date: item,
          message: action.payload[item]
        })
      });
  
      data = orderBy(data, ['date'], ['desc']);

      return {
        ...state,
        status: { pending: false, error: false, success: true },
        events: action.payload,
        isDataUpdated: true
      };
    }

    case c.LOAD_EVENTS_ERROR: {
      return {
        ...state,
        status: { pending: false, error: action.payload, success: false }
      };
    }

    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.path]: action.payload.value
      };
    }

    case c.SET_DATA_UPDATED: {
      return {
        ...state,
        isDataUpdated: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
