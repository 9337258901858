import * as c from './constants';

const initialState = {
  status: {
    pending: false,
    error: false,
    success: false
  },
  exchanges: [],
  exchange: null,
  exchangeClients: [],
  symbols: [],
  isDataUpdated: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.LOAD_INITIAL_DATA_PENDING:
    case c.DELETE_EXCHANGE_PENDING:
    case c.ADD_UPDATE_EXCHANGE_PENDING:
    case c.RECONNECT_EXCHANGE_PENDING:
    case c.EDIT_ACCESS_KEY_PENDING:
    case c.EDIT_SETTINGS_PENDING:
    case c.PUBLISH_ORDER_BOOK_PENDING: {
      return {
        ...state,
        status: { pending: true, error: false, success: false }
      };
    }

    case c.LOAD_INITIAL_DATA_SUCCESS: {
      const { exchanges, exchangeClients, symbols } = action.payload;

      return {
        ...state,
        status: { pending: false, error: false, success: true },
        exchanges,
        exchangeClients,
        symbols,
        isDataUpdated: true
      };
    }

    case c.LOAD_INITIAL_DATA_ERROR:
    case c.EDIT_SETTINGS_ERROR:
    case c.DELETE_EXCHANGE_ERROR:
    case c.ADD_UPDATE_EXCHANGE_ERROR:
    case c.RECONNECT_EXCHANGE_ERROR:
    case c.PUBLISH_ORDER_BOOK_ERROR: {
      return {
        ...state,
        status: { pending: false, error: action.payload, success: false }
      };
    }

    case c.DELETE_EXCHANGE_SUCCESS:
    case c.ADD_UPDATE_EXCHANGE_SUCCESS: {
      return {
        ...state,
        status: { pending: false, error: false, success: true },
        exchanges: action.payload,
        isDataUpdated: true
      };
    }

    case c.RECONNECT_EXCHANGE_SUCCESS:
    case c.EDIT_SETTINGS_SUCCESS:
    case c.PUBLISH_ORDER_BOOK_SUCCESS: {
      return {
        ...state,
        status: { pending: false, error: false, success: true }
      };
    }

    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.path]: action.payload.value
      };
    }

    case c.SET_DATA_UPDATED: {
      return {
        ...state,
        isDataUpdated: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
