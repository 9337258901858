import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import bn from '../../../../utils/bemnames';
import * as constants from '../../../../constants';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import FormikInputNumber from '../../../FormikInputNumber';
import * as Yup from 'yup';
import InfoIconTooltip from '../../../InfoIconTooltip';
const className = 'cr-modal';
const bem = bn.create('modal');

const initialValues = {
  name: '',
  status: '',
  processorInfo: '',
  processorStatus: '',
  sourceExchange: '',
  sourceSymbol: '',
  targetExchange: '',
  targetSymbol: '',
  minAmount: '0',
  orderBookSize: '0',
  maxAmount: '0',
  askAmountIncreasePercent: '100',
  bidAmountIncreasePercent: '100',
  increasedAskLevelPercent: '0',
  increasedBidLevelPercent: '0',
  orderBookStuckCheckIntervalM: '0',
  priceNotificationMargin: '0',
  cleanupOnErrorEnabled: false
};

class MarketMakerModal extends React.Component {
  constructInitialValues = () => {
    const entity = this.props.marketMaker;

    if (entity)
      return {
        name: entity.name,
        status: entity.info.status,
        processorInfo: entity.info.additionalInfo.processorInfo,
        processorStatus: entity.info.additionalInfo.processorStatus,
        sourceExchange: entity.sourceExchange,
        sourceSymbol: entity.sourceSymbol,
        targetExchange: entity.targetExchange,
        targetSymbol: entity.targetSymbol,
        minAmount: entity.settings.minAmount,
        orderBookSize: entity.settings.orderBookSize,
        maxAmount: entity.settings.maxAmount,
        askAmountIncreasePercent: entity.settings.askAmountIncreasePercent,
        bidAmountIncreasePercent: entity.settings.bidAmountIncreasePercent,
        increasedAskLevelPercent: entity.settings.increasedAskLevelPercent,
        increasedBidLevelPercent: entity.settings.increasedBidLevelPercent,
        orderBookStuckCheckIntervalM:
          entity.settings.orderBookStuckCheckIntervalM,
        priceNotificationMargin: entity.settings.priceNotificationMargin,
        cleanupOnErrorEnabled: !(
          entity.settings.cleanupOnErrorEnabled === 'false' ||
          !('cleanupOnErrorEnabled' in entity.settings)
        )
      };
    else
      return {
        ...initialValues
      };
  };

  constructEditForm = () => {
    const { exchanges, sourceSymbols, targetSymbols } = this.props;

    return (
      <Formik
        initialValues={this.constructInitialValues()}
        validationSchema={Yup.object().shape({
          minAmount: Yup.number().required('Min amount is required'),
          orderBookSize: Yup.number()
            .integer()
            .required('Order book size is required'),
          maxAmount: Yup.number().required('Max amount is required'),
          askAmountIncreasePercent: Yup.number()
            .required('Ask Amount Increase Percent is required')
            .min(100, 'Cant be less than 100'),
          bidAmountIncreasePercent: Yup.number()
            .required('Bid Amount Increase Percent is required')
            .min(100, 'Cant be less than 100'),
          increasedAskLevelPercent: Yup.number().required(
            'Increased Ask Level Percent is required'
          ),
          increasedBidLevelPercent: Yup.number().required(
            'Increased Bid Level Percent is required'
          ),
          orderBookStuckCheckIntervalM: Yup.number().required(
            'Order Book Stuck Check Interval M is required'
          ),
          priceNotificationMargin: Yup.number().required(
            'Price Notification Margin is required'
          ),
          cleanupOnErrorEnabled: Yup.boolean()
        })}
        onSubmit={(fields) => {
          let entity = {
            name: fields.name,
            settings: {
              minAmount: fields.minAmount,
              orderBookSize: fields.orderBookSize,
              maxAmount: fields.maxAmount,
              askAmountIncreasePercent: fields.askAmountIncreasePercent,
              bidAmountIncreasePercent: fields.bidAmountIncreasePercent,
              increasedAskLevelPercent: fields.increasedAskLevelPercent,
              increasedBidLevelPercent: fields.increasedBidLevelPercent,
              orderBookStuckCheckIntervalM: fields.orderBookStuckCheckIntervalM,
              priceNotificationMargin: fields.priceNotificationMargin,
              cleanupOnErrorEnabled: fields.cleanupOnErrorEnabled
            }
          };

          this.props.acceptAddUpdateModal(entity);
        }}>
        {({ errors, touched }) => {
          return (
            <Form>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='name'>Market Maker</label>
                  <Field
                    name='name'
                    type='text'
                    className='form-control'
                    disabled
                  />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='orderBookSize'>Order Book Size</label>
                  <FormikInputNumber name='orderBookSize' />
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='sourceExchange'>Source Exchange</label>
                  <Field
                    name='sourceExchange'
                    as='select'
                    className='form-control'
                    disabled>
                    {exchanges.map((exchange, index) => (
                      <option key={index} value={exchange.name}>
                        {exchange.name}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='sourceSymbol'>Source Symbol</label>
                  <Field
                    name='sourceSymbol'
                    as='select'
                    className='form-control'
                    disabled>
                    {sourceSymbols.map((symbol, index) => (
                      <option key={index} value={symbol.internalSymbol}>
                        {symbol.internalSymbol}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='targetExchange'>Target Exchange</label>
                  <Field
                    name='targetExchange'
                    as='select'
                    className='form-control'
                    disabled>
                    {exchanges.map((exchange, index) => (
                      <option key={index} value={exchange.name}>
                        {exchange.name}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='targetSymbol'>Target Symbol</label>
                  <Field
                    name='targetSymbol'
                    as='select'
                    className='form-control'
                    disabled>
                    {targetSymbols.map((symbol, index) => (
                      <option key={index} value={symbol.internalSymbol}>
                        {symbol.internalSymbol}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='minAmount'>Min Amount/Level</label>
                  <FormikInputNumber name='minAmount' />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='maxAmount'>Max Amount/Level</label>
                  <FormikInputNumber name='maxAmount' />
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-6'>
                  <label className='d-flex' htmlFor='askAmountIncreasePercent'>
                    <span className='mr-1'>Ask Amount Increase Percent</span>
                    <InfoIconTooltip
                      overlay={
                        <p>
                          Increased level amount formula - generated amount +
                          <br />
                          (input percent * generated amount)
                        </p>
                      }
                    />
                  </label>
                  <FormikInputNumber name='askAmountIncreasePercent' />
                </div>
                <div className='form-group col-6'>
                  <label className='d-flex' htmlFor='bidAmountIncreasePercent'>
                    <span className='mr-1'>Bid Amount Increase Percent</span>
                    <InfoIconTooltip
                      overlay={
                        <p>
                          Increased level amount formula - generated amount +
                          <br />
                          (input percent * generated amount)
                        </p>
                      }
                    />
                  </label>
                  <FormikInputNumber name='bidAmountIncreasePercent' />
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-6'>
                  <label className='d-flex' htmlFor='increasedAskLevelPercent'>
                    <span className='mr-1'>Increased Ask Level Percent</span>
                    <InfoIconTooltip
                      overlay={
                        <p>Percentage of increased levels in orderbook.</p>
                      }
                    />
                  </label>
                  <FormikInputNumber name='increasedAskLevelPercent' />
                </div>
                <div className='form-group col-6'>
                  <label className='d-flex' htmlFor='increasedBidLevelPercent'>
                    <span className='mr-1'>Increased Bid Level Percent</span>
                    <InfoIconTooltip
                      overlay={
                        <p>Percentage of increased levels in orderbook.</p>
                      }
                    />
                  </label>
                  <FormikInputNumber name='increasedBidLevelPercent' />
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='orderBookStuckCheckIntervalM'>
                    Order Book Stuck Check Interval M
                  </label>
                  <FormikInputNumber name='orderBookStuckCheckIntervalM' />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='priceNotificationMargin'>
                    Price Notification Margin
                  </label>
                  <FormikInputNumber name='priceNotificationMargin' />
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='enableTargetPrice'>
                    Enable Cleanup On Error
                  </label>
                  <Field
                    name='cleanupOnErrorEnabled'
                    type='checkbox'
                    className={'form-control'}
                  />
                </div>
              </div>
              <div className='float-right'>
                <button type='submit' className='btn btn-primary'>
                  Accept
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  constructAddForm = () => {
    const { exchanges, sourceSymbols, targetSymbols } = this.props;

    return (
      <Formik
        initialValues={this.constructInitialValues()}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Market maker name is required'),
          sourceExchange: Yup.string()
            .notOneOf(
              [Yup.ref('targetExchange')],
              "Source and target exchanges can't match"
            )
            .required('Source exchange is required'),
          sourceSymbol: Yup.string().required('Source symbol is required'),
          targetExchange: Yup.string()
            .notOneOf(
              [Yup.ref('sourceExchange')],
              "Source and target exchanges can't match"
            )
            .required('Target exchange is required'),
          targetSymbol: Yup.string().required('Target symbol is required'),
          minAmount: Yup.number().required('Min amount is required'),
          orderBookSize: Yup.number()
            .integer()
            .required('Order book size is required'),
          maxAmount: Yup.number().required('Max amount is required'),
          askAmountIncreasePercent: Yup.number()
            .required('Ask Amount Increase Percent is required')
            .min(100, 'Cant be less than 100'),
          bidAmountIncreasePercent: Yup.number()
            .required('Bid Amount Increase Percent is required')
            .min(100, 'Cant be less than 100'),
          increasedAskLevelPercent: Yup.number().required(
            'Increased Ask Level Percent is required'
          ),
          increasedBidLevelPercent: Yup.number().required(
            'Increased Bid Level Percent is required'
          ),
          orderBookStuckCheckIntervalM: Yup.number().required(
            'Order Book Stuck Check Interval M is required'
          ),
          priceNotificationMargin: Yup.number().required(
            'Price Notification Margin is required'
          ),
          cleanupOnErrorEnabled: Yup.boolean()
        })}
        onSubmit={(fields) => {
          let entity = {
            name: fields.name,
            sourceExchange: fields.sourceExchange,
            sourceSymbol: fields.sourceSymbol,
            targetExchange: fields.targetExchange,
            targetSymbol: fields.targetSymbol,
            settings: {
              minAmount: fields.minAmount,
              orderBookSize: fields.orderBookSize,
              maxAmount: fields.maxAmount,
              askAmountIncreasePercent: fields.askAmountIncreasePercent,
              bidAmountIncreasePercent: fields.bidAmountIncreasePercent,
              increasedAskLevelPercent: fields.increasedAskLevelPercent,
              increasedBidLevelPercent: fields.increasedBidLevelPercent,
              orderBookStuckCheckIntervalM: fields.orderBookStuckCheckIntervalM,
              priceNotificationMargin: fields.priceNotificationMargin,
              cleanupOnErrorEnabled: fields.cleanupOnErrorEnabled
            }
          };

          this.props.acceptAddUpdateModal(entity);
        }}>
        {({ errors, touched }) => {
          return (
            <Form>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='name'>Market Maker</label>
                  <Field
                    name='name'
                    type='text'
                    className={
                      'form-control' +
                      (errors.name && touched.name ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name='name'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='orderBookSize'>Order Book Size</label>
                  <FormikInputNumber name='orderBookSize' />
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='sourceExchange'>Source Exchange</label>
                  <Field name='sourceExchange'>
                    {({ field, form, meta }) => {
                      return (
                        <div>
                          <select
                            {...field}
                            className={
                              'form-control' +
                              (meta.error && meta.touched ? ' is-invalid' : '')
                            }
                            onChange={(e) => {
                              const { value } = e.target;
                              form.setFieldValue('sourceSymbol', '');
                              this.props.loadSymbols(value, 'sourceSymbols');
                              field.onChange(e);
                            }}>
                            <option value=''></option>
                            {exchanges.map((exchange, index) => (
                              <option key={index} value={exchange.name}>
                                {exchange.name}
                              </option>
                            ))}
                          </select>
                          {meta.touched && meta.error && (
                            <div className='invalid-feedback'>{meta.error}</div>
                          )}
                        </div>
                      );
                    }}
                  </Field>
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='sourceSymbol'>Source Symbol</label>
                  <Field
                    name='sourceSymbol'
                    as='select'
                    className={
                      'form-control' +
                      (errors.sourceSymbol && touched.sourceSymbol
                        ? ' is-invalid'
                        : '')
                    }>
                    <option value=''></option>
                    {sourceSymbols.map((sourceSymbol, index) => (
                      <option key={index} value={sourceSymbol.internalSymbol}>
                        {sourceSymbol.internalSymbol}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name='sourceSymbol'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='targetExchange'>Target Exchange</label>
                  <Field name='targetExchange'>
                    {({
                      field, // { name, value, onChange, onBlur }
                      form,
                      meta
                    }) => (
                      <div>
                        <select
                          {...field}
                          className={
                            'form-control' +
                            (meta.error && meta.touched ? ' is-invalid' : '')
                          }
                          onChange={(e) => {
                            const { value } = e.target;
                            form.setFieldValue('targetSymbol', '');
                            this.props.loadSymbols(value, 'targetSymbols');
                            field.onChange(e);
                          }}>
                          <option value=''></option>
                          {exchanges.map((exchange, index) => (
                            <option key={index} value={exchange.name}>
                              {exchange.name}
                            </option>
                          ))}
                        </select>
                        {meta.touched && meta.error && (
                          <div className='invalid-feedback'>{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='targetSymbol'>Target Symbols</label>
                  <Field
                    name='targetSymbol'
                    as='select'
                    className={
                      'form-control' +
                      (errors.targetSymbol && touched.targetSymbol
                        ? ' is-invalid'
                        : '')
                    }>
                    <option value=''></option>
                    {targetSymbols.map((targetSymbol, index) => (
                      <option key={index} value={targetSymbol.internalSymbol}>
                        {targetSymbol.internalSymbol}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name='targetSymbol'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='minAmount'>Min Amount/Level</label>
                  <FormikInputNumber name='minAmount' />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='maxAmount'>Max Amount/Level</label>
                  <FormikInputNumber name='maxAmount' />
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='askAmountIncreasePercent'>
                    Ask Amount Increase Percent
                  </label>
                  <FormikInputNumber name='askAmountIncreasePercent' />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='bidAmountIncreasePercent'>
                    Bid Amount Increase Percent
                  </label>
                  <FormikInputNumber name='bidAmountIncreasePercent' />
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='increasedAskLevelPercent'>
                    Increased Ask Level Percent
                  </label>
                  <FormikInputNumber name='increasedAskLevelPercent' />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='increasedBidLevelPercent'>
                    Increased Bid Level Percent
                  </label>
                  <FormikInputNumber name='increasedBidLevelPercent' />
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='orderBookStuckCheckIntervalM'>
                    Order Book Stuck Check Interval M
                  </label>
                  <FormikInputNumber name='orderBookStuckCheckIntervalM' />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='priceNotificationMargin'>
                    Price Notification Margin
                  </label>
                  <FormikInputNumber name='priceNotificationMargin' />
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='enableTargetPrice'>
                    Enable Cleanup On Error
                  </label>
                  <Field
                    name='cleanupOnErrorEnabled'
                    type='checkbox'
                    className={'form-control'}
                  />
                </div>
              </div>
              <div className='float-right'>
                <button type='submit' className='btn btn-primary'>
                  Accept
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  render() {
    const { show, actionType, entityName } = this.props.addUpdateModal;
    const { hideAddUpdateModal } = this.props;

    return (
      <Modal
        className={className}
        isOpen={show && entityName === constants.MARKET_MAKER}
        toggle={hideAddUpdateModal}>
        <ModalHeader
          className={`${bem.e('header')}`}
          toggle={hideAddUpdateModal}>
          {`${actionType} Market Maker`}
        </ModalHeader>
        <ModalBody>
          {actionType === constants.UPDATE
            ? this.constructEditForm()
            : this.constructAddForm()}
        </ModalBody>
      </Modal>
    );
  }
}

export default MarketMakerModal;
