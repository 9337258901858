import * as c from './constants';
import { asyncActionCreator, actionCreator } from '../../../utils/redux';

export const loadInitialData = asyncActionCreator(
  c.LOAD_INITIAL_DATA_PENDING,
  c.LOAD_INITIAL_DATA_SUCCESS,
  c.LOAD_INITIAL_DATA_ERROR
);

export const deleteHedgingPair = asyncActionCreator(
  c.DELETE_HEDGING_PAIR_PENDING,
  c.DELETE_HEDGING_PAIR_SUCCESS,
  c.DELETE_HEDGING_PAIR_ERROR
);

export const addUpdateHedgingPair = asyncActionCreator(
  c.ADD_UPDATE_HEDGING_PAIR_PENDING,
  c.ADD_UPDATE_HEDGING_PAIR_SUCCESS,
  c.ADD_UPDATE_HEDGING_PAIR_ERROR
);

export const startHedgingPair = asyncActionCreator(
  c.START_HEDGING_PAIR_PENDING,
  c.START_HEDGING_PAIR_SUCCESS,
  c.START_HEDGING_PAIR_ERROR
);

export const stopHedgingPair = asyncActionCreator(
  c.START_HEDGING_PAIR_PENDING,
  c.START_HEDGING_PAIR_SUCCESS,
  c.START_HEDGING_PAIR_ERROR
);

export const setField = actionCreator(c.SET_FIELD);

export const setDataUpdated = actionCreator(c.SET_DATA_UPDATED);
