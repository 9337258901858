import * as c from './constants';
import { actionCreator } from '../../../utils/redux';

export const showAlertModal = actionCreator(c.SHOW_ALERT_MODAL);
export const hideAlertModal = actionCreator(c.HIDE_ALERT_MODAL);

export const showAddUpdateModal = actionCreator(c.SHOW_ADD_UPDATE_MODAL);
export const hideAddUpdateModal = actionCreator(c.HIDE_ADD_UPDATE_MODAL);

export const showDynamicModal = actionCreator(c.SHOW_DYNAMIC_MODAL);
export const hideDynamicModal = actionCreator(c.HIDE_DYNAMIC_MODAL);

export const addOpenedTab = actionCreator(c.ADD_OPENED_TAB);
export const deleteOpenedTab = actionCreator(c.DELETE_OPENED_TAB);

export const setField = actionCreator(c.SET_FIELD);
