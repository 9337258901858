export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

export const api = process.env.REACT_APP_API;
export const api2 = process.env.REACT_APP_API2;
export const api3 = process.env.REACT_APP_API3;

export const eventTypes = [
  'error',
  'settings'
];

export const feedGeneratorAlgorithms = [
  'RandomWalker',
  'TimedBuffer',
  'BookScaler'
];

export const activityGeneratorAlgorithms = ['TimedTrader', 'PriceSpikesTrader'];

export const urlIcons = [
  {
    to: '/markets/currencies',
    icon: 'fa-cog'
  },
  {
    to: '/markets/symbols',
    icon: 'fa-cog'
  },
  {
    to: '/markets/exchanges',
    icon: 'fa-cog'
  },
  {
    to: '/monitoring/market-maker-live-prices',
    icon: 'fa-chart-bar'
  },
  {
    to: '/monitoring/exchange-live-prices',
    icon: 'fa-chart-bar'
  },
  {
    to: '/market-maker/configuration',
    icon: 'fa-chart-line'
  },
  {
    to: '/market-maker/feed-generator/configuration',
    icon: 'fa-chart-line'
  },
  {
    to: '/market-maker/order-books',
    icon: 'fa-chart-line'
  },
  {
    to: '/activity-generator/trading-activity',
    icon: 'fa-tachometer-alt'
  },
  {
    to: '/activity-generator/simulation',
    icon: 'fa-tachometer-alt'
  },
  {
    to: '/hedging/pnl-balance',
    icon: 'fa-circle-notch'
  },
  {
    to: '/hedging/pnl-trade',
    icon: 'fa-circle-notch'
  },
  {
    to: '/hedging/configuration',
    icon: 'fa-circle-notch'
  },
  {
    to: '/hedging/exchange-keys',
    icon: 'fa-circle-notch'
  },
  {
    to: '/events',
    icon: 'fa-calendar-week'
  }
];
