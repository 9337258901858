import React from 'react';
import logo200Image from 'assets/img/logo/logo_200.png';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const initialValues = {
  login: '',
  password: ''
};

class AuthForm extends React.Component {
  render() {
    return (
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{ ...initialValues }}
        validationSchema={Yup.object().shape({
          login: Yup.string()
            .oneOf(['roberto'], 'Login is wrong')
            .required('Login is required'),
          password: Yup.string()
            .oneOf(['thj593gd8s0'], 'Password is wrong')
            .required('Password is required')
        })}
        onSubmit={() => {
          localStorage.setItem('auth', true);
          this.props.history.push('/market-maker/configuration');
        }}>
        {({ errors, touched }) => {
          return (
            <Form>
              <div className='d-flex justify-content-center'>
                <img alt='' style={{ height: '70px' }} src={logo200Image} />
              </div>
              <div className='row'>
                <div className='form-group col-12'>
                  <label htmlFor='login'>Log in</label>
                  <Field
                    name='login'
                    type='text'
                    className={
                      'form-control' +
                      (errors.login && touched.login ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name='login'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-12'>
                  <label htmlFor='password'>Password</label>
                  <Field
                    name='password'
                    type='password'
                    className={
                      'form-control' +
                      (errors.password && touched.password ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name='password'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12 d-flex'>
                  <button type='submit' className='btn btn-primary w-100'>
                    Login
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default AuthForm;
