import * as c from './constants';
import { asyncActionCreator, actionCreator } from '../../../utils/redux';

export const loadInitialData = asyncActionCreator(
  c.LOAD_INITIAL_DATA_PENDING,
  c.LOAD_INITIAL_DATA_SUCCESS,
  c.LOAD_INITIAL_DATA_ERROR
);

export const getActivityGeneratorSimulator = asyncActionCreator(
  c.GET_ACTIVITY_GENERATOR_SIMULATOR_PENDING,
  c.GET_ACTIVITY_GENERATOR_SIMULATOR_SUCCESS,
  c.GET_ACTIVITY_GENERATOR_SIMULATOR_ERROR
);

export const deleteActivityGenerator = asyncActionCreator(
  c.DELETE_ACTIVITY_GENERATOR_PENDING,
  c.DELETE_ACTIVITY_GENERATOR_SUCCESS,
  c.DELETE_ACTIVITY_GENERATOR_ERROR
);

export const addUpdateActivityGenerator = asyncActionCreator(
  c.ADD_UPDATE_ACTIVITY_GENERATOR_PENDING,
  c.ADD_UPDATE_ACTIVITY_GENERATOR_SUCCESS,
  c.ADD_UPDATE_ACTIVITY_GENERATOR_ERROR
);

export const startActivityGenerator = asyncActionCreator(
  c.START_ACTIVITY_GENERATOR_PENDING,
  c.START_ACTIVITY_GENERATOR_SUCCESS,
  c.START_ACTIVITY_GENERATOR_ERROR
);

export const stopActivityGenerator = asyncActionCreator(
  c.START_ACTIVITY_GENERATOR_PENDING,
  c.START_ACTIVITY_GENERATOR_SUCCESS,
  c.START_ACTIVITY_GENERATOR_ERROR
);

export const setField = actionCreator(c.SET_FIELD);

export const setDataUpdated = actionCreator(c.SET_DATA_UPDATED);
