import * as ac from './actionCreators';
import * as constants from '../../../constants';
import * as api from '../../../api';
import { getErrorMessage } from '../../../utils/error';

export function loadInitialData() {
  return async (dispatch) => {
    dispatch(ac.loadInitialData.pending());

    try {
      const feedGenerators = await api.getFeedGenerators();
      feedGenerators.sort((a, b) => (a.name > b.name ? 1 : -1));

      const exchanges = await api.getExchanges();
      exchanges.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.loadInitialData.success({ feedGenerators, exchanges }));
    } catch (err) {
      dispatch(ac.loadInitialData.error(getErrorMessage(err)));
    }
  };
}

export function loadExchangeMapping(name) {
  return async (dispatch) => {
    const exchange = await api.getExchange(name);
    exchange.mapping.sort((a, b) =>
      a.internalSymbol > b.internalSymbol ? 1 : -1
    );

    dispatch(ac.setField({ path: 'symbols', value: exchange.mapping }));
  };
}

export function loadFeedGenerator(name) {
  return async (dispatch) => {
    const feedGenerator = await api.getFeedGenerator(name);
    dispatch(ac.setField({ path: 'feedGenerator', value: feedGenerator }));
  };
}

export function deleteFeedGenerator(name) {
  return async (dispatch) => {
    dispatch(ac.deleteFeedGenerator.pending());

    try {
      await api.deleteFeedGenerator(name);
      const feedGenerators = await api.getFeedGenerators();
      feedGenerators.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.deleteFeedGenerator.success(feedGenerators));
    } catch (err) {
      dispatch(ac.deleteFeedGenerator.error(getErrorMessage(err)));
    }
  };
}

export function addUpdateFeedGenerator(feedGenerator) {
  return async (dispatch, getState) => {
    dispatch(ac.addUpdateFeedGenerator.pending());

    try {
      const { actionType } = getState().UI.addUpdateModal;

      if (actionType === constants.UPDATE)
        await api.updateFeedGenerator(feedGenerator.name, {
          settings: { ...feedGenerator.settings }
        });
      else await api.addFeedGenerator(feedGenerator);

      const feedGenerators = await api.getFeedGenerators();
      feedGenerators.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.addUpdateFeedGenerator.success(feedGenerators));
    } catch (err) {
      dispatch(ac.addUpdateFeedGenerator.error(getErrorMessage(err)));
    }
  };
}

export function startFeedGenerator(name) {
  return async (dispatch) => {
    dispatch(ac.startFeedGenerator.pending());

    try {
      await api.startFeedGenerator(name);
      const feedGenerators = await api.getFeedGenerators();
      feedGenerators.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.startFeedGenerator.success(feedGenerators));
    } catch (err) {
      dispatch(ac.startFeedGenerator.error(getErrorMessage(err)));
    }
  };
}

export function stopFeedGenerator(name) {
  return async (dispatch) => {
    dispatch(ac.stopFeedGenerator.pending());

    try {
      await api.stopFeedGenerator(name);
      const feedGenerators = await api.getFeedGenerators();
      feedGenerators.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.stopFeedGenerator.success(feedGenerators));
    } catch (err) {
      dispatch(ac.stopFeedGenerator.error(getErrorMessage(err)));
    }
  };
}

export function setField(path, value) {
  return (dispatch) => dispatch(ac.setField({ path, value }));
}

export function setDataUpdated(isDataUpdated) {
  return (dispatch) => dispatch(ac.setDataUpdated(isDataUpdated));
}
