import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import InputsPair from '../../InputsPair';

import Exchanges from '../../../../../../redux/modules/Exchanges';

const BinanceAndCoinbaseProClient = ({ exchange, setUIField }) => {
  const [input1Props, setInput1Props] = useState(null);
  const [input2Props, setInput2Props] = useState(null);

  useEffect(() => {
    const hasAccessKey = !!exchange && !!exchange.accessKeys.length;
    const props1 = {
      value: '',
      onChange: (event) => {
        handleChange('key', event.target.value, exchange);
      }
    };
    const props2 = {
      value: '',
      onChange: (event) => {
        handleChange('value', event.target.value, exchange);
      }
    };
    if (hasAccessKey) {
      if (exchange.accessKeys[0].value)
        props2.value = exchange.accessKeys[0].value;
      if (exchange.accessKeys[0].key) props1.value = exchange.accessKeys[0].key;
    }

    setInput1Props(props1);
    setInput2Props(props2);

    return () => resetState();
  }, [exchange]);

  const resetState = () => {
    setInput1Props(null);
    setInput2Props(null);
  };

  const handleChange = (key, value, exchangeCurr) => {
    const hasAccessKey = !!exchange && !!exchangeCurr.accessKeys.length;
    let accessKeys;
    if (hasAccessKey) {
      accessKeys = [{ ...exchangeCurr.accessKeys[0], [key]: value }];
    } else {
      accessKeys = [{ [key]: value }];
    }

    const exchangeUpdated = { ...exchangeCurr, accessKeys };
    setUIField('exchange', exchangeUpdated);
  };

  if (!input1Props || !input2Props) return null;

  return (
    <InputsPair
      input1Label='Api Key'
      input1Props={input1Props}
      input2Label='Secret Key'
      input2Props={input2Props}
    />
  );
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      setUIField: Exchanges.actions.setField
    },
    dispatch
  );
};

export default connect(null, mapActionsToProps)(BinanceAndCoinbaseProClient);
