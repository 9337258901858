import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HedgingConfig from './components';
import Hedging from '../../../redux/modules/Hedging';
import UI from '../../../redux/modules/UI';

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      loadInitialData: Hedging.actions.loadInitialData,
      //WE DONT ABILITY TO UPDATE
      //   loadMarketMaker: MarketMaker.actions.loadMarketMaker,
      setDataUpdated: Hedging.actions.setDataUpdated,
      showAlertModal: UI.actions.showAlertModal,
      showAddUpdateModal: UI.actions.showAddUpdateModal,
      setUIField: UI.actions.setField,
      addOpenedTab: UI.actions.addOpenedTab
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  status: Hedging.selectors.status(state),
  hedgingPairs: Hedging.selectors.hedgingPairs(state),
  currencies: Hedging.selectors.currencies(state),
  isDataUpdated: Hedging.selectors.isDataUpdated(state),
  openedTabs: UI.selectors.openedTabs(state)
});

export default connect(mapStateToProps, mapActionsToProps)(HedgingConfig);
