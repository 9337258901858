import * as c from './constants';

const initialState = {
  status: {
    pending: false,
    error: false,
    success: false
  },
  symbol: null,
  symbols: [],
  currencies: [],
  isDataUpdated: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.LOAD_INITIAL_DATA_PENDING:
    case c.DELETE_SYMBOL_PENDING:
    case c.ADD_UPDATE_SYMBOL_PENDING: {
      return {
        ...state,
        status: { pending: true, error: false, success: false }
      };
    }

    case c.LOAD_INITIAL_DATA_SUCCESS: {
      const { symbols, currencies } = action.payload;

      return {
        ...state,
        status: { pending: false, error: false, success: true },
        symbols,
        currencies,
        isDataUpdated: true
      };
    }
    case c.LOAD_INITIAL_DATA_ERROR:
    case c.ADD_UPDATE_SYMBOL_ERROR:
    case c.DELETE_SYMBOL_ERROR: {
      return {
        ...state,
        status: { pending: false, error: action.payload, success: false }
      };
    }

    case c.DELETE_SYMBOL_SUCCESS:
    case c.ADD_UPDATE_SYMBOL_SUCCESS: {
      return {
        ...state,
        status: { pending: false, error: false, success: true },
        symbols: action.payload,
        isDataUpdated: true
      };
    }

    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.path]: action.payload.value
      };
    }

    case c.SET_DATA_UPDATED: {
      return {
        ...state,
        isDataUpdated: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
