import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import bn from '../../../../utils/bemnames';
import InfoIconTooltip from '../../../../components/InfoIconTooltip';
import * as constants from '../../../../constants';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import FormikInputNumber from '../../../FormikInputNumber';
import FormikInputSelect from '../../../FormikInputSelect';
import * as Yup from 'yup';
import moment from 'moment';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

const className = 'cr-modal';
const bem = bn.create('modal');

const getInitialValues = (feedGeneratorAlgo) => {
  let baseValues = {
    name: '',
    algorithm: feedGeneratorAlgo,
    orderBookSize: '0'
  };

  if (feedGeneratorAlgo === constants.FEED_GEN_RAND_WALK_ALGO)
    return {
      ...baseValues,
      distance: '0',
      lowerBound: '0',
      spread: '0',
      startPrice: '0',
      upperBound: '0',
      minTickDistance: '0',
      maxTickDistance: '0',
      enableTargetPrice: false,
      targetPriceDateTime: null,
      targetLowerBound: '',
      targetUpperBound: '',
      enableLeadingPair: false,
      leadingExchange: '',
      leadingSymbol: '',
      weightPct: ''
    };
  else if (
    feedGeneratorAlgo === constants.FEED_GEN_TIME_BUFFER_ALGO ||
    feedGeneratorAlgo === constants.FEED_GEN_BOOK_SCALER_ALGO
  ) {
    baseValues = {
      ...baseValues,
      exchange: '',
      symbol: '',
      frequencyS: '0',
      minSpread: '0'
    };

    if (feedGeneratorAlgo === constants.FEED_GEN_TIME_BUFFER_ALGO)
      return {
        ...baseValues,
        minTick: '0'
      };
    else if (feedGeneratorAlgo === constants.FEED_GEN_BOOK_SCALER_ALGO)
      return {
        ...baseValues,
        scaleFactor: '0',
        minPrice: '0',
        maxPrice: '0'
      };
  }
};

class FeedGeneratorModal extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.addUpdateModal.entityName === constants.FEED_GENERATOR &&
      prevProps.addUpdateModal.show &&
      !this.props.addUpdateModal.show
    )
      this.props.setFeedGeneratorField('symbols', []);

    if (
      this.props.addUpdateModal.entityName === constants.FEED_GENERATOR &&
      !prevProps.addUpdateModal.show &&
      this.props.addUpdateModal.show &&
      this.props.addUpdateModal.actionType === constants.UPDATE &&
      (this.props.feedGeneratorAlgorithm ===
        constants.FEED_GEN_TIME_BUFFER_ALGO ||
        this.props.feedGeneratorAlgorithm ===
          constants.FEED_GEN_BOOK_SCALER_ALGO ||
        (this.props.feedGeneratorAlgorithm ===
          constants.FEED_GEN_RAND_WALK_ALGO &&
          this.props.feedGenerator.settings.enableLeadingPair === 'true'))
    ) {
      if (
        this.props.feedGeneratorAlgorithm === constants.FEED_GEN_RAND_WALK_ALGO
      ) {
        this.props.loadExchangeMapping(
          this.props.feedGenerator.settings.leadingExchange
        );
      } else {
        this.props.loadExchangeMapping(
          this.props.feedGenerator.settings.exchange
        );
      }
    }
  }

  constructInitialValues = () => {
    const { feedGenerator: entity, feedGeneratorAlgorithm } = this.props;

    if (entity) {
      let initialBaseValues = {
        name: entity.name,
        algorithm: entity.algorithm,
        orderBookSize: entity.settings.orderBookSize
      };

      if (entity.algorithm === constants.FEED_GEN_RAND_WALK_ALGO)
        return {
          ...initialBaseValues,
          distance: entity.settings.distance,
          lowerBound: entity.settings.lowerBound,
          spread: entity.settings.spread,
          startPrice: entity.settings.startPrice,
          upperBound: entity.settings.upperBound,
          minTickDistance: entity.settings.minTickDistance,
          maxTickDistance: entity.settings.maxTickDistance,
          enableTargetPrice:
            entity.settings.enableTargetPrice === 'false' ||
            !('enableTargetPrice' in entity.settings)
              ? false
              : true,
          targetPriceDateTime: entity.settings.targetPriceDateTime
            ? new Date(entity.settings.targetPriceDateTime)
            : null,
          targetLowerBound: entity.settings.targetLowerBound || '',
          targetUpperBound: entity.settings.targetUpperBound || '',
          enableLeadingPair:
            entity.settings.enableLeadingPair === 'false' ||
            !('enableLeadingPair' in entity.settings)
              ? false
              : true,
          leadingExchange: entity.settings.leadingExchange || '',
          leadingSymbol: entity.settings.leadingSymbol || '',
          weightPct: entity.settings.weightPct || ''
        };
      else if (
        entity.algorithm === constants.FEED_GEN_TIME_BUFFER_ALGO ||
        entity.algorithm === constants.FEED_GEN_BOOK_SCALER_ALGO
      ) {
        initialBaseValues = {
          ...initialBaseValues,
          exchange: entity.settings.exchange,
          symbol: entity.settings.symbol,
          frequencyS: entity.settings.frequencyS,
          minSpread: entity.settings.minSpread
        };

        if (entity.algorithm === constants.FEED_GEN_TIME_BUFFER_ALGO)
          return {
            ...initialBaseValues,
            minTick: entity.settings.minTick
          };
        else if (entity.algorithm === constants.FEED_GEN_BOOK_SCALER_ALGO)
          return {
            ...initialBaseValues,
            scaleFactor: entity.settings.scaleFactor,
            minPrice: entity.settings.minPrice,
            maxPrice: entity.settings.maxPrice
          };
      }
    } else return getInitialValues(feedGeneratorAlgorithm);
  };

  constructForm = () => {
    const {
      addUpdateModal: { actionType },
      feedGeneratorAlgorithm,
      exchanges,
      symbols,
      loadExchangeMapping
    } = this.props;

    const exchangeOptions = exchanges.map((item) => {
      return {
        label: item.name,
        value: item.name
      };
    });

    const symbolOptions = symbols.map((item) => {
      return {
        label: item.internalSymbol,
        value: item.internalSymbol
      };
    });

    const leadingExchangeOnChange = (e, form, field) => {
      const { value } = e.target;

      form.setFieldValue('leadingSymbol', '');
      loadExchangeMapping(value);
      field.onChange(e);
    };

    const targetPriceDateTimeOnChange = (value, form) => {
      if (value) form.setFieldValue('targetPriceDateTime', new Date(value));
      else {
        form.setFieldValue('targetPriceDateTime', null);
      }
    };

    const exchangeOnChange = (e, form, field) => {
      const { value } = e.target;

      form.setFieldValue('symbol', '');
      loadExchangeMapping(value);
      field.onChange(e);
    };

    let validationSchemaObject = {
      name: Yup.string().required('Feed generator name is required'),
      orderBookSize: Yup.number()
        .integer()
        .positive()
        .required('Order book size amount is required')
    };

    if (feedGeneratorAlgorithm === constants.FEED_GEN_RAND_WALK_ALGO) {
      validationSchemaObject = {
        ...validationSchemaObject,
        distance: Yup.number().required('Distance amount is required'),
        lowerBound: Yup.number().required('Lower bound amount is required'),
        spread: Yup.number().required('Spread amount is required'),
        startPrice: Yup.number().required('Start price amount is required'),
        upperBound: Yup.number().required('Upper bound amount is required'),
        minTickDistance: Yup.number()
          .required('Min tick distance is required')
          .lessThan(
            Yup.ref('maxTickDistance'),
            'Min tick should be less than Max tick'
          ),
        maxTickDistance: Yup.number()
          .required('Max tick distance is required')
          .moreThan(
            Yup.ref('minTickDistance'),
            'Max tick should be bigger than Min tick'
          ),
        enableTargetPrice: Yup.boolean(),
        targetPriceDateTime: Yup.date()
          .nullable()
          .when('enableTargetPrice', {
            is: true,
            then: Yup.date().required('Target price date is required')
          }),
        targetLowerBound: Yup.number().when('enableTargetPrice', {
          is: true,
          then: Yup.number()
            .required('Target lower bound is required')
            .lessThan(
              Yup.ref('targetUpperBound'),
              'Should be less than Target Upper Bound'
            )
        }),
        targetUpperBound: Yup.number().when('enableTargetPrice', {
          is: true,
          then: Yup.number()
            .required('Target upper bound is required')
            .moreThan(
              Yup.ref('targetLowerBound'),
              'Should be more than Target Lower Bound'
            )
        }),
        enableLeadingPair: Yup.boolean(),
        leadingExchange: Yup.string().when('enableLeadingPair', {
          is: true,
          then: Yup.string().required('Leading exchange is required')
        }),
        leadingSymbol: Yup.string().when('enableLeadingPair', {
          is: true,
          then: Yup.string().required('Leading symbol is required')
        }),
        weightPct: Yup.number().when('enableLeadingPair', {
          is: true,
          then: Yup.number()
            .positive()
            .required('Weight percentage is required')
        })
      };
    } else if (
      feedGeneratorAlgorithm === constants.FEED_GEN_TIME_BUFFER_ALGO ||
      feedGeneratorAlgorithm === constants.FEED_GEN_BOOK_SCALER_ALGO
    ) {
      validationSchemaObject = {
        ...validationSchemaObject,
        exchange: Yup.string().required('Exchange is required'),
        symbol: Yup.string().required('Symbol is required'),
        frequencyS: Yup.number()
          .integer()
          .positive()
          .required('Frequency S is required')
      };

      if (feedGeneratorAlgorithm === constants.FEED_GEN_TIME_BUFFER_ALGO) {
        validationSchemaObject = {
          ...validationSchemaObject,
          minSpread: Yup.number()
            .positive()
            .required('Min spread is required')
            .moreThan(
              Yup.ref('minTick'),
              'Min spread should be more than Min tick'
            ),
          minTick: Yup.number()
            .positive()
            .required('Min tick is required')
            .lessThan(
              Yup.ref('minSpread'),
              'Min tick should be less than Min spread'
            )
        };
      } else if (
        feedGeneratorAlgorithm === constants.FEED_GEN_BOOK_SCALER_ALGO
      ) {
        validationSchemaObject = {
          ...validationSchemaObject,
          minSpread: Yup.number()
            .positive()
            .required('Min spread is required'),
          scaleFactor: Yup.number()
            .positive()
            .required('Scale factor is required'),
          minPrice: Yup.number()
            .positive()
            .required('Min price is required')
            .lessThan(
              Yup.ref('maxPrice'),
              'Min price must be less than Max price'
            ),
          maxPrice: Yup.number()
            .positive()
            .required('Max price is required')
            .moreThan(
              Yup.ref('minPrice'),
              'Max price must be more than Min price'
            )
        };
      }
    }

    return (
      <Formik
        initialValues={this.constructInitialValues()}
        validationSchema={Yup.object().shape(validationSchemaObject)}
        onSubmit={(fields) => {
          let entity = {
            name: fields.name,
            algorithm: fields.algorithm,
            settings: {
              orderBookSize: fields.orderBookSize
            }
          };

          if (feedGeneratorAlgorithm === constants.FEED_GEN_RAND_WALK_ALGO) {
            entity = {
              ...entity,
              settings: {
                ...entity.settings,
                distance: fields.distance,
                lowerBound: fields.lowerBound,
                spread: fields.spread,
                startPrice: fields.startPrice,
                upperBound: fields.upperBound,
                minTickDistance: fields.minTickDistance,
                maxTickDistance: fields.maxTickDistance,
                enableTargetPrice: fields.enableTargetPrice,
                enableLeadingPair: fields.enableLeadingPair
              }
            };

            if (fields.enableTargetPrice) {
              entity = {
                ...entity,
                settings: {
                  ...entity.settings,
                  targetPriceDateTime: fields.targetPriceDateTime
                    ? moment(fields.targetPriceDateTime).format(
                        'YYYY-MM-DD HH:mm'
                      )
                    : null,
                  targetLowerBound: fields.targetLowerBound,
                  targetUpperBound: fields.targetUpperBound
                }
              };
            }

            if (fields.enableLeadingPair) {
              entity = {
                ...entity,
                settings: {
                  ...entity.settings,
                  leadingExchange: fields.leadingExchange,
                  leadingSymbol: fields.leadingSymbol,
                  weightPct: fields.weightPct
                }
              };
            }
          } else if (
            feedGeneratorAlgorithm === constants.FEED_GEN_TIME_BUFFER_ALGO ||
            feedGeneratorAlgorithm === constants.FEED_GEN_BOOK_SCALER_ALGO
          ) {
            entity = {
              ...entity,
              settings: {
                ...entity.settings,
                exchange: fields.exchange,
                symbol: fields.symbol,
                frequencyS: fields.frequencyS,
                minSpread: fields.minSpread
              }
            };

            if (
              feedGeneratorAlgorithm === constants.FEED_GEN_TIME_BUFFER_ALGO
            ) {
              entity = {
                ...entity,
                settings: {
                  ...entity.settings,
                  minTick: fields.minTick
                }
              };
            } else if (
              feedGeneratorAlgorithm === constants.FEED_GEN_BOOK_SCALER_ALGO
            ) {
              entity = {
                ...entity,
                settings: {
                  ...entity.settings,
                  scaleFactor: fields.scaleFactor,
                  minPrice: fields.minPrice,
                  maxPrice: fields.maxPrice
                }
              };
            }
          }

          this.props.acceptAddUpdateModal(entity);
        }}>
        {({ errors, touched }) => {
          return (
            <Form>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='name'>Feed Generator</label>
                  <Field
                    name='name'
                    type='text'
                    className={
                      'form-control' +
                      (errors.name && touched.name ? ' is-invalid' : '')
                    }
                    disabled={actionType === constants.UPDATE}
                  />
                  <ErrorMessage
                    name='name'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='algorithm'>Algorithm</label>
                  <Field
                    name='algorithm'
                    type='text'
                    className={'form-control'}
                    disabled
                  />
                </div>
              </div>
              {feedGeneratorAlgorithm === constants.FEED_GEN_RAND_WALK_ALGO && (
                <>
                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='lowerBound'>Lower Bound</label>
                      <FormikInputNumber name='lowerBound' />
                    </div>
                    <div className='form-group col-6'>
                      <label htmlFor='upperBound'>Upper Bound</label>
                      <FormikInputNumber name='upperBound' />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='minTickDistance'>Min Tick Distance</label>
                      <FormikInputNumber name='minTickDistance' />
                    </div>
                    <div className='form-group col-6'>
                      <label htmlFor='maxTickDistance'>Max Tick Distance</label>
                      <FormikInputNumber name='maxTickDistance' />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='distance'>Distance</label>
                      <FormikInputNumber name='distance' />
                    </div>
                    <div className='form-group col-6'>
                      <label htmlFor='orderBookSize'>Order Book Size</label>
                      <FormikInputNumber name='orderBookSize' />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='spread'>Spread</label>
                      <FormikInputNumber name='spread' />
                    </div>
                    <div className='form-group col-6'>
                      <label htmlFor='startPrice'>Start Price</label>
                      <FormikInputNumber name='startPrice' />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='enableTargetPrice'>
                        Enable Target Price
                      </label>
                      <Field
                        name='enableTargetPrice'
                        type='checkbox'
                        className={'form-control'}
                      />
                    </div>
                    <div className='form-group col-6'>
                      <label htmlFor='targetPriceDateTime' className='d-flex'>
                        <span className='mr-1'>Target Price Date Time</span>
                          <InfoIconTooltip
                            overlay={
                              <>
                                Random walker algorithm uses time UTC,<br/>
                                it will reach the price target base on it
                              </>
                            }
                          />
                      </label>
                      <Field name='targetPriceDateTime'>
                        {({ field, form, meta }) => {
                          const inputProps = {
                            className: `form-control ${
                              meta.error && meta.touched ? ' is-invalid' : ''
                            }`
                          };

                          if (!form.values.enableTargetPrice) {
                            inputProps.disabled = true;
                          }

                          return (
                            <>
                              <Datetime
                                inputProps={inputProps}
                                onChange={(value) =>
                                  targetPriceDateTimeOnChange(value, form)
                                }
                                value={field.value}
                                disable={!form.values.enableTargetPrice}
                              />
                              {meta.touched && meta.error && (
                                <div
                                  className='invalid-feedback'
                                  style={{ display: 'block' }}>
                                  {meta.error}
                                </div>
                              )}
                            </>
                          );
                        }}
                      </Field>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='distance'>Target Lower Bound</label>
                      <FormikInputNumber
                        name='targetLowerBound'
                        conditionalDisableFieldName='enableTargetPrice'
                        conditionalDisableFieldValue={false}
                      />
                    </div>
                    <div className='form-group col-6'>
                      <label htmlFor='distance'>Target Upper Bound</label>
                      <FormikInputNumber
                        name='targetUpperBound'
                        conditionalDisableFieldName='enableTargetPrice'
                        conditionalDisableFieldValue={false}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='enableLeadingPair'>
                        Enable Leading Pair
                      </label>
                      <Field
                        name='enableLeadingPair'
                        type='checkbox'
                        className={'form-control'}
                      />
                    </div>
                    <div className='form-group col-6'>
                      <label htmlFor='leadingExchange'>Leading Exchange</label>
                      <FormikInputSelect
                        name='leadingExchange'
                        options={exchangeOptions}
                        onChange={leadingExchangeOnChange}
                        conditionalDisableFieldName='enableLeadingPair'
                        conditionalDisableFieldValue={false}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='leadingSymbol'>Leading Symbol</label>
                      <FormikInputSelect
                        name='leadingSymbol'
                        options={symbolOptions}
                        conditionalDisableFieldName='enableLeadingPair'
                        conditionalDisableFieldValue={false}
                      />
                    </div>
                    <div className='form-group col-6'>
                      <label htmlFor='distance'>Weight Percentage, %</label>
                      <FormikInputNumber
                        name='weightPct'
                        conditionalDisableFieldName='enableLeadingPair'
                        conditionalDisableFieldValue={false}
                      />
                    </div>
                  </div>
                </>
              )}

              {(feedGeneratorAlgorithm ===
                constants.FEED_GEN_TIME_BUFFER_ALGO ||
                feedGeneratorAlgorithm ===
                  constants.FEED_GEN_BOOK_SCALER_ALGO) && (
                <>
                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='exchange'>Exchange</label>
                      <FormikInputSelect
                        name='exchange'
                        options={exchangeOptions}
                        onChange={exchangeOnChange}
                      />
                    </div>
                    <div className='form-group col-6'>
                      <label htmlFor='symbol'>Symbol</label>
                      <FormikInputSelect
                        name='symbol'
                        options={symbolOptions}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='frequencyS'>Frequency S</label>
                      <FormikInputNumber name='frequencyS' />
                    </div>
                    <div className='form-group col-6'>
                      <label htmlFor='orderBookSize'>Order Book Size</label>
                      <FormikInputNumber name='orderBookSize' />
                    </div>
                  </div>
                  {feedGeneratorAlgorithm ===
                    constants.FEED_GEN_TIME_BUFFER_ALGO && (
                    <div className='row'>
                      <div className='form-group col-6'>
                        <label htmlFor='minSpread'>Min Spread</label>
                        <FormikInputNumber name='minSpread' />
                      </div>
                      <div className='form-group col-6'>
                        <label htmlFor='minTick'>Min Tick</label>
                        <FormikInputNumber name='minTick' />
                      </div>
                    </div>
                  )}
                  {feedGeneratorAlgorithm ===
                    constants.FEED_GEN_BOOK_SCALER_ALGO && (
                    <>
                      <div className='row'>
                        <div className='form-group col-6'>
                          <label htmlFor='minSpread'>Min Spread</label>
                          <FormikInputNumber name='minSpread' />
                        </div>
                        <div className='form-group col-6'>
                          <label htmlFor='scaleFactor'>Scale Factor</label>
                          <FormikInputNumber name='scaleFactor' />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='form-group col-6'>
                          <label htmlFor='minPrice'>Min Price</label>
                          <FormikInputNumber name='minPrice' />
                        </div>
                        <div className='form-group col-6'>
                          <label htmlFor='maxPrice'>Max Price</label>
                          <FormikInputNumber name='maxPrice' />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              <div className='float-right'>
                <button type='submit' className='btn btn-primary'>
                  Accept
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  render() {
    const { show, actionType, entityName } = this.props.addUpdateModal;
    const { hideAddUpdateModal } = this.props;

    return (
      <Modal
        className={className}
        isOpen={show && entityName === constants.FEED_GENERATOR}
        toggle={hideAddUpdateModal}>
        <ModalHeader
          className={`${bem.e('header')}`}
          toggle={hideAddUpdateModal}>
          {`${actionType} Feed Generator`}
        </ModalHeader>
        <ModalBody>{this.constructForm()}</ModalBody>
      </Modal>
    );
  }
}

export default FeedGeneratorModal;
