import React, { memo } from 'react';
import classNames from 'classnames';

const Loader = ({ fullScreen, small, isLoading, children }) => {
  if (!isLoading && children) {
    return children;
  }

  return (
    <div
      className={classNames('load', {
        'full-screen': fullScreen,
        small: small
      })}>
      <div className='loaderIc'>
        <div className='loading-dots'>
          <div className='loading-dots--dot' />
          <div className='loading-dots--dot' />
          <div className='loading-dots--dot' />
        </div>
      </div>
    </div>
  );
};

export default memo(Loader);
