import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HedgingModal from './components';
import Hedging from '../../../redux/modules/Hedging';
import UI from '../../../redux/modules/UI';

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      hideAddUpdateModal: UI.actions.hideAddUpdateModal,
      acceptAddUpdateModal: UI.actions.acceptAddUpdateModal
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    //WE DONT HAVE ABILITY TO UPDATE
    // hedgingPair: Hedging.selectors.hedgingPair(state),
    currencies: Hedging.selectors.currencies(state),
    addUpdateModal: UI.selectors.addUpdateModal(state)
  };
};

export default connect(mapStateToProps, mapActionsToProps)(HedgingModal);
