export const status = (state) => state.ActivityGenerator.status;
export const activityGenerators = (state) =>
  state.ActivityGenerator.activityGenerators;
export const activityGenerator = (state) =>
  state.ActivityGenerator.activityGenerator;
export const exchanges = (state) => state.ActivityGenerator.exchanges;
export const targetSymbols = (state) => state.ActivityGenerator.targetSymbols;
export const activityGeneratorAlgorithm = (state) =>
  state.ActivityGenerator.activityGeneratorAlgorithm;
export const stockChartData = (state) => state.ActivityGenerator.stockChartData;
export const isDataUpdated = (state) => state.ActivityGenerator.isDataUpdated;
