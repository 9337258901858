import React from 'react';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import { isLoggedIn } from '../../utils/auth';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import '../../styles/reduction.scss';
import ExchangeLivePrices from '../../modules/Monitoring/ExchangeLivePrices';
import MarketMakerLivePrices from '../../modules/Monitoring/MarketMakerLivePrices';
import HedgingPnlBalance from '../../modules/Hedging/PnlBalance';
import HedgingPnlTrade from '../../modules/Hedging/PnlTrade';
import HedgingConfiguration from '../../modules/Hedging/Configuration';
import HedgingExchangeKeys from '../../modules/Hedging/ExchangeKeys';
import Events from '../../modules/Events';
import '@fortawesome/fontawesome-free/css/all.css';

// const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
const ConfigCurrencies = React.lazy(() => import('modules/Markets/Currencies'));
const ConfigSymbols = React.lazy(() => import('modules/Markets/Symbols'));
const ConfigExchanges = React.lazy(() => import('modules/Markets/Exchanges'));
const MarketMakerConfig = React.lazy(() =>
  import('modules/MarketMaker/Configuration')
);
const ActivityGeneratorConfig = React.lazy(() =>
  import('modules/ActivityGenerator/TradingActivity')
);
const ActivityGeneratorSimulation = React.lazy(() =>
  import('modules/ActivityGenerator/Simulation')
);
const FeedGeneratorConfig = React.lazy(() =>
  import('modules/MarketMaker/FeedGenerator')
);
const MarketMakerOrderBooks = React.lazy(() =>
  import('modules/MarketMaker/OrderBooks')
);

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

// routes only for authenticated users
export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn() ? <Component {...props} /> : <Redirect to='/login' />
    }
  />
);

class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <Route
              exact
              path='/logout'
              render={() => {
                localStorage.removeItem('auth');
                this.props.setUIField('openedTabs', []);
                return <Redirect to='/login' />;
              }}
            />

            <LayoutRoute
              exact
              path='/login'
              layout={EmptyLayout}
              component={(props) => <AuthPage {...props} />}
            />

            <MainLayout
              breakpoint={this.props.breakpoint}
              history={this.props.history}>
              <React.Suspense fallback={<PageSpinner />}>
                <Route
                  exact
                  path='/'
                  render={() => <Redirect to='/market-maker/configuration' />}
                />

                <PrivateRoute
                  exact
                  path='/markets/currencies'
                  component={(props) => <ConfigCurrencies {...props} />}
                />
                <PrivateRoute
                  exact
                  path='/markets/symbols'
                  component={(props) => <ConfigSymbols {...props} />}
                />
                <PrivateRoute
                  exact
                  path='/markets/exchanges'
                  component={(props) => <ConfigExchanges {...props} />}
                />
                <PrivateRoute
                  exact
                  path='/market-maker/configuration'
                  component={(props) => <MarketMakerConfig {...props} />}
                />
                <PrivateRoute
                  exact
                  path='/market-maker/feed-generator/configuration'
                  component={(props) => <FeedGeneratorConfig {...props} />}
                />
                <PrivateRoute
                  exact
                  path='/market-maker/order-books'
                  component={(props) => <MarketMakerOrderBooks {...props} />}
                />
                <PrivateRoute
                  exact
                  path='/activity-generator/trading-activity'
                  component={(props) => <ActivityGeneratorConfig {...props} />}
                />
                <PrivateRoute
                  exact
                  path='/activity-generator/simulation'
                  component={(props) => (
                    <ActivityGeneratorSimulation {...props} />
                  )}
                />
                <PrivateRoute
                  exact
                  path='/monitoring/exchange-live-prices'
                  component={(props) => <ExchangeLivePrices {...props} />}
                />
                <PrivateRoute
                  exact
                  path='/monitoring/market-maker-live-prices'
                  component={(props) => <MarketMakerLivePrices {...props} />}
                />
                <PrivateRoute
                  exact
                  path='/hedging/pnl-balance'
                  component={(props) => <HedgingPnlBalance {...props} />}
                />
                <PrivateRoute
                  exact
                  path='/hedging/pnl-trade'
                  component={(props) => <HedgingPnlTrade {...props} />}
                />
                <PrivateRoute
                  exact
                  path='/hedging/configuration'
                  component={(props) => <HedgingConfiguration {...props} />}
                />
                <PrivateRoute
                  exact
                  path='/hedging/exchange-keys'
                  component={(props) => <HedgingExchangeKeys {...props} />}
                />
                <PrivateRoute
                  exact
                  path='/events'
                  component={(props) => <Events {...props} />}
                />
              </React.Suspense>
            </MainLayout>
            <Redirect to='/' />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
