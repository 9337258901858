import React from 'react';

const InputsPair = ({ input1Label, input1Props, input2Label, input2Props }) => {
  return (
    <div className='row'>
      <div className='form-group col-6'>
        <label className='first-letter-upper'>{input1Label}</label>
        <input type='text' className='form-control' {...input1Props} />
      </div>
      <div className='form-group col-6'>
        <label className='first-letter-upper'>{input2Label}</label>
        <input type='text' className='form-control' {...input2Props} />
      </div>
    </div>
  );
};

export default InputsPair;
