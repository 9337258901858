const module = 'HEDGING_EXCHANGE_KEYS';

export const LOAD_EXCHANGE_KEYS_PENDING = `${module}/LOAD_EXCHANGE_KEYS_PENDING`;
export const LOAD_EXCHANGE_KEYS_SUCCESS = `${module}/LOAD_EXCHANGE_KEYS_SUCCESS`;
export const LOAD_EXCHANGE_KEYS_ERROR = `${module}/LOAD_EXCHANGE_KEYS_ERROR`;

export const LOAD_EXCHANGE_KEY_PENDING = `${module}/LOAD_EXCHANGE_KEY_PENDING`;
export const LOAD_EXCHANGE_KEY_SUCCESS = `${module}/LOAD_EXCHANGE_KEY_SUCCESS`;
export const LOAD_EXCHANGE_KEY_ERROR = `${module}/LOAD_EXCHANGE_KEY_ERROR`;

export const DELETE_EXCHANGE_KEY_PENDING = `${module}/DELETE_EXCHANGE_KEY_PENDING`;
export const DELETE_EXCHANGE_KEY_SUCCESS = `${module}/DELETE_EXCHANGE_KEY_SUCCESS`;
export const DELETE_EXCHANGE_KEY_ERROR = `${module}/DELETE_EXCHANGE_KEY_ERROR`;

export const ADD_UPDATE_EXCHANGE_KEY_PENDING = `${module}/ADD_UPDATE_EXCHANGE_KEY_PENDING`;
export const ADD_UPDATE_EXCHANGE_KEY_SUCCESS = `${module}/ADD_UPDATE_EXCHANGE_KEY_SUCCESS`;
export const ADD_UPDATE_EXCHANGE_KEY_ERROR = `${module}/ADD_UPDATE_EXCHANGE_KEY_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;

export const SET_DATA_UPDATED = `${module}/SET_DATA_UPDATED`;
