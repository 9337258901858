import * as ac from './actionCreators';
import Currencies from '../Currencies';
import Symbols from '../Symbols';
import * as constants from '../../../constants';
import MarketMaker from '../MarketMaker';
import Exchanges from '../Exchanges';
import ActivityGenerator from '../ActivityGenerator';
import FeedGenerator from '../FeedGenerator';
import Hedging from '../Hedging';
import HedgingExchangeKeys from '../HedgingExchangeKeys';

export function showAlertModal(payload) {
  return (dispatch) => dispatch(ac.showAlertModal(payload));
}

export function hideAlertModal() {
  return (dispatch) => dispatch(ac.hideAlertModal());
}

export function acceptAlertModal() {
  return async (dispatch, getState) => {
    const { id, entityName, actionType, entity } = getState().UI.alertModal;

    switch (actionType) {
      case constants.DELETE:
        switch (entityName) {
          case constants.MARKET_MAKER:
            await dispatch(MarketMaker.actions.deleteMarketMaker(id));
            break;
          case constants.ACTIVITY_GENERATOR:
            await dispatch(
              ActivityGenerator.actions.deleteActivityGenerator(id)
            );
            break;
          case constants.FEED_GENERATOR:
            await dispatch(FeedGenerator.actions.deleteFeedGenerator(id));
            break;
          case constants.HEDGING:
            await dispatch(Hedging.actions.deleteHedgingPair(id));
            break;
          case constants.HEDGING_EXCHANGE_KEY:
            await dispatch(HedgingExchangeKeys.actions.deleteExchangeKey(id));
            break;
          default:
            break;
        }
        break;
      case constants.MM_START:
        await dispatch(MarketMaker.actions.startMarketMaker(id));
        break;
      case constants.MM_STOP:
        await dispatch(MarketMaker.actions.stopMarketMaker(id));
        break;
      case constants.AG_START:
        await dispatch(ActivityGenerator.actions.startActivityGenerator(id));
        break;
      case constants.AG_STOP:
        await dispatch(ActivityGenerator.actions.stopActivityGenerator(id));
        break;
      case constants.FG_START:
        await dispatch(FeedGenerator.actions.startFeedGenerator(id));
        break;
      case constants.FG_STOP:
        await dispatch(FeedGenerator.actions.stopFeedGenerator(id));
        break;
      case constants.HEDGING_START:
        await dispatch(Hedging.actions.startHedgingPair(entity));
        break;
      case constants.HEDGING_STOP:
        await dispatch(Hedging.actions.stopHedgingPair(entity));
        break;
      case constants.RECONNECT:
        switch (entityName) {
          case constants.EXCHANGE:
            await dispatch(Exchanges.actions.reconnectExchange(id));
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    dispatch(ac.hideAlertModal());
  };
}

export function showAddUpdateModal(payload) {
  return (dispatch) => dispatch(ac.showAddUpdateModal(payload));
}

export function addOpenedTab(payload) {
  return (dispatch) => dispatch(ac.addOpenedTab(payload));
}

export function deleteOpenedTab(payload) {
  return (dispatch) => dispatch(ac.deleteOpenedTab(payload));
}

export function hideAddUpdateModal() {
  return (dispatch, getState) => {
    const { entityName } = getState().UI.addUpdateModal;

    switch (entityName) {
      case constants.CURRENCY:
        dispatch(Currencies.actions.setField('currency', null));
        break;
      case constants.SYMBOL:
        dispatch(Symbols.actions.setField('symbol', null));
        break;
      case constants.EXCHANGE:
        dispatch(Exchanges.actions.setField('exchange', null));
        break;
      case constants.MARKET_MAKER:
        dispatch(MarketMaker.actions.setField('marketMaker', null));
        dispatch(MarketMaker.actions.setField('sourceSymbols', []));
        dispatch(MarketMaker.actions.setField('targetSymbols', []));
        break;
      case constants.ACTIVITY_GENERATOR:
        dispatch(ActivityGenerator.actions.setField('activityGenerator', null));
        dispatch(ActivityGenerator.actions.setField('targetSymbols', []));
        break;
      case constants.FEED_GENERATOR:
        dispatch(FeedGenerator.actions.setField('feedGenerator', null));
        break;
      default:
        break;
    }

    dispatch(ac.hideAddUpdateModal(entityName));
  };
}

export function acceptAddUpdateModal(entity) {
  return async (dispatch, getState) => {
    const { entityName } = getState().UI.addUpdateModal;

    switch (entityName) {
      case constants.CURRENCY:
        await dispatch(Currencies.actions.addUpdateCurrency(entity));
        dispatch(Currencies.actions.setField('currency', null));
        break;
      case constants.SYMBOL:
        await dispatch(Symbols.actions.addUpdateSymbol(entity));
        dispatch(Symbols.actions.setField('symbol', null));
        break;
      case constants.EXCHANGE:
        await dispatch(Exchanges.actions.addUpdateExchange(entity));
        break;
      case constants.MARKET_MAKER:
        await dispatch(MarketMaker.actions.addUpdateMarketMaker(entity));
        dispatch(MarketMaker.actions.setField('marketMaker', null));
        dispatch(MarketMaker.actions.setField('sourceSymbols', []));
        dispatch(MarketMaker.actions.setField('targetSymbols', []));
        break;
      case constants.ACTIVITY_GENERATOR:
        await dispatch(
          ActivityGenerator.actions.addUpdateActivityGenerator(entity)
        );
        dispatch(ActivityGenerator.actions.setField('activityGenerator', null));
        dispatch(ActivityGenerator.actions.setField('targetSymbols', []));
        break;
      case constants.FEED_GENERATOR:
        await dispatch(FeedGenerator.actions.addUpdateFeedGenerator(entity));
        dispatch(FeedGenerator.actions.setField('feedGenerator', null));
        break;

      case constants.HEDGING:
        await dispatch(Hedging.actions.addUpdateHedgingPair(entity));
        break;

      case constants.HEDGING_EXCHANGE_KEY:
        await dispatch(
          HedgingExchangeKeys.actions.addUpdateExchangeKey(entity)
        );
        dispatch(HedgingExchangeKeys.actions.setField('exchangeKey', null));
        break;
      default:
        break;
    }

    dispatch(ac.hideAddUpdateModal());
  };
}

export function showDynamicModal(payload) {
  return (dispatch) => dispatch(ac.showDynamicModal(payload));
}

export function hideDynamicModal() {
  return (dispatch) => {
    dispatch(Exchanges.actions.setField('exchange', null));
    dispatch(ac.hideDynamicModal());
  };
}

export function setField(name, value) {
  return (dispatch) => dispatch(ac.setField({ name, value }));
}
