import React from 'react';

import ParamountdaxClient from './ParamountdaxClient';
import BinanceAndCoinbaseProClient from './BinanceAndCoinbaseProClient';

const AccessKeys = ({ exchange }) => {
  const generateAccessKeys = () => {
    switch (exchange.exchangeClient) {
      case 'paramountdax':
        return <ParamountdaxClient exchange={exchange} />;
      default:
        return <BinanceAndCoinbaseProClient exchange={exchange} />;
    }
  };

  return generateAccessKeys();
};

export default AccessKeys;
