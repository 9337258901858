import {
  Header,
  Content,
  Footer,
  ContentHeader,
  Sidebar
} from 'components/Layout';
import React from 'react';
import 'react-icons/md';

class MainLayout extends React.Component {
  state = {
    isSidebarOpen: true
  };

  isSidebarOpen = () => {
    return document
      .querySelector('.cr-sidebar')
      .classList.contains('cr-sidebar--open');
  };

  openCloseSidebar = () => {
    this.setState({
      isSidebarOpen: !this.state.isSidebarOpen
    });
  };

  render() {
    const { children, openedTabs, addOpenedTab, deleteOpenedTab } = this.props;

    return (
      <main className='cr-app'>
        <Header openCloseSidebar={this.openCloseSidebar} />
        <Sidebar
          isSidebarOpen={this.state.isSidebarOpen}
          addOpenedTab={addOpenedTab}
        />
        <Content fluid onClick={this.handleContentClick}>
          <ContentHeader
            deleteOpenedTab={deleteOpenedTab}
            openedTabs={openedTabs}
          />
          {children}
          <Footer />
        </Content>
      </main>
    );
  }
}

export default MainLayout;
