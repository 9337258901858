const module = 'HEDGING_PAIR';

export const LOAD_INITIAL_DATA_PENDING = `${module}/LOAD_INITIAL_DATA_PENDING`;
export const LOAD_INITIAL_DATA_SUCCESS = `${module}/LOAD_INITIAL_DATA_SUCCESS`;
export const LOAD_INITIAL_DATA_ERROR = `${module}/LOAD_INITIAL_DATA_ERROR`;

export const DELETE_HEDGING_PAIR_PENDING = `${module}/DELETE_HEDGING_PAIR_PENDING`;
export const DELETE_HEDGING_PAIR_SUCCESS = `${module}/DELETE_HEDGING_PAIR_SUCCESS`;
export const DELETE_HEDGING_PAIR_ERROR = `${module}/DELETE_HEDGING_PAIR_ERROR`;

export const ADD_UPDATE_HEDGING_PAIR_PENDING = `${module}/ADD_UPDATE_HEDGING_PAIR_PENDING`;
export const ADD_UPDATE_HEDGING_PAIR_SUCCESS = `${module}/ADD_UPDATE_HEDGING_PAIR_SUCCESS`;
export const ADD_UPDATE_HEDGING_PAIR_ERROR = `${module}/ADD_UPDATE_HEDGING_PAIR_ERROR`;

export const START_HEDGING_PAIR_PENDING = `${module}/START_HEDGING_PAIR_PENDING`;
export const START_HEDGING_PAIR_SUCCESS = `${module}/START_HEDGING_PAIR_SUCCESS`;
export const START_HEDGING_PAIR_ERROR = `${module}/START_HEDGING_PAIR_ERROR`;

export const STOP_HEDGING_PAIR_PENDING = `${module}/STOP_HEDGING_PAIR_PENDING`;
export const STOP_HEDGING_PAIR_SUCCESS = `${module}/STOP_HEDGING_PAIR_SUCCESS`;
export const STOP_HEDGING_PAIR_ERROR = `${module}/STOP_HEDGING_PAIR_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;

export const SET_DATA_UPDATED = `${module}/SET_DATA_UPDATED`;
