import * as c from './constants';
import { asyncActionCreator, actionCreator } from '../../../utils/redux';

export const loadData = asyncActionCreator(
  c.LOAD_DATA_PENDING,
  c.LOAD_DATA_SUCCESS,
  c.LOAD_DATA_ERROR
);

export const resetMeta = actionCreator(c.RESET_META);
export const setField = actionCreator(c.SET_FIELD);
export const setPage = actionCreator(c.SET_PAGE);
export const setLimit = actionCreator(c.SET_LIMIT);
export const resetPage = actionCreator(c.RESET_PAGE);
