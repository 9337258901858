import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const DateFilter = ({ filters, setField, filter }) => {
  const applyFilter = (startDay, endDay) => {
    const latestStartDay = startDay ? startDay : filters.startDay;
    const latestEndDay = endDay ? endDay : filters.endDay;

    setStartDayError('');
    setEndDayError('');
    setStartEndDayError('');

    if (!latestStartDay) setStartDayError('Cant be empty');
    else if (!latestEndDay) setEndDayError('Cant be empty');
    else if (latestStartDay > latestEndDay)
      setStartEndDayError('Start day cant be later, than end day');
    else {
      filter();
    }
  };

  const [startDayError, setStartDayError] = useState('');
  const [endDayError, setEndDayError] = useState('');
  const [startEndDayError, setStartEndDayError] = useState('');

  const setOneDay = () => {
    const currDay = new Date();
    currDay.setHours(0, 0, 0, 0);

    setField('filters', { ...filters, startDay: currDay, endDay: currDay });
    applyFilter(currDay, currDay);
  };

  const setOneWeek = () => {
    const endDay = new Date();
    endDay.setHours(0, 0, 0, 0);
    const startDay = new Date();
    startDay.setDate(endDay.getDate() - 6);

    setField('filters', { ...filters, startDay, endDay });
    applyFilter(startDay, endDay);
  };

  const setOneMonth = () => {
    const endDay = new Date();
    endDay.setHours(0, 0, 0, 0);
    const startDay = new Date();
    startDay.setMonth(endDay.getMonth() - 1);
    startDay.setHours(0, 0, 0);

    setField('filters', { ...filters, startDay, endDay });
    applyFilter(startDay, endDay);
  };

  const setAllTime = () => {
    const startDay = new Date('05/13/2021');
    startDay.setHours(0, 0, 0, 0);
    const endDay = new Date();
    endDay.setHours(0, 0, 0);

    setField('filters', { ...filters, startDay, endDay });
    applyFilter(startDay, endDay);
  };

  return (
    <div className='filter d-flex p-3 align-items-center'>
      <div className='mr-2'>Day Start:</div>
      <div className='date-picker-section mr-3'>
        <DatePicker
          selected={filters.startDay}
          onChange={(date) => {
            date.setHours(0, 0, 0, 0);
            setField('filters', { ...filters, startDay: date });
            applyFilter(date);
          }}
        />
        <span className='date-picker-error error'>{startDayError}</span>
      </div>
      <div className='mr-2'>Day End:</div>
      <div className='date-picker-section mr-3'>
        <DatePicker
          selected={filters.endDay}
          onChange={(date) => {
            setField('filters', { ...filters, endDay: date });
            applyFilter(null, date);
          }}
        />
        <span className='date-picker-error error'>{endDayError}</span>
      </div>
      <button className='btn btn-sm btn-primary mr-2' onClick={setOneDay}>
        1 day
      </button>
      <button className='btn btn-sm btn-primary mr-2' onClick={setOneWeek}>
        1 week
      </button>
      <button className='btn btn-sm btn-primary mr-2' onClick={setOneMonth}>
        1 month
      </button>
      <button className='btn btn-sm btn-primary mr-5' onClick={setAllTime}>
        All time
      </button>
      <span className='filter-section-error error'>{startEndDayError}</span>
    </div>
  );
};

export default DateFilter;
