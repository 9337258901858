import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NewPage from './NewPage';
import FeedGenerator from '../../redux/modules/FeedGenerator';
import ActivityGenerator from '../../redux/modules/ActivityGenerator';
import EventsState from '../../redux/modules/Events';
import MarketMakerOrderBooksState from '../../redux/modules/MarketMakerOrderBooks';

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      feedGeneratorSetField: FeedGenerator.actions.setField,
      activityGeneratorSetField: ActivityGenerator.actions.setField,
      eventTypeSetField: EventsState.actions.setField,
      marketMakerSetField: MarketMakerOrderBooksState.actions.setField
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    feedGeneratorAlgorithm: FeedGenerator.selectors.feedGeneratorAlgorithm(
      state
    ),
    activityGeneratorAlgorithm: ActivityGenerator.selectors.activityGeneratorAlgorithm(
      state
    ),
    eventType: EventsState.selectors.eventType(
      state
    ),
    marketMaker: MarketMakerOrderBooksState.selectors.marketMaker(
      state
    ),
    marketMakers: MarketMakerOrderBooksState.selectors.marketMakers(
      state
    ),
  };
};

export default connect(mapStateToProps, mapActionsToProps)(NewPage);
