import React, { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';

const DateFilter = ({ filters, setField, filter, isDisabled }) => {
  const applyFilter = (startDateTime, endDateTime) => {
    const latestStartDateTime = startDateTime
      ? startDateTime
      : filters.startDateTime;
    const latestEndDateTime = endDateTime ? endDateTime : filters.endDateTime;

    setStartDateTimeError('');
    setEndDateTimeError('');
    setStartEndDateTimeError('');

    if (!latestStartDateTime) setStartDateTimeError('Cant be empty');
    else if (!latestEndDateTime) setEndDateTimeError('Cant be empty');
    else if (latestStartDateTime > latestEndDateTime)
      setStartEndDateTimeError('Start day cant be later, than end day');
    else filter();
  };

  const [startDateTimeError, setStartDateTimeError] = useState('');
  const [endDateTimeError, setEndDateTimeError] = useState('');
  const [startEndDateTimeError, setStartEndDateTimeError] = useState('');

  const setOneDay = () => {
    const endDateTime = new Date();
    const startDateTime = new Date();
    startDateTime.setHours(endDateTime.getHours() - 24);

    setField('filters', { ...filters, startDateTime, endDateTime });
  };

  const setOneWeek = () => {
    const endDateTime = new Date();
    const startDateTime = new Date();
    startDateTime.setDate(endDateTime.getDate() - 7);

    setField('filters', { ...filters, startDateTime, endDateTime });
  };

  const setOneMonth = () => {
    const endDateTime = new Date();
    const startDateTime = new Date();
    startDateTime.setMonth(endDateTime.getMonth() - 1);

    setField('filters', { ...filters, startDateTime, endDateTime });
  };

  return (
    <div className='filter d-flex p-3 align-items-center'>
      <div className='mr-2'>Start:</div>
      <div className='date-picker-section mr-3'>
        <DateTimePicker
          disabled={isDisabled}
          clearIcon={null}
          value={filters.startDateTime}
          onChange={(date) => {
            setField('filters', { ...filters, startDateTime: date });
          }}
        />
        <span className='date-picker-error error'>{startDateTimeError}</span>
      </div>
      <div className='mr-2'>End:</div>
      <div className='date-picker-section mr-3'>
        <DateTimePicker
          disabled={isDisabled}
          clearIcon={null}
          value={filters.endDateTime}
          onChange={(date) => {
            setField('filters', { ...filters, endDateTime: date });
          }}
        />
        <span className='date-picker-error error'>{endDateTimeError}</span>
      </div>
      <button
        className='btn btn-sm btn-primary mr-2'
        onClick={setOneDay}
        disabled={isDisabled}>
        1 day
      </button>
      <button
        className='btn btn-sm btn-primary mr-2'
        onClick={setOneWeek}
        disabled={isDisabled}>
        1 week
      </button>
      <button
        className='btn btn-sm btn-primary mr-2'
        onClick={setOneMonth}
        disabled={isDisabled}>
        1 month
      </button>
      <div className='d-flex align-items-center'>
        <span className='mr-2'>comission %</span>
        <input type='text' disabled={isDisabled}  className='mr-2'
          style={{width: '50px'}}
          onChange={(e) => {
            setField('filters', { ...filters, customCommissionPercent: e.target.value });
          }}
          value={filters.customCommissionPercent}
        />
      </div>
      <button
        className='btn btn-sm btn-primary mr-2'
        onClick={applyFilter}
        disabled={isDisabled}>
        Apply Filter
      </button>
      <span className='filter-section-error error'>
        {startEndDateTimeError}
      </span>
    </div>
  );
};

export default DateFilter;
