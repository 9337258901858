import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import bn from '../../../../utils/bemnames';
import * as constants from '../../../../constants';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const className = 'cr-modal';
const bem = bn.create('modal');

const initialValues = {
  name: '',
  exchangeClient: '',
  apiKey: ''
};

class ExchangeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdditionalFieldOpened: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.addUpdateModal.entityName === constants.EXCHANGE &&
      prevProps.addUpdateModal.show &&
      !this.props.addUpdateModal.show
    )
      this.setState({ isAdditionalFieldOpened: false });
  }

  constructInitialValues = () => {
    return {
      ...initialValues
    };
  };

  constructForm = () => {
    return (
      <Formik
        initialValues={this.constructInitialValues()}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Currency name is required'),
          exchangeClient: Yup.string().required('Exchange client is required'),
          apiKey: Yup.string().when('exchangeClient', {
            is: (exchangeClient) => exchangeClient === 'paramountdax',
            then: Yup.string()
              .required('Api key is required')
              .typeError('Api key is required')
          }),
        })}
        onSubmit={(fields) => {
          let entity = {
            name: fields.name,
            exchangeClient: fields.exchangeClient,
            settings: {}
          };

          entity['accessKeys'] = [];
          if (this.state.isAdditionalFieldOpened){
            entity['accessKeys'].push({ key: 'apiKey', value: fields.apiKey });

            if(fields.url){
              const substr = 'https://';
              const index = fields.url.indexOf(substr);
        
              if(index !== 0) 
                entity['settings'] = {
                  url:  `${substr}${fields.url}`
                };
              else 
                entity['settings'] = {
                  url:  fields.url
                };
            } 
          }

          this.props.acceptAddUpdateModal(entity);
        }}>
        {({ errors, touched }) => {
          return (
            <Form>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='name'>Exchange</label>
                  <Field
                    name='name'
                    type='text'
                    className={
                      'form-control' +
                      (errors.name && touched.name ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name='name'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='exchangeClient'>Echange Client</label>
                  <Field name='exchangeClient'>
                    {({ field, form: { touched, errors }, meta }) => (
                      <div>
                        <select
                          {...field}
                          className={
                            'form-control' +
                            (meta.error && meta.touched ? ' is-invalid' : '')
                          }
                          onChange={(e) => {
                            const { value } = e.target;
                            if (
                              value === constants.EXCHANGE_CLIENT_PARAMOUNTDAX
                            )
                              this.setState({ isAdditionalFieldOpened: true });
                            else
                              this.setState({ isAdditionalFieldOpened: false });
                            field.onChange(e);
                          }}>
                          <option value=''></option>
                          {this.props.exchangeClients.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                        {meta.touched && meta.error && (
                          <div className='invalid-feedback'>{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
              </div>
              {this.state.isAdditionalFieldOpened && (
                <div className='row'>
                  <div className='form-group col-6'>
                    <label htmlFor='apiKey'>Api Key</label>
                    <Field
                      name='apiKey'
                      type='text'
                      className={
                        'form-control' +
                        (errors.apiKey && touched.apiKey ? ' is-invalid' : '')
                      }
                    />
                    <ErrorMessage
                      name='apiKey'
                      component='div'
                      className='invalid-feedback'
                    />
                  </div>
                  <div className='form-group col-6'>
                    <label htmlFor='url'>Url (<b>https://</b> will be added automatically)</label>
                    <Field
                      name='url'
                      type='text'
                      className={
                        'form-control' +
                        (errors.url && touched.url ? ' is-invalid' : '')
                      }
                    />
                    <ErrorMessage
                      name='url'
                      component='div'
                      className='invalid-feedback'
                    />
                  </div>
                </div>
              )}
              <div className='float-right'>
                <button type='submit' className='btn btn-primary'>
                  Accept
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  render() {
    const { show, actionType, entityName } = this.props.addUpdateModal;
    const { hideAddUpdateModal } = this.props;

    return (
      <Modal
        className={className}
        isOpen={
          show &&
          entityName === constants.EXCHANGE &&
          actionType === constants.ADD
        }
        toggle={hideAddUpdateModal}>
        <ModalHeader
          className={`${bem.e('header')}`}
          toggle={hideAddUpdateModal}>
          {`${actionType} Exchange`}
        </ModalHeader>
        <ModalBody>{this.constructForm()}</ModalBody>
      </Modal>
    );
  }
}

export default ExchangeModal;
