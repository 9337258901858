import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ExchangeInfoModal from './components';
import Exchanges from '../../../redux/modules/Exchanges';
import UI from '../../../redux/modules/UI';

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      hideAddUpdateModal: UI.actions.hideAddUpdateModal,
      editAccessKey: Exchanges.actions.editAccessKey,
      showAlertModal: UI.actions.showAlertModal,
      editSettings: Exchanges.actions.editSettings,
      setUIField: Exchanges.actions.setField
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    exchange: Exchanges.selectors.exchange(state),
    addUpdateModal: UI.selectors.addUpdateModal(state),
    status: Exchanges.selectors.status(state)
  };
};

export default connect(mapStateToProps, mapActionsToProps)(ExchangeInfoModal);
