import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import promiseMiddleware from './promiseMiddleware';
import rootReducer from './rootReducer';
import { IS_DEVELOPMENT } from '../config';

export const history = createBrowserHistory();

const logger = createLogger({
  collapsed: true,
  logger: console
});

const store = createStore(
  rootReducer,
  IS_DEVELOPMENT
    ? applyMiddleware(
        promiseMiddleware,
        thunk,
        logger,
        routerMiddleware(history)
      )
    : applyMiddleware(promiseMiddleware, thunk)
);

export default store;
