import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

class Chart1 extends React.Component {
  constructor(props) {
    super();
  }

  componentDidMount() {
    am4core.addLicense('ch-custom-attribution');
    let chart = am4core.create('chartdiv1', am4charts.XYChart);

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    const prdxTotalMainCurrencySeries = chart.series.push(
      new am4charts.LineSeries()
    );
    prdxTotalMainCurrencySeries.dataFields.valueY = 'prdxTotalMainCurrency';
    prdxTotalMainCurrencySeries.dataFields.dateX = 'date';
    prdxTotalMainCurrencySeries.tooltipText = '{prdxTotalMainCurrency}';
    prdxTotalMainCurrencySeries.tooltip.pointerOrientation = 'vertical';
    prdxTotalMainCurrencySeries.name = 'PRDX-TotalUSDT';
    prdxTotalMainCurrencySeries.strokeWidth = 3;
    prdxTotalMainCurrencySeries.stroke = am4core.color('green');
    prdxTotalMainCurrencySeries.tooltip.getFillFromObject = false;
    prdxTotalMainCurrencySeries.tooltip.background.fill = am4core.color(
      'green'
    );

    const prdxTotalCryptoInMainCurrencySeries = chart.series.push(
      new am4charts.LineSeries()
    );
    prdxTotalCryptoInMainCurrencySeries.dataFields.valueY =
      'prdxTotalCryptoInMainCurrency';
    prdxTotalCryptoInMainCurrencySeries.dataFields.dateX = 'date';
    prdxTotalCryptoInMainCurrencySeries.tooltipText =
      '{prdxTotalCryptoInMainCurrency}';
    prdxTotalCryptoInMainCurrencySeries.tooltip.pointerOrientation = 'vertical';
    prdxTotalCryptoInMainCurrencySeries.name = 'PRDX-TotalCryptoInUSDT';
    prdxTotalCryptoInMainCurrencySeries.strokeWidth = 3;
    prdxTotalCryptoInMainCurrencySeries.stroke = am4core.color('red');
    prdxTotalCryptoInMainCurrencySeries.tooltip.getFillFromObject = false;
    prdxTotalCryptoInMainCurrencySeries.tooltip.background.fill = am4core.color(
      'red'
    );

    const binanceTotalMainCurrencySeries = chart.series.push(
      new am4charts.LineSeries()
    );
    binanceTotalMainCurrencySeries.dataFields.valueY =
      'binanceTotalMainCurrency';
    binanceTotalMainCurrencySeries.dataFields.dateX = 'date';
    binanceTotalMainCurrencySeries.tooltipText = '{binanceTotalMainCurrency}';
    binanceTotalMainCurrencySeries.tooltip.pointerOrientation = 'vertical';
    binanceTotalMainCurrencySeries.name = 'Binance-TotalUSDT';
    binanceTotalMainCurrencySeries.strokeWidth = 3;
    binanceTotalMainCurrencySeries.stroke = am4core.color('blue');
    binanceTotalMainCurrencySeries.tooltip.getFillFromObject = false;
    binanceTotalMainCurrencySeries.tooltip.background.fill = am4core.color(
      'blue'
    );

    const binanceTotalCryptoInMainCurrencySeries = chart.series.push(
      new am4charts.LineSeries()
    );
    binanceTotalCryptoInMainCurrencySeries.dataFields.valueY =
      'binanceTotalCryptoInMainCurrency';
    binanceTotalCryptoInMainCurrencySeries.dataFields.dateX = 'date';
    binanceTotalCryptoInMainCurrencySeries.tooltipText =
      '{binanceTotalCryptoInMainCurrency}';
    binanceTotalCryptoInMainCurrencySeries.tooltip.pointerOrientation =
      'vertical';
    binanceTotalCryptoInMainCurrencySeries.name = 'Binance-TotalCryptoInUSDT';
    binanceTotalCryptoInMainCurrencySeries.strokeWidth = 3;
    binanceTotalCryptoInMainCurrencySeries.stroke = am4core.color('orange');
    binanceTotalCryptoInMainCurrencySeries.tooltip.getFillFromObject = false;
    binanceTotalCryptoInMainCurrencySeries.tooltip.background.fill = am4core.color(
      'orange'
    );

    chart.cursor = new am4charts.XYCursor();
    chart.legend = new am4charts.Legend();

    this.chart = chart;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isDataUpdated !== this.props.isDataUpdated &&
      this.props.isDataUpdated
    ) {
      const data = this.props.data.map((item) => {
        const date = new Date(item.timestamp);
        const prdxTotalCryptoInMainCurrency =
          item.subReports[1].totalCryptoInMainCurrency;
        const prdxTotalMainCurrency = item.subReports[1].totalMainCurrecny;
        const binanceTotalMainCurrency = item.subReports[0].totalMainCurrecny;
        const binanceTotalCryptoInMainCurrency =
          item.subReports[0].totalCryptoInMainCurrency;

        return {
          date,
          prdxTotalCryptoInMainCurrency,
          prdxTotalMainCurrency,
          binanceTotalMainCurrency,
          binanceTotalCryptoInMainCurrency
        };
      });

      this.chart.data = data;
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div id='chartdiv1' style={{ width: '100%', height: '500px' }}></div>
    );
  }
}

export default Chart1;
