import NewPage from 'components/NewPage';
import React from 'react';
import { Col, Row } from 'reactstrap';
import Chart1 from './Chart1';
import Chart2 from './Chart2';
import DateFilter from '../../../../components/DateFilter';
import CustomTable from '../../../../components/CustomTable';
import ErrorComp from '../../../../components/ErrorComp';
import * as constants from '../../../../constants';
import moment from 'moment';

class PnlBalance extends React.Component {
  constructor(props) {
    super(props);

    this.table = React.createRef();

    this.state = {
      tablePnlBalances: [],
      tableColumnNames: this.getTableColumnNames()
    };

    this.props.setUIField('currentEntityName', constants.PNL_BALANCE);
  }

  getTableColumnNames = () => {
    return [
      {
        header: '№',
        accessor: 'id'
      },
      {
        header: 'DateTime',
        accessor: 'timestamp'
      },
      {
        header: 'PRDX-TotalCryptoInUSDT',
        accessor: 'prdxTotalCryptoInMainCurrency'
      },
      {
        header: 'PRDX-TotalUSDT',
        accessor: 'prdxTotalMainCurrency'
      },
      {
        header: 'PRDX-PNL',
        accessor: 'prdxPnlValue'
      },
      {
        header: 'Binance-TotalCryptoInUSDT',
        accessor: 'binanceTotalCryptoInMainCurrency'
      },
      {
        header: 'Binance-TotalUSDT',
        accessor: 'binanceTotalMainCurrency'
      },
      {
        header: 'Binance-PNL',
        accessor: 'binancePnlValue'
      },
      {
        header: 'TotalPNL',
        accessor: 'pnlValueTotal'
      }
    ];
  };

  getTablePnlBalances = () => {
    const { pnlBalances } = this.props;

    return pnlBalances.map((pnlBalance, index) => {
      return {
        id: index,
        timestamp: moment(pnlBalance.timestamp).format('YYYY-MM-DD HH:mm:ss'),
        prdxTotalCryptoInMainCurrency:
          pnlBalance.subReports[1].totalCryptoInMainCurrency,
        prdxTotalMainCurrency: pnlBalance.subReports[1].totalMainCurrecny,
        prdxPnlValue: pnlBalance.subReports[1].pnlValue,
        binanceTotalCryptoInMainCurrency:
          pnlBalance.subReports[0].totalCryptoInMainCurrency,
        binanceTotalMainCurrency: pnlBalance.subReports[0].totalMainCurrecny,
        binancePnlValue: pnlBalance.subReports[0].pnlValue,
        pnlValueTotal: pnlBalance.pnlValueTotal
      };
    });
  };

  componentDidMount() {
    this.props.loadData();
    this.props.addOpenedTab({
      to: '/hedging/pnl-balance',
      name: 'PNL balance'
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { openedTabs } = this.props;

    if (
      !openedTabs.find((openedTab) => openedTab.to === '/hedging/pnl-balance')
    ) {
      this.props.history.push(openedTabs[openedTabs.length - 1].to);
    } else {
      if (this.props.isDataUpdated) {
        this.setState({ tablePnlBalances: this.getTablePnlBalances() });
        this.props.setField('isDataUpdated', false);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetPage();
  }

  filter = () => {
    this.props.setField('pnlBalances', []);
    this.props.resetMeta();
    this.props.loadData();
  };

  render() {
    const {
      status: { error }
    } = this.props;

    const { tableColumnNames, tablePnlBalances } = this.state;

    return (
      <NewPage
        title=''
        breadcrumbs={[{ name: 'PNL Balance', active: true }]}
        disableAddButton>
        <Row>
          <Col md='12' sm='12' xs='12'>
            {error && <ErrorComp error={error}></ErrorComp>}
            <DateFilter
              filters={this.props.filters}
              setField={this.props.setField}
              filter={this.filter}
            />
            <Chart1
              data={this.props.pnlBalances}
              setField={this.props.setField}
              isDataUpdated={this.props.isDataUpdated}
            />
            <Chart2
              data={this.props.pnlBalances}
              setField={this.props.setField}
              isDataUpdated={this.props.isDataUpdated}
            />
            {this.props.pnlBalances.length > 0 && (
              <CustomTable
                columns={tableColumnNames}
                data={tablePnlBalances}
                withPagination
                meta={this.props.meta}
                setPage={this.props.setPage}
                setLimit={this.props.setLimit}
              />
            )}
          </Col>
        </Row>
      </NewPage>
    );
  }
}

export default PnlBalance;
