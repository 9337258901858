const module = 'EXCHANGES';

export const LOAD_INITIAL_DATA_PENDING = `${module}/LOAD_INITIAL_DATA_PENDING`;
export const LOAD_INITIAL_DATA_SUCCESS = `${module}/LOAD_INITIAL_DATA_SUCCESS`;
export const LOAD_INITIAL_DATA_ERROR = `${module}/LOAD_INITIAL_DATA_ERROR`;

export const EDIT_ACCESS_KEY_PENDING = `${module}/EDIT_ACCESS_KEY_PENDING`;
export const EDIT_ACCESS_KEY_SUCCESS = `${module}/EDIT_ACCESS_KEY_SUCCESS`;
export const EDIT_ACCESS_KEY_ERROR = `${module}/EDIT_ACCESS_KEY_ERROR`;

export const EDIT_SETTINGS_PENDING = `${module}/EDIT_SETTINGS_PENDING`;
export const EDIT_SETTINGS_SUCCESS = `${module}/EDIT_SETTINGS_SUCCESS`;
export const EDIT_SETTINGS_ERROR = `${module}/EDIT_SETTINGS_ERROR`;

export const DELETE_EXCHANGE_PENDING = `${module}/DELETE_EXCHANGE_PENDING`;
export const DELETE_EXCHANGE_SUCCESS = `${module}/DELETE_EXCHANGE_SUCCESS`;
export const DELETE_EXCHANGE_ERROR = `${module}/DELETE_EXCHANGE_ERROR`;

export const ADD_UPDATE_EXCHANGE_PENDING = `${module}/ADD_UPDATE_EXCHANGE_PENDING`;
export const ADD_UPDATE_EXCHANGE_SUCCESS = `${module}/ADD_UPDATE_EXCHANGE_SUCCESS`;
export const ADD_UPDATE_EXCHANGE_ERROR = `${module}/ADD_UPDATE_EXCHANGE_ERROR`;

export const RECONNECT_EXCHANGE_PENDING = `${module}/RECONNECT_EXCHANGE_PENDING`;
export const RECONNECT_EXCHANGE_SUCCESS = `${module}/RECONNECT_EXCHANGE_SUCCESS`;
export const RECONNECT_EXCHANGE_ERROR = `${module}/RECONNECT_EXCHANGE_ERROR`;

export const PUBLISH_ORDER_BOOK_PENDING = `${module}/PUBLISH_ORDER_BOOK_PENDING`;
export const PUBLISH_ORDER_BOOK_SUCCESS = `${module}/PUBLISH_ORDER_BOOK_SUCCESS`;
export const PUBLISH_ORDER_BOOK_ERROR = `${module}/PUBLISH_ORDER_BOOK_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;

export const SET_DATA_UPDATED = `${module}/SET_DATA_UPDATED`;
