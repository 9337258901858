import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import bn from '../../../../utils/bemnames';
import * as constants from '../../../../constants';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import FormikInputNumber from '../../../FormikInputNumber';
import * as Yup from 'yup';

const className = 'cr-modal';
const bem = bn.create('modal');

const initialValues = {
  name: '',
  baseCurrency: '',
  counterCurrency: '',
  priceScale: '0'
};

class SymbolModal extends React.Component {
  constructInitialValues = () => {
    const entity = this.props.symbol;

    if (entity)
      return {
        name: entity.name,
        baseCurrency: entity.settings.baseCurrency,
        counterCurrency: entity.settings.counterCurrency,
        priceScale: entity.settings.priceScale
      };
    else
      return {
        ...initialValues
      };
  };

  constructForm = () => {
    const { currencies } = this.props;
    const { actionType } = this.props.addUpdateModal;

    return (
      <Formik
        initialValues={this.constructInitialValues()}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Currency name is required'),
          baseCurrency: Yup.string()
            .required('Base currency is required')
            .notOneOf(
              [Yup.ref('counterCurrency')],
              "Base and counter currencies can't match"
            ),
          counterCurrency: Yup.string()
            .required('Counter currency is required')
            .notOneOf(
              [Yup.ref('baseCurrency')],
              "Base and counter currencies can't match"
            ),
          priceScale: Yup.number().required('Price scale is required')
        })}
        onSubmit={(fields) => {
          let entity = {
            name: fields.name,
            settings: {
              priceScale: fields.priceScale,
              baseCurrency: fields.baseCurrency,
              counterCurrency: fields.counterCurrency
            }
          };

          this.props.acceptAddUpdateModal(entity);
        }}>
        {({ errors, status, touched }) => (
          <Form>
            <div className='row'>
              <div className='form-group col-6'>
                <label htmlFor='name'>Symbol</label>
                <Field
                  name='name'
                  type='text'
                  className={
                    'form-control' +
                    (errors.name && touched.name ? ' is-invalid' : '')
                  }
                  disabled={actionType === constants.UPDATE}
                />
                <ErrorMessage
                  name='name'
                  component='div'
                  className='invalid-feedback'
                />
              </div>
              <div className='form-group col-6'>
                <label htmlFor='priceScale'>Price Scale</label>
                <FormikInputNumber name='priceScale' />
              </div>
            </div>
            <div className='row'>
              <div className='form-group col-6'>
                <label htmlFor='amountScale'>Base Currency</label>
                <Field
                  name='baseCurrency'
                  as='select'
                  className={
                    'form-control' +
                    (errors.baseCurrency && touched.baseCurrency
                      ? ' is-invalid'
                      : '')
                  }
                  disabled={actionType === constants.UPDATE}>
                  <option value=''></option>
                  {currencies.map((currency, index) => (
                    <option key={index} value={currency.name}>
                      {currency.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name='baseCurrency'
                  component='div'
                  className='invalid-feedback'
                />
              </div>
              <div className='form-group col-6'>
                <label htmlFor='counterCurrency'>Counter Currency</label>
                <Field
                  name='counterCurrency'
                  as='select'
                  className={
                    'form-control' +
                    (errors.counterCurrency && touched.counterCurrency
                      ? ' is-invalid'
                      : '')
                  }
                  disabled={actionType === constants.UPDATE}>
                  <option value=''></option>
                  {currencies.map((currency, index) => (
                    <option key={index} value={currency.name}>
                      {currency.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name='counterCurrency'
                  component='div'
                  className='invalid-feedback'
                />
              </div>
            </div>
            <div className='float-right'>
              <button type='submit' className='btn btn-primary'>
                Accept
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  render() {
    const { show, actionType, entityName } = this.props.addUpdateModal;
    const { hideAddUpdateModal } = this.props;

    return (
      <Modal
        className={className}
        isOpen={show && entityName === constants.SYMBOL}
        toggle={hideAddUpdateModal}>
        <ModalHeader
          className={`${bem.e('header')}`}
          toggle={hideAddUpdateModal}>
          {`${actionType} Symbol`}
        </ModalHeader>
        <ModalBody>{this.constructForm()}</ModalBody>
      </Modal>
    );
  }
}

export default SymbolModal;
