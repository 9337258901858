import * as ac from './actionCreators';
import * as constants from '../../../constants';
import * as api from '../../../api';
import { getErrorMessage } from '../../../utils/error';

export function loadCurrencies() {
  return async (dispatch) => {
    dispatch(ac.loadCurrencies.pending());

    try {
      const currencies = await api.getCurrencies();
      currencies.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.loadCurrencies.success(currencies));
    } catch (err) {
      dispatch(ac.loadCurrencies.error(getErrorMessage(err)));
    }
  };
}

export function addUpdateCurrency(currency) {
  return async (dispatch, getState) => {
    dispatch(ac.addUpdateCurrency.pending());

    try {
      const { actionType } = getState().UI.addUpdateModal;

      if (actionType === constants.UPDATE) {
        await api.updateCurrency(currency.name, {
          settings: { ...currency.settings }
        });
      } else {
        await api.addCurrency(currency);
      }

      const currencies = await api.getCurrencies();
      currencies.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.addUpdateCurrency.success(currencies));
    } catch (err) {
      dispatch(ac.addUpdateCurrency.error(getErrorMessage(err)));
    }
  };
}

export function setField(path, value) {
  return (dispatch) => dispatch(ac.setField({ path, value }));
}

export function setDataUpdated(isDataUpdated) {
  return (dispatch) => dispatch(ac.setDataUpdated(isDataUpdated));
}
