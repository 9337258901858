import * as ac from './actionCreators';
import * as api from '../../../api';
import { getErrorMessage } from '../../../utils/error';

export function loadEvents(eventType) {
  return async (dispatch) => {
    dispatch(ac.loadEvents.pending());

    try {
      const events = await api.getEvents(eventType);
      dispatch(ac.loadEvents.success(events));
    } catch (err) {
      dispatch(ac.loadEvents.error(getErrorMessage(err)));
    }
  };
}

export function setField(path, value) {
  return (dispatch) => dispatch(ac.setField({ path, value }));
}

export function setDataUpdated(isDataUpdated) {
  return (dispatch) => dispatch(ac.setDataUpdated(isDataUpdated));
}
