import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HedgingExchangeKeys from './components';
import HedgingExchangeKeysState from '../../../redux/modules/HedgingExchangeKeys';
import UI from '../../../redux/modules/UI';

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      loadExchangeKeys: HedgingExchangeKeysState.actions.loadExchangeKeys,
      loadExchangeKey: HedgingExchangeKeysState.actions.loadExchangeKey,
      setDataUpdated: HedgingExchangeKeysState.actions.setDataUpdated,
      showAlertModal: UI.actions.showAlertModal,
      showAddUpdateModal: UI.actions.showAddUpdateModal,
      setUIField: UI.actions.setField,
      addOpenedTab: UI.actions.addOpenedTab
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  status: HedgingExchangeKeysState.selectors.status(state),
  exchangeKeys: HedgingExchangeKeysState.selectors.exchangeKeys(state),
  isDataUpdated: HedgingExchangeKeysState.selectors.isDataUpdated(state),
  openedTabs: UI.selectors.openedTabs(state)
});

export default connect(mapStateToProps, mapActionsToProps)(HedgingExchangeKeys);
