import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import bn from '../../../../utils/bemnames';
import * as constants from '../../../../constants';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import FormikInputNumber from '../../../FormikInputNumber';
import * as Yup from 'yup';

const className = 'cr-modal';
const bem = bn.create('modal');

const initialValues = {
  name: '',
  amountScale: '0'
};

class CurrencyModal extends React.Component {
  constructInitialValues = () => {
    const entity = this.props.currency;

    if (entity)
      return {
        name: entity.name,
        amountScale: entity.settings.amountScale
      };
    else
      return {
        ...initialValues
      };
  };

  constructForm = () => {
    const { actionType } = this.props.addUpdateModal;

    return (
      <Formik
        initialValues={this.constructInitialValues()}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Currency name is required'),
          amountScale: Yup.number().required('Min scale is required')
        })}
        onSubmit={(fields) => {
          let entity = {
            name: fields.name,
            settings: {
              amountScale: fields.amountScale
            }
          };

          this.props.acceptAddUpdateModal(entity);
        }}>
        {({ errors, status, touched }) => (
          <Form>
            <div className='row'>
              <div className='form-group col-6'>
                <label htmlFor='name'>Currency</label>
                <Field
                  name='name'
                  type='text'
                  className={
                    'form-control' +
                    (errors.name && touched.name ? ' is-invalid' : '')
                  }
                  disabled={actionType === constants.UPDATE}
                />
                <ErrorMessage
                  name='name'
                  component='div'
                  className='invalid-feedback'
                />
              </div>
              <div className='form-group col-6'>
                <label htmlFor='amountScale'>Min scale</label> c
                <FormikInputNumber name='amountScale' />
              </div>
            </div>
            <div className='float-right'>
              <button type='submit' className='btn btn-primary'>
                Accept
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  render() {
    const { show, actionType, entityName } = this.props.addUpdateModal;
    const { hideAddUpdateModal } = this.props;

    return (
      <Modal
        className={className}
        isOpen={show && entityName === constants.CURRENCY}
        toggle={hideAddUpdateModal}>
        <ModalHeader
          className={`${bem.e('header')}`}
          toggle={hideAddUpdateModal}>
          {`${actionType} Currency`}
        </ModalHeader>
        <ModalBody>{this.constructForm()}</ModalBody>
      </Modal>
    );
  }
}

export default CurrencyModal;
