import * as ac from './actionCreators';
import * as api from '../../../api';
import { getErrorMessage } from '../../../utils/error';

export function loadInitialData() {
  return async (dispatch) => {
    dispatch(ac.loadInitialData.pending());

    try { 
      const marketMakers = await api.getMarketMakers();
      marketMakers.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.loadInitialData.success({marketMakers}));
    } catch (err) {
      dispatch(ac.loadInitialData.error(getErrorMessage(err)));
    }
  };
}

export function loadData(marketMaker) {
  return async (dispatch) => {
    dispatch(ac.loadData.pending());

    try { 
      const marketMakerOrderBooks = await api.getMarketMakerOrderBooks(marketMaker);

      dispatch(ac.loadData.success(marketMakerOrderBooks));
    } catch (err) {
      dispatch(ac.loadData.error(getErrorMessage(err)));
    }
  };
}

export function resetPage() {
  return (dispatch) => dispatch(ac.resetPage());
}

export function setField(path, value) {
  return (dispatch) => dispatch(ac.setField({ path, value }));
}

export function setDataUpdated(isDataUpdated) {
  return (dispatch) => dispatch(ac.setDataUpdated(isDataUpdated));
}
