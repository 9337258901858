import * as c from './constants';

const initialState = {
  status: {
    pending: false,
    error: false,
    success: false
  },
  marketMakers: [],
  exchanges: [],
  symbols: [],
  bests: {}
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.LOAD_INITIAL_DATA_PENDING: {
      return {
        ...state,
        status: { pending: true, error: false, success: false }
      };
    }

    case c.LOAD_INITIAL_DATA_SUCCESS: {
      return {
        ...state,
        status: { pending: false, error: false, success: true },
        ...action.payload
      };
    }

    case c.LOAD_INITIAL_DATA_ERROR: {
      return {
        ...state,
        status: { pending: false, error: action.payload, success: false }
      };
    }

    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.path]: action.payload.value
      };
    }

    default: {
      return state;
    }
  }
};
