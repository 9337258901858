import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PnlTrade from './components';
import PnlTradeState from '../../../redux/modules/PnlTrade';
import UIState from '../../../redux/modules/UI';

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      loadData: PnlTradeState.actions.loadData,
      setField: PnlTradeState.actions.setField,
      resetMeta: PnlTradeState.actions.resetMeta,
      setPage: PnlTradeState.actions.setPage,
      setLimit: PnlTradeState.actions.setLimit,
      resetPage: PnlTradeState.actions.resetPage,
      setUIField: UIState.actions.setField,
      addOpenedTab: UIState.actions.addOpenedTab
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  status: PnlTradeState.selectors.status(state),
  pnlTotal: PnlTradeState.selectors.pnlTotal(state),
  pnlTradesTable: PnlTradeState.selectors.pnlTradesTable(state),
  pnlTrades: PnlTradeState.selectors.pnlTrades(state),
  filters: PnlTradeState.selectors.filters(state),
  meta: PnlTradeState.selectors.meta(state),
  isDataUpdated: PnlTradeState.selectors.isDataUpdated(state),
  openedTabs: UIState.selectors.openedTabs(state)
});

export default connect(mapStateToProps, mapActionsToProps)(PnlTrade);
