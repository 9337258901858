import * as ac from './actionCreators';
import * as api from '../../../api';
import { getErrorMessage } from '../../../utils/error';


export function loadExchangeKeys() {
  return async (dispatch) => {
    dispatch(ac.loadExchangeKeys.pending());

    try {
      const exchangeKeys = await api.getHedgingExchangeKeys();

      dispatch(ac.loadExchangeKeys.success({ exchangeKeys: exchangeKeys ? exchangeKeys : [] }));
    } catch (err) {
      dispatch(ac.loadExchangeKeys.error(getErrorMessage(err)));
    }
  };
}

export function loadExchangeKey(exchangeKey) {
  return async (dispatch) => {
      dispatch(ac.loadExchangeKey.success(exchangeKey));
  };
}

export function deleteExchangeKey(exchange) {
  return async (dispatch) => {
    dispatch(ac.deleteExchangeKey.pending());

    try {
      await api.deleteHedgingExchangeKey(exchange);
      const exchangeKeys = await api.getHedgingExchangeKeys();

      dispatch(ac.deleteExchangeKey.success( exchangeKeys ? exchangeKeys : [] ));
    } catch (err) {
      dispatch(ac.deleteExchangeKey.error(getErrorMessage(err)));
    }
  };
}

export function addUpdateExchangeKey(exchangeKey) {
  return async (dispatch, getState) => {
    dispatch(ac.addUpdateExchangeKey.pending());

    try {
      await api.addUpdateHedgingExchangeKey(exchangeKey);
    } catch (err) {
      dispatch(ac.addUpdateExchangeKey.error(getErrorMessage(err)));
    }
    finally{
      const exchangeKeys = await api.getHedgingExchangeKeys();

      dispatch(ac.addUpdateExchangeKey.success(exchangeKeys ? exchangeKeys : [] ));
    }
  };
}


export function setField(path, value) {
  return (dispatch) => dispatch(ac.setField({ path, value }));
}

export function setDataUpdated(isDataUpdated) {
  return (dispatch) => dispatch(ac.setDataUpdated(isDataUpdated));
}
