import NewPage from 'components/NewPage';
import React from 'react';
import { Col, Row } from 'reactstrap';
import CustomTable from '../../../components/CustomTable';
import ErrorComp from '../../../components/ErrorComp';
import * as constants from '../../../constants';
import Loader from '../../../components/Loader';
import { orderBy } from 'lodash';
import moment from 'moment';

class Events extends React.Component {
  constructor(props) {
    super(props);

    this.table = React.createRef();

    this.state = {
      tableEvents: [],
      tableColumnNames: []
    };

    this.props.setUIField('currentEntityName', constants.EVENT);
  }

  componentDidMount() {
    this.props.loadEvents(this.props.eventType);
    this.props.addOpenedTab({
      to: '/events',
      name: 'events'
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { openedTabs } = this.props;

    if (
      !openedTabs.find(
        (openedTab) =>
          openedTab.to === '/events'
      )
    ) {
      this.props.history.push(openedTabs[openedTabs.length - 1].to);
    } else {
      if (this.props.isDataUpdated) {
        this.setState({
          tableColumnNames: this.getTableColumnNames(),
          tableEvents: this.getTableEvents()
        });
        this.props.setDataUpdated(false);
      }
      if (
        prevProps.eventType !== this.props.eventType
      ) {
        this.props.loadEvents(this.props.eventType);
      }
    }
  }

  getTableColumnNames = () => {

    return [
      {
        header: 'Date',
        accessor: 'date'
      },
      {
        header: 'Message',
        accessor: 'message',
        inputType: 'textarea'
      }
    ];
  };

  getTableEvents = () => {
    const { events } = this.props;

    let data = [];

    Object.keys(events).forEach((item) => {
      data.push({
        dateUnix: item,
        date: moment.unix(item).format('YYYY-MM-DD HH:mm:ss'),
        message: events[item]
      })
    });

    data = orderBy(data, ['dateUnix'], ['desc']);

    return data;
  };

  render() {
    const {
      status: { error, pending }
    } = this.props;

    const { tableColumnNames, tableEvents } = this.state;

    return (
      <NewPage
        title=''
        breadcrumbs={[{ name: 'Events', active: true }]}
        withEventTypeFilter
        disableAddButton
        dataLoading = {pending}
        >
        <Row>
          <Col md='12' sm='12' xs='12'>
            {error && <ErrorComp error={error}></ErrorComp>}
            <div style={{width: '100%','min-height': '300px'}}>
              <Loader isLoading={pending}>
                <CustomTable
                  className='event-table'
                  columns={tableColumnNames}
                  data={tableEvents}
                />
              </Loader>
            </div>
          </Col>
        </Row>
      </NewPage>
    );
  }
}

export default Events;
