import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HedgingExchangeKeyModal from './components';
import HedgingExchangeKeysState from '../../../redux/modules/HedgingExchangeKeys';
import UI from '../../../redux/modules/UI';

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      setHedgingExchangeKeyField: HedgingExchangeKeysState.actions.setField,
      hideAddUpdateModal: UI.actions.hideAddUpdateModal,
      acceptAddUpdateModal: UI.actions.acceptAddUpdateModal
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    exchangeKey: HedgingExchangeKeysState.selectors.exchangeKey(state),
    addUpdateModal: UI.selectors.addUpdateModal(state)
  };
};

export default connect(mapStateToProps, mapActionsToProps)(HedgingExchangeKeyModal);
