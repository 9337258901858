import * as ac from './actionCreators';
import * as constants from '../../../constants';
import * as api from '../../../api';
import { getErrorMessage } from '../../../utils/error';

export function loadInitialData() {
  return async (dispatch) => {
    dispatch(ac.loadInitialData.pending());

    try {
      //TODO: to make promise all
      const exchanges = await api.getExchanges();
      exchanges.sort((a, b) => (a.name > b.name ? 1 : -1));

      const exchangeClients = await api.getExchangeClients();
      exchangeClients.sort();

      const symbols = await api.getSymbols();
      symbols.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(
        ac.loadInitialData.success({ exchanges, exchangeClients, symbols })
      );
    } catch (err) {
      dispatch(ac.loadInitialData.error(getErrorMessage(err)));
    }
  };
}

export function loadExchange(name) {
  return async (dispatch) => {
    const exchange = await api.getExchange(name);
    exchange.mapping.sort((a, b) =>
      a.internalSymbol > b.internalSymbol ? 1 : -1
    );
    exchange.accessKeys.sort((a, b) => (a.key > b.key ? 1 : -1));

    dispatch(ac.setField({ path: 'exchange', value: exchange }));
  };
}

export function editAccessKey(name, key, value) {
  return async (dispatch) => {
    try {
      dispatch(ac.editAccessKey.pending());
      await api.editExchangeAccessKey(name, {
        key,
        value
      });
      dispatch(ac.editAccessKey.success());
    } catch (err) {
      dispatch(ac.editAccessKey.error(getErrorMessage(err)));
    }
  };
}

export function editSettings(name, data) {
  return async (dispatch) => {
    try {
      dispatch(ac.editSettings.pending());
      await api.editExchangeSettings(name, data);
      dispatch(ac.editSettings.success());
    } catch (err) {
      dispatch(ac.editSettings.error(getErrorMessage(err)));
    }
  };
}

export function addUpdateExchange(exchange) {
  return async (dispatch, getState) => {
    dispatch(ac.addUpdateExchange.pending());

    try {
      const { actionType } = getState().UI.addUpdateModal;

      if (actionType === constants.UPDATE) {
        await api.updateExchange(exchange.name, {
          settings: { ...exchange.settings }
        });
      } else {
        await api.addExchange(exchange);
      }

      const exchanges = await api.getExchanges();
      exchanges.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.addUpdateExchange.success(exchanges));
    } catch (err) {
      dispatch(ac.addUpdateExchange.error(getErrorMessage(err)));
    }
  };
}

export function reconnectExchange(exchange) {
  return async (dispatch, getState) => {
    dispatch(ac.reconnectExchange.pending());

    try {
      await api.reconnectExchange(exchange);

      dispatch(ac.reconnectExchange.success());
    } catch (err) {
      dispatch(ac.reconnectExchange.error(getErrorMessage(err)));
    }
  };
}

export function setField(path, value) {
  return (dispatch) => dispatch(ac.setField({ path, value }));
}

export function setDataUpdated(isDataUpdated) {
  return (dispatch) => dispatch(ac.setDataUpdated(isDataUpdated));
}

export function publishOrderBook(name, symbol) {
  return async (dispatch, getState) => {
    dispatch(ac.publishOrderBook.pending());

    try {
      await api.publishExchangeOrderBook(name, symbol);

      dispatch(ac.publishOrderBook.success());
    } catch (err) {
      dispatch(ac.publishOrderBook.error(getErrorMessage(err)));
    }
  };
}
