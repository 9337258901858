import AuthForm from 'components/AuthForm';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';

class AuthPage extends React.Component {
  render() {
    return (
      <Row
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'start'
        }}>
        <Col md={4} lg={3}>
          <Card body>
            <AuthForm {...this.props} />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default AuthPage;
