import * as c from './constants';
import { asyncActionCreator, actionCreator } from '../../../utils/redux';

export const loadInitialData = asyncActionCreator(
  c.LOAD_INITIAL_DATA_PENDING,
  c.LOAD_INITIAL_DATA_SUCCESS,
  c.LOAD_INITIAL_DATA_ERROR
);

export const loadData = asyncActionCreator(
  c.LOAD_DATA_PENDING,
  c.LOAD_DATA_SUCCESS,
  c.LOAD_DATA_ERROR
);

export const resetPage = actionCreator(c.RESET_PAGE);

export const setField = actionCreator(c.SET_FIELD);

export const setDataUpdated = actionCreator(c.SET_DATA_UPDATED);
