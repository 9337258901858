import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class CustomPagination extends React.Component {
  getNumberItem(page) {
    const { meta, setPage } = this.props;

    if (meta.page === page)
      return (
        <PaginationItem active>
          <PaginationLink>{page}</PaginationLink>
        </PaginationItem>
      );
    else
      return (
        <PaginationItem onClick={() => setPage(page)}>
          <PaginationLink>{page}</PaginationLink>
        </PaginationItem>
      );
  }

  getDotItem() {
    return (
      <PaginationItem>
        <PaginationLink>...</PaginationLink>
      </PaginationItem>
    );
  }

  render() {
    const paginationItems = [];
    const { meta, setPage, setLimit } = this.props;

    if (meta.count > 10) {
      if (meta.page <= 5) {
        for (let i = 1; i <= 5; i++) {
          paginationItems.push(this.getNumberItem(i));
        }
        paginationItems.push(this.getDotItem());
      } else if (meta.page > meta.count - 5) {
        paginationItems.push(this.getDotItem());

        for (let i = meta.count - 4; i <= meta.count; i++) {
          paginationItems.push(this.getNumberItem(i));
        }
      } else {
        paginationItems.push(this.getDotItem());

        for (let i = meta.page - 2; i <= meta.page + 2; i++) {
          paginationItems.push(this.getNumberItem(i));
        }

        paginationItems.push(this.getDotItem());
      }
    } else {
      for (let i = 1; i <= meta.count; i++) {
        paginationItems.push(this.getNumberItem(i));
      }
    }

    return (
      <div className='d-flex'>
        <Pagination aria-label='Page navigation example'>
          <PaginationItem onClick={() => setPage(1)}>
            <PaginationLink>Begin</PaginationLink>
          </PaginationItem>
          <PaginationItem
            onClick={() => meta.page > 1 && setPage(meta.page - 1)}>
            <PaginationLink previous />
          </PaginationItem>
          {paginationItems}
          <PaginationItem
            onClick={() => meta.page < meta.count && setPage(meta.page + 1)}>
            <PaginationLink next />
          </PaginationItem>
          <PaginationItem onClick={() => setPage(meta.count)}>
            <PaginationLink>End</PaginationLink>
          </PaginationItem>
        </Pagination>
        <div className='ml-auto'>
          <select
            className='form-control'
            value={meta.limit}
            onChange={(e) => {
              setLimit(e.target.value);
            }}>
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
          </select>
        </div>
      </div>
    );
  }
}

export default CustomPagination;
