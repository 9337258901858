import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Events from './components';
import EventsState from '../../redux/modules/Events';
import UI from '../../redux/modules/UI';

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      loadEvents: EventsState.actions.loadEvents,
      setDataUpdated: EventsState.actions.setDataUpdated,
      setUIField: UI.actions.setField,
      addOpenedTab: UI.actions.addOpenedTab
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  status: EventsState.selectors.status(state),
  events: EventsState.selectors.events(state),
  isDataUpdated: EventsState.selectors.isDataUpdated(state),
  eventType: EventsState.selectors.eventType(
    state
  ),
  openedTabs: UI.selectors.openedTabs(state)
});

export default connect(mapStateToProps, mapActionsToProps)(Events);
