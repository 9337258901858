import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UI from '../redux/modules/UI';
import App from './components/App';

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      setUIField: UI.actions.setField
    },
    dispatch
  );

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapActionsToProps)(App);
