import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink as BSNavLink } from 'reactstrap';
import bn from 'utils/bemnames';

const navMarkets = [
  {
    to: '/markets/currencies',
    name: 'currencies',
    exact: false
  },
  {
    to: '/markets/symbols',
    name: 'symbols',
    exact: false
  },
  {
    to: '/markets/exchanges',
    name: 'exchanges',
    exact: false
  }
];

const navMonitoring = [
  {
    to: '/monitoring/market-maker-live-prices',
    name: 'market maker live prices',
    exact: false
  },
  {
    to: '/monitoring/exchange-live-prices',
    name: 'exchange live prices',
    exact: false
  }
];

// const navTrades = [
//   {
//     to: '/trades/source-orders',
//     name: 'source orders',
//     exact: false
//   },
//   {
//     to: '/trades/source-trades',
//     name: 'source trades',
//     exact: false
//   },
//   {
//     to: '/trades/target-orders',
//     name: 'target orders',
//     exact: false
//   },
//   {
//     to: '/trades/target-trades',
//     name: 'target trades',
//     exact: false
//   }
// ];

const navMarketMaker = [
  {
    to: '/market-maker/configuration',
    name: 'configuration',
    exact: false
  },
  {
    to: '/market-maker/feed-generator/configuration',
    name: 'feed generator',
    exact: false
  },
  {
    to: '/market-maker/order-books',
    name: 'order books',
    exact: false
  }
];
const navActivityGenerator = [
  {
    to: '/activity-generator/trading-activity',
    name: 'trading activity',
    exact: false
  },
  {
    to: '/activity-generator/simulation',
    name: 'simulation',
    exact: false
  }
];

const navHedging = [
  {
    to: '/hedging/configuration',
    name: 'Configuration',
    exact: false
  },
  {
    to: '/hedging/pnl-balance',
    name: 'PNL balance',
    exact: false
  },
  {
    to: '/hedging/pnl-trade',
    name: 'PNL trade',
    exact: false
  },
  {
    to: '/hedging/exchange-keys',
    name: 'Exchange Keys',
    exact: false
  }
];

const bem = bn.create('sidebar');

const initialState = {
  isOpenMarkets: false,
  isOpenMonitoring: false,
  isOpenTrades: false,
  isOpenMarketMaker: false
};

class Sidebar2 extends React.Component {
  state = { ...initialState };

  handleClick = (name) => () => {
    this.setState((prevState) => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        ...initialState,
        [`isOpen${name}`]: !isOpen
      };
    });
  };

  render() {
    let className = `${bem.b()}`;
    if (this.props.isSidebarOpen) className = `${className} ${bem.b()}--open`;

    return (
      <>
        <aside className={className} data-image={sidebarBgImage}>
          <div className={bem.e('background')} />
          <div className={bem.e('content')}>
            <Nav vertical>
              <NavItem
                className={bem.e('nav-item')}
                onClick={this.handleClick('Markets')}>
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className='d-flex'>
                    <i className='fa fa-fw fa-lg fas fa-cog mt-1'></i>
                    <span className=' align-self-start ml-1'>Markets</span>
                  </div>
                  {!this.state.isOpenMarkets ? (
                    <i className='fas fa-chevron-left'></i>
                  ) : (
                    <i className='fas fa-chevron-down'></i>
                  )}
                </BSNavLink>
              </NavItem>
              <Collapse isOpen={this.state.isOpenMarkets}>
                {navMarkets.map(({ to, name, exact }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className='first-letter-uppercase sub-menu-item'
                      tag={NavLink}
                      to={to}
                      activeClassName='active'
                      exact={exact}>
                      <span>{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>

              <NavItem
                className={bem.e('nav-item')}
                onClick={this.handleClick('Monitoring')}>
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className='d-flex'>
                    <i className='fa fa-fw fa-lg fas fa-chart-bar mt-1'></i>
                    <span className=' align-self-start ml-1'>Monitoring</span>
                  </div>
                  {!this.state.isOpenMonitoring ? (
                    <i className='fas fa-chevron-left'></i>
                  ) : (
                    <i className='fas fa-chevron-down'></i>
                  )}
                </BSNavLink>
              </NavItem>
              <Collapse isOpen={this.state.isOpenMonitoring}>
                {navMonitoring.map(({ to, name, exact }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className='first-letter-uppercase sub-menu-item'
                      tag={NavLink}
                      to={to}
                      activeClassName='active'
                      exact={exact}>
                      <span>{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>

              <NavItem
                className={bem.e('nav-item')}
                onClick={this.handleClick('MarketMaker')}>
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className='d-flex'>
                    <i className='fa fa-fw fa-lg fas fa-chart-line mt-1'></i>
                    <span className=' align-self-start ml-1'>Market Maker</span>
                  </div>
                  {!this.state.isOpenMarketMaker ? (
                    <i className='fas fa-chevron-left'></i>
                  ) : (
                    <i className='fas fa-chevron-down'></i>
                  )}
                </BSNavLink>
              </NavItem>
              <Collapse isOpen={this.state.isOpenMarketMaker}>
                {navMarketMaker.map(({ to, name, exact }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className='first-letter-uppercase sub-menu-item'
                      tag={NavLink}
                      to={to}
                      activeClassName='active'
                      exact={exact}>
                      <span>{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>
              <NavItem
                className={bem.e('nav-item')}
                onClick={this.handleClick('ActivityGenerator')}>
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className='d-flex'>
                    <i className='fa fa-fw fa-lg fas fa-tachometer-alt mt-1'></i>
                    <span className='align-self-start ml-1'>
                      Activity Generator
                    </span>
                  </div>
                  {!this.state.isOpenActivityGenerator ? (
                    <i className='fas fa-chevron-left'></i>
                  ) : (
                    <i className='fas fa-chevron-down'></i>
                  )}
                </BSNavLink>
              </NavItem>
              <Collapse isOpen={this.state.isOpenActivityGenerator}>
                {navActivityGenerator.map(({ to, name, exact }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className='first-letter-uppercase sub-menu-item'
                      tag={NavLink}
                      to={to}
                      activeClassName='active'
                      exact={exact}>
                      <span>{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>

              <NavItem
                className={bem.e('nav-item')}
                onClick={this.handleClick('Hedging')}>
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className='d-flex'>
                    <i className='fa fa-fw fa-lg fas fa-circle-notch mt-1'></i>
                    <span className='align-self-start ml-1'>Hedging</span>
                  </div>
                  {!this.state.isOpenHedging ? (
                    <i className='fas fa-chevron-left'></i>
                  ) : (
                    <i className='fas fa-chevron-down'></i>
                  )}
                </BSNavLink>
              </NavItem>
              <Collapse isOpen={this.state.isOpenHedging}>
                {navHedging.map(({ to, name, exact }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className='first-letter-uppercase sub-menu-item'
                      tag={NavLink}
                      to={to}
                      activeClassName='active'
                      exact={exact}>
                      <span>{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>
              <NavItem key={'events'} className={bem.e('nav-item')}>
                <BSNavLink
                  className={bem.e('nav-item-collapse')}
                  tag={NavLink}
                  to={'/events'}
                  activeClassName='active'
                  exact={false}>
                    <div className='d-flex'>
                      <i className='fa fa-fw fa-lg fas fa-calendar-week mt-1'></i>
                      <span className='align-self-start ml-1'>Events</span>
                    </div>
                </BSNavLink>
              </NavItem>
            </Nav>
          </div>
        </aside>
      </>
    );
  }
}

export default Sidebar2;
