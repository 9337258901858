import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SymbolModal from './components';
import Symbols from '../../../redux/modules/Symbols';
import UI from '../../../redux/modules/UI';

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      hideAddUpdateModal: UI.actions.hideAddUpdateModal,
      acceptAddUpdateModal: UI.actions.acceptAddUpdateModal
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    addUpdateModal: UI.selectors.addUpdateModal(state),
    currencies: Symbols.selectors.currencies(state),
    symbol: Symbols.selectors.symbol(state)
  };
};

export default connect(mapStateToProps, mapActionsToProps)(SymbolModal);
