import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

class Chart2 extends React.Component {
  constructor(props) {
    super();
  }

  componentDidMount() {
    am4core.addLicense('ch-custom-attribution');
    let chart = am4core.create('chartdiv2', am4charts.XYChart);

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    const prdxPnlValueSeries = chart.series.push(new am4charts.LineSeries());
    prdxPnlValueSeries.dataFields.valueY = 'prdxPnlValue';
    prdxPnlValueSeries.dataFields.dateX = 'date';
    prdxPnlValueSeries.tooltipText = '{prdxPnlValue}';
    prdxPnlValueSeries.tooltip.pointerOrientation = 'vertical';
    prdxPnlValueSeries.name = 'PRDX-PNL';
    prdxPnlValueSeries.strokeWidth = 3;
    prdxPnlValueSeries.stroke = am4core.color('purple');
    prdxPnlValueSeries.tooltip.getFillFromObject = false;
    prdxPnlValueSeries.tooltip.background.fill = am4core.color('purple');

    const binancePnlValueSeries = chart.series.push(new am4charts.LineSeries());
    binancePnlValueSeries.dataFields.valueY = 'binancePnlValue';
    binancePnlValueSeries.dataFields.dateX = 'date';
    binancePnlValueSeries.tooltipText = '{binancePnlValue}';
    binancePnlValueSeries.tooltip.pointerOrientation = 'vertical';
    binancePnlValueSeries.name = 'Binance-PNL';
    binancePnlValueSeries.strokeWidth = 3;
    binancePnlValueSeries.stroke = am4core.color('pink');
    binancePnlValueSeries.tooltip.getFillFromObject = false;
    binancePnlValueSeries.tooltip.background.fill = am4core.color('pink');

    const totalPnlValueSeries = chart.series.push(new am4charts.LineSeries());
    totalPnlValueSeries.dataFields.valueY = 'pnlValueTotal';
    totalPnlValueSeries.dataFields.dateX = 'date';
    totalPnlValueSeries.tooltipText = '{pnlValueTotal}';
    totalPnlValueSeries.tooltip.pointerOrientation = 'vertical';
    totalPnlValueSeries.name = 'Total-PNL';
    totalPnlValueSeries.strokeWidth = 3;
    totalPnlValueSeries.stroke = am4core.color('gold');
    totalPnlValueSeries.tooltip.getFillFromObject = false;
    totalPnlValueSeries.tooltip.background.fill = am4core.color('gold');

    chart.cursor = new am4charts.XYCursor();
    chart.legend = new am4charts.Legend();
    // chart.cursor.snapToSeries = series;
    // chart.cursor.xAxis = dateAxis;

    this.chart = chart;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isDataUpdated !== this.props.isDataUpdated &&
      this.props.isDataUpdated
    ) {
      const data = this.props.data.map((item) => {
        const date = new Date(item.timestamp);
        const prdxPnlValue = item.subReports[1].pnlValue;
        const binancePnlValue = item.subReports[0].pnlValue;
        const pnlValueTotal = item.pnlValueTotal;

        return { date, prdxPnlValue, binancePnlValue, pnlValueTotal };
      });

      this.chart.data = data;
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div id='chartdiv2' style={{ width: '100%', height: '500px' }}></div>
    );
  }
}

export default Chart2;
