import * as ac from './actionCreators';
import * as api from '../../../api';
import { getErrorMessage } from '../../../utils/error';

export function loadData() {
  return async (dispatch, getState) => {
    dispatch(ac.loadData.pending());

    try {
      const data = await api.getPnlTrades(
        getState().PnlTrade.filters.startDateTime,
        getState().PnlTrade.filters.endDateTime,
        getState().PnlTrade.filters.customCommissionPercent
      );

      dispatch(ac.loadData.success({ pnlTrades: data.hedges }));
    } catch (err) {
      dispatch(ac.loadData.error(getErrorMessage(err)));
    }
  };
}

export function resetMeta() {
  return (dispatch) => dispatch(ac.resetMeta());
}

export function setPage(page) {
  return (dispatch) => {
    return dispatch(ac.setPage(page));
  };
}

export function setLimit(limit) {
  return (dispatch) => {
    return dispatch(ac.setLimit(limit));
  };
}

export function setField(path, value) {
  return (dispatch) => {
    return dispatch(ac.setField({ path, value }));
  };
}

export function resetPage() {
  return (dispatch) => {
    return dispatch(ac.resetPage());
  };
}
