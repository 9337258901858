const module = 'UI';

export const SHOW_ALERT_MODAL = `${module}/SHOW_ALERT_MODAL`;
export const HIDE_ALERT_MODAL = `${module}/HIDE_ALERT_MODAL`;

export const SHOW_ADD_UPDATE_MODAL = `${module}/SHOW_ADD_UPDATE_MODAL`;
export const HIDE_ADD_UPDATE_MODAL = `${module}/HIDE_ADD_UPDATE_MODAL`;

export const SHOW_DYNAMIC_MODAL = `${module}/SHOW_DYNAMIC_MODAL`;
export const HIDE_DYNAMIC_MODAL = `${module}/HIDE_DYNAMIC_MODAL`;

export const ADD_OPENED_TAB = `${module}/ADD_OPENED_TAB`;
export const DELETE_OPENED_TAB = `${module}/DELETE_OPENED_TAB`;

export const SET_FIELD = `${module}/SET_FIELD`;
