import * as c from './constants';

const initialState = {
  status: {
    pending: false,
    error: false,
    success: false
  },
  marketMakers: [],
  marketMaker: null,
  exchanges: [],
  sourceSymbols: [],
  targetSymbols: [],
  isDataUpdated: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.LOAD_INITIAL_DATA_PENDING:
    case c.DELETE_MARKET_MAKER_PENDING:
    case c.ADD_UPDATE_MARKET_MAKER_PENDING:
    case c.START_MARKET_MAKER_PENDING:
    case c.STOP_MARKET_MAKER_PENDING: {
      return {
        ...state,
        status: { pending: true, error: false, success: false }
      };
    }

    case c.LOAD_INITIAL_DATA_SUCCESS: {
      const { marketMakers, exchanges } = action.payload;

      return {
        ...state,
        status: { pending: false, error: false, success: true },
        marketMakers,
        exchanges,
        isDataUpdated: true
      };
    }

    case c.LOAD_INITIAL_DATA_ERROR:
    case c.DELETE_MARKET_MAKER_ERROR:
    case c.ADD_UPDATE_MARKET_MAKER_ERROR:
    case c.START_MARKET_MAKER_ERROR:
    case c.STOP_MARKET_MAKER_ERROR: {
      return {
        ...state,
        status: { pending: false, error: action.payload, success: false }
      };
    }

    case c.DELETE_MARKET_MAKER_SUCCESS:
    case c.ADD_UPDATE_MARKET_MAKER_SUCCESS:
    case c.START_MARKET_MAKER_SUCCESS:
    case c.STOP_MARKET_MAKER_SUCCESS: {
      return {
        ...state,
        status: { pending: false, error: false, success: true },
        marketMakers: action.payload,
        isDataUpdated: true
      };
    }

    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.path]: action.payload.value
      };
    }

    case c.SET_DATA_UPDATED: {
      return {
        ...state,
        isDataUpdated: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
