import * as c from './constants';
import { asyncActionCreator, actionCreator } from '../../../utils/redux';

export const loadInitialData = asyncActionCreator(
  c.LOAD_INITIAL_DATA_PENDING,
  c.LOAD_INITIAL_DATA_SUCCESS,
  c.LOAD_INITIAL_DATA_ERROR
);

export const deleteMarketMaker = asyncActionCreator(
  c.DELETE_MARKET_MAKER_PENDING,
  c.DELETE_MARKET_MAKER_SUCCESS,
  c.DELETE_MARKET_MAKER_ERROR
);

export const addUpdateMarketMaker = asyncActionCreator(
  c.ADD_UPDATE_MARKET_MAKER_PENDING,
  c.ADD_UPDATE_MARKET_MAKER_SUCCESS,
  c.ADD_UPDATE_MARKET_MAKER_ERROR
);

export const startMarketMaker = asyncActionCreator(
  c.START_MARKET_MAKER_PENDING,
  c.START_MARKET_MAKER_SUCCESS,
  c.START_MARKET_MAKER_ERROR
);

export const stopMarketMaker = asyncActionCreator(
  c.START_MARKET_MAKER_PENDING,
  c.START_MARKET_MAKER_SUCCESS,
  c.START_MARKET_MAKER_ERROR
);

export const setField = actionCreator(c.SET_FIELD);

export const setDataUpdated = actionCreator(c.SET_DATA_UPDATED);
