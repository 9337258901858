import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import bn from '../../../../utils/bemnames';
import * as constants from '../../../../constants';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
const className = 'cr-modal';
const bem = bn.create('modal');

const initialValues = {
  currencyFrom: '',
  currencyTo: ''
};

class HedgingModal extends React.Component {
  constructInitialValues = () => {
    //AS WE DONT HAVE ABILITY TO UPDATE

    // const entity = this.props.marketMaker;

    //     if (entity)
    //       return {
    //         name: entity.name,
    //       };
    //     else
    return {
      ...initialValues
    };
  };

  //   constructEditForm = () => {
  //     const { exchanges, sourceSymbols, targetSymbols } = this.props;
  //   };

  constructAddForm = () => {
    const { currencies } = this.props;

    return (
      <Formik
        initialValues={this.constructInitialValues()}
        validationSchema={Yup.object().shape({
          currencyFrom: Yup.string().required('Currency from is required'),
          currencyTo: Yup.string().required('Currency to is required')
        })}
        onSubmit={(fields) => {
          let entity = {
            pair: `${fields.currencyFrom.toUpperCase()}/${fields.currencyTo.toUpperCase()}`,
            is_turned_on: false
          };

          this.props.acceptAddUpdateModal(entity);
        }}>
        {({ errors, touched }) => {
          return (
            <Form>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='currencyFrom'>Currency From</label>
                  <Field
                    name='currencyFrom'
                    as='select'
                    className={
                      'form-control' +
                      (errors.currencyFrom && touched.currencyFrom
                        ? ' is-invalid'
                        : '')
                    }>
                    <option value=''></option>
                    {currencies.map((currency, index) => (
                      <option key={index} value={currency.name}>
                        {currency.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name='currencyFrom'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='currencyTo'>Currency To</label>
                  <Field
                    name='currencyTo'
                    as='select'
                    className={
                      'form-control' +
                      (errors.currencyTo && touched.currencyTo
                        ? ' is-invalid'
                        : '')
                    }>
                    <option value=''></option>
                    {currencies.map((currency, index) => (
                      <option key={index} value={currency.name}>
                        {currency.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name='currencyTo'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
              </div>
              <div className='float-right'>
                <button type='submit' className='btn btn-primary'>
                  Accept
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  render() {
    const { show, actionType, entityName } = this.props.addUpdateModal;
    const { hideAddUpdateModal } = this.props;

    return (
      <Modal
        className={className}
        isOpen={show && entityName === constants.HEDGING}
        toggle={hideAddUpdateModal}>
        <ModalHeader
          className={`${bem.e('header')}`}
          toggle={hideAddUpdateModal}>
          {`${actionType} Hedging Pair`}
        </ModalHeader>
        <ModalBody>
          {actionType === constants.ADD && this.constructAddForm()}
        </ModalBody>
      </Modal>
    );
  }
}

export default HedgingModal;
