import NewPage from 'components/NewPage';
import React from 'react';
import { Col, Row } from 'reactstrap';
import ErrorComp from '../../../../components/ErrorComp';
import * as constants from '../../../../constants';
import MonitoringTable from 'components/MonitoringTable';

class MarketMakerLivePrices extends React.Component {
  constructor(props) {
    super(props);

    this.table = React.createRef();
  }

  componentDidMount() {
    this.props.loadInitialData(constants.MARKET_MAKER);
    this.props.addOpenedTab({
      to: '/monitoring/market-maker-live-prices',
      name: 'MM live prices'
    });

    this.interval = setInterval(() => {
      this.props.loadBests(constants.MARKET_MAKER);
    }, 2000);
  }

  componentDidUpdate() {
    const { openedTabs } = this.props;

    if (
      !openedTabs.find(
        (openedTab) => openedTab.to === '/monitoring/market-maker-live-prices'
      )
    ) {
      this.props.history.push(openedTabs[openedTabs.length - 1].to);
    }
  }

  componentWillUnmount() {
    this.props.setField('exchanges', []);
    this.props.setField('marketMakers', []);
    this.props.setField('symbols', []);
    this.props.setField('bests', []);

    if (this.interval) clearInterval(this.interval);
  }

  render() {
    const {
      status: { error }
    } = this.props;

    const { marketMakers, bests } = this.props;

    return (
      <NewPage
        title=''
        breadcrumbs={[{ name: 'market maker live prices', active: true }]}
        disableAddButton>
        <Row>
          <Col md='12' sm='12' xs='12'>
            {error && <ErrorComp error={error}></ErrorComp>}
            <MonitoringTable columns={marketMakers} data={bests} withoutSymbolColumn/>
          </Col>
        </Row>
      </NewPage>
    );
  }
}

export default MarketMakerLivePrices;
