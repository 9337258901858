import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import Checkbox from '../../Checkbox';
import InputsPair from '../../InputsPair';

import Exchanges from '../../../../../../redux/modules/Exchanges';
import { isTrueValue } from '../../../../../../utils/common';

const BinanceAndCoinbaseProClient = ({ exchange, setUIField }) => {
  const [sandboxChecked, setSandboxChecked] = useState('false');
  const [sourceChecked, setSourceChecked] = useState('false');
  const [alertSourceChecked, setAlertSourceChecked] = useState('false');
  const [passphrase, setPassphrase] = useState('');

  useEffect(() => {
    if (isEmpty(exchange.settings)) return;
    Object.keys(exchange.settings).forEach((key) => {
      switch (key) {
        case 'useSandbox':
          setSandboxChecked(exchange.settings[key]);
          break;
        case 'source':
          setSourceChecked(exchange.settings[key]);
          break;
        case 'alertSource':
          setAlertSourceChecked(exchange.settings[key]);
          break;
        case 'passphrase':
          setPassphrase(exchange.settings[key]);
          break;
      }
    });
    return () => resetState();
  }, [exchange.settings]);

  const resetState = () => {
    setSandboxChecked('false');
    setSourceChecked('false');
    setAlertSourceChecked('false');
    setPassphrase('');
  };

  const handleChangeCheckbox = ({ target: { name, checked } }) => {
    let settings = {
      ...exchange.settings,
      [name]: checked.toString()
    };
    setUIField('exchange', { ...exchange, settings });
  };

  const handleChangePassphrase = ({ target: { value } }) => {
    let settings = { ...exchange.settings, passphrase: value };
    setUIField('exchange', { ...exchange, settings });
  };

  return (
    <>
      <div className='row'>
        <div className='form-group col-6'>
          <Checkbox
            title='Use Sandbox'
            checked={isTrueValue(sandboxChecked)}
            name='useSandbox'
            onChange={handleChangeCheckbox}
          />
        </div>
      </div>
      <div className='row'>
        <div className='form-group col-6'>
          <Checkbox
            title='Orderbook Source'
            checked={isTrueValue(sourceChecked)}
            name='source'
            onChange={handleChangeCheckbox}
          />
        </div>
      </div>
      <div className='row'>
        <div className='form-group col-6'>
          <Checkbox
            title='Alert Source'
            checked={isTrueValue(alertSourceChecked)}
            name='alertSource'
            onChange={handleChangeCheckbox}
          />
        </div>
      </div>
      {exchange.exchangeClient === 'coinbase_pro' && (
        <InputsPair
          input1Label='Key name'
          input2Label='Key value'
          input1Props={{ value: 'passphrase', disabled: true }}
          input2Props={{
            value: passphrase,
            onChange: handleChangePassphrase
          }}
        />
      )}
    </>
  );
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      setUIField: Exchanges.actions.setField
    },
    dispatch
  );
};

export default connect(null, mapActionsToProps)(BinanceAndCoinbaseProClient);
