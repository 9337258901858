const module = 'CURRENCIES';

export const LOAD_CURRENCIES_PENDING = `${module}/LOAD_CURRENCIES_PENDING`;
export const LOAD_CURRENCIES_SUCCESS = `${module}/LOAD_CURRENCIES_SUCCESS`;
export const LOAD_CURRENCIES_ERROR = `${module}/LOAD_CURRENCIES_ERROR`;

export const DELETE_CURRENCY_PENDING = `${module}/DELETE_CURRENCY_PENDING`;
export const DELETE_CURRENCY_SUCCESS = `${module}/DELETE_CURRENCY_SUCCESS`;
export const DELETE_CURRENCY_ERROR = `${module}/DELETE_CURRENCY_ERROR`;

export const ADD_UPDATE_CURRENCY_PENDING = `${module}/ADD_UPDATE_CURRENCY_PENDING`;
export const ADD_UPDATE_CURRENCY_SUCCESS = `${module}/ADD_UPDATE_CURRENCY_SUCCESS`;
export const ADD_UPDATE_CURRENCY_ERROR = `${module}/ADD_UPDATE_CURRENCY_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;

export const SET_DATA_UPDATED = `${module}/SET_DATA_UPDATED`;
