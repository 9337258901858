import React from 'react';
import { isEmpty } from 'lodash';

import AccessKeys from '../AccessKeys';
import ParamountdaxClient from './ParamountdaxClient';
import BinanceAndCoinbaseProClient from './BinanceAndCoinbaseProClient';

const ExchangeSettings = ({ exchange, editSettings }) => {
  const generateSettings = () => {
    switch (exchange.exchangeClient) {
      case 'paramountdax':
        return <ParamountdaxClient exchange={exchange} />;
      default:
        return <BinanceAndCoinbaseProClient exchange={exchange} />;
    }
  };

  const handleSaveSettings = () => {
    if (!exchange) return;

    const data = {};
    if (exchange.accessKeys.length) {
      const accessKey = {};
      if (exchange.accessKeys[0].key && exchange.accessKeys[0].value) {
        accessKey.key = exchange.accessKeys[0].key;
        accessKey.value = exchange.accessKeys[0].value;
        data.accessKey = accessKey;
      }
    }
    if (!isEmpty(exchange.settings)) {
      data.settings = exchange.settings;
      editSettings(exchange.name, data);
    }
  };

  return (
    <>
      <div>{generateSettings()}</div>
      <AccessKeys exchange={exchange} />
      <div className='d-flex flex-row-reverse'>
        <button
          className='btn btn-sm btn-primary mr-2'
          onClick={handleSaveSettings}>
          Save
        </button>
      </div>
    </>
  );
};

export default ExchangeSettings;
