import * as c from './constants';

//by default one week
const defaultEndDay = new Date();
defaultEndDay.setHours(0, 0, 0, 0);
const defaultStartDay = new Date();
defaultStartDay.setDate(defaultEndDay.getDate() - 6);

const initialState = {
  status: {
    pending: false,
    error: false,
    success: false
  },
  pnlBalances: [],
  filters: {
    startDay: defaultStartDay,
    endDay: defaultEndDay
  },
  meta: {
    page: 1,
    limit: 50,
    count: 0
  },
  isDataUpdated: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.LOAD_DATA_PENDING: {
      return {
        ...state,
        status: { pending: true, error: false, success: false }
      };
    }

    case c.LOAD_DATA_SUCCESS: {
      const count = Math.ceil(
        action.payload.pnlBalances.length / state.meta.limit
      );

      return {
        ...state,
        status: { pending: false, error: false, success: true },
        pnlBalances: action.payload.pnlBalances,
        meta: {
          ...state.meta,
          count
        },
        isDataUpdated: true
      };
    }

    case c.LOAD_DATA_ERROR: {
      return {
        ...state,
        status: { pending: false, error: action.payload, success: false }
      };
    }
    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.path]: action.payload.value
      };
    }

    case c.RESET_META: {
      return {
        ...state,
        meta: {
          ...initialState.meta
        }
      };
    }

    case c.SET_PAGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          page: action.payload
        }
      };
    }

    case c.SET_LIMIT: {
      const limit = action.payload;

      return {
        ...state,
        meta: {
          page: 1,
          limit,
          count: Math.ceil(state.pnlBalances.length / limit)
        }
      };
    }

    case c.RESET_PAGE: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
};
