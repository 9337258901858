import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import bn from '../../../../utils/bemnames';
import * as constants from '../../../../constants';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import FormikInputNumber from '../../../FormikInputNumber';
import * as Yup from 'yup';
const className = 'cr-modal';
const bem = bn.create('modal');

const getInitialValues = (activityGeneratorAlgo) => {
  let baseValues = {
    name: '',
    algorithm: activityGeneratorAlgo,
    targetExchange: '',
    targetSymbol: ''
  };

  if (activityGeneratorAlgo === constants.ACTIVITY_GEN_TIMED_TRADER_ALGO)
    return {
      ...baseValues,
      minAmount: '0',
      maxAmount: '0',
      minDelayMs: '0',
      maxDelayMs: '0',
      maxSpreadDiff: '5',
      maxSpreadTradeAmount: '0',
      spreadTradePct: '30'
    };
  else if (
    activityGeneratorAlgo === constants.ACTIVITY_GEN_PRICE_SPIKES_TRADER_ALGO
  ) {
    return {
      ...baseValues,
      baseMinAmount: '0',
      baseMaxAmount: '0',
      changePercentForTrade: '0',
      priceCheckDelayMs: '0',
      priceToAmountChangeRatio: '0',
      tracedAmountResetIntervalS: '0'
    };
  }
};

class ActivityGeneratorModal extends React.Component {
  constructInitialValues = () => {
    const {
      activityGenerator: entity,
      activityGeneratorAlgorithm
    } = this.props;

    if (entity) {
      let initialBaseValues = {
        name: entity.name,
        algorithm: entity.algorithm,
        targetExchange: entity.targetExchange,
        targetSymbol: entity.targetSymbol
      };

      if (entity.algorithm === constants.ACTIVITY_GEN_TIMED_TRADER_ALGO)
        return {
          ...initialBaseValues,
          minAmount: entity.settings.minAmount,
          maxAmount: entity.settings.maxAmount,
          minDelayMs: entity.settings.minDelayMs,
          maxDelayMs: entity.settings.maxDelayMs,
          maxSpreadDiff: entity.settings.maxSpreadDiff,
          maxSpreadTradeAmount: entity.settings.maxSpreadTradeAmount,
          spreadTradePct: entity.settings.spreadTradePct
        };
      else if (
        entity.algorithm === constants.ACTIVITY_GEN_PRICE_SPIKES_TRADER_ALGO
      )
        return {
          ...initialBaseValues,
          baseMinAmount: entity.settings.baseMinAmount,
          baseMaxAmount: entity.settings.baseMaxAmount,
          changePercentForTrade: entity.settings.changePercentForTrade,
          priceCheckDelayMs: entity.settings.priceCheckDelayMs,
          priceToAmountChangeRatio: entity.settings.priceToAmountChangeRatio,
          tracedAmountResetIntervalS: entity.settings.tracedAmountResetIntervalS
        };
    } else return getInitialValues(activityGeneratorAlgorithm);
  };

  constructForm = () => {
    const { exchanges, targetSymbols, activityGeneratorAlgorithm } = this.props;
    const { actionType } = this.props.addUpdateModal;

    let validationSchemaObject = {
      name: Yup.string().required('Activity generator name is required'),
      targetExchange: Yup.string().required('Target exchange is required'),
      targetSymbol: Yup.string().required('Target symbol is required')
    };

    if (activityGeneratorAlgorithm === constants.ACTIVITY_GEN_TIMED_TRADER_ALGO)
      validationSchemaObject = {
        ...validationSchemaObject,
        minAmount: Yup.number()
          .required('Min trade amount is required')
          .lessThan(
            Yup.ref('maxAmount'),
            'Min trade amount must be less than max trade amount'
          ),
        maxAmount: Yup.number()
          .required('Max trade amount is required')
          .moreThan(
            Yup.ref('minAmount'),
            'Max trade amount must be more than min trade amount'
          ),
        minDelayMs: Yup.number()
          .integer('Min delay must be integer')
          .required('Min delay is required')
          .lessThan(
            Yup.ref('maxDelayMs'),
            'Min delay MS must be less than max delay MS'
          ),
        maxDelayMs: Yup.number()
          .integer('Max delay must be integer')
          .required('Max delay is required')
          .moreThan(
            Yup.ref('minDelayMs'),
            'Max delay MS must be more than min delay MS'
          ),
        maxSpreadDiff: Yup.number()
          .positive('Max spread diff must be positive')
          .required('Max spread diff is required'),
        maxSpreadTradeAmount: Yup.number()
          .positive('Max spread trade amount must be positive')
          .required('Max spread trade amount is required'),
        spreadTradePct: Yup.number()
          .positive('Spread trade % must be positive')
          .required('Spread trade % is required')
      };
    else if (
      activityGeneratorAlgorithm ===
      constants.ACTIVITY_GEN_PRICE_SPIKES_TRADER_ALGO
    ) {
      validationSchemaObject = {
        ...validationSchemaObject,
        baseMinAmount: Yup.number()
          .positive('Base min trade amount must be positive')
          .required('Base min trade amount is required')
          .lessThan(
            Yup.ref('baseMaxAmount'),
            'Base min trade amount must be less than base max trade amount'
          ),
        baseMaxAmount: Yup.number()
          .positive('Base max trade amount must be positive')
          .required('Base max trade amount is required')
          .moreThan(
            Yup.ref('baseMinAmount'),
            'Base max trade amount must be more than base min trade amount'
          ),
        changePercentForTrade: Yup.number()
          .positive('Change trade % must be positive')
          .required('Change trade % is required'),
        priceCheckDelayMs: Yup.number()
          .integer('Price check delay MS must be integer')
          .positive('Price check delay MS must be positive')
          .required('Price check delay MS is required'),
        priceToAmountChangeRatio: Yup.number()
          .integer('Change ratio price to amount is integer')
          .required('Change ratio price to amount is required'),
        tracedAmountResetIntervalS: Yup.number()
          .integer('Traced amount reset interval S must be integer')
          .positive('Traced amount reset interval S must be positive')
          .required('Traced amount reset interval S is required')
      };
    }

    return (
      <Formik
        initialValues={this.constructInitialValues()}
        validationSchema={Yup.object().shape(validationSchemaObject)}
        onSubmit={(fields) => {
          let entity = {
            name: fields.name,
            algorithm: fields.algorithm,
            targetExchange: fields.targetExchange,
            targetSymbol: fields.targetSymbol
          };

          if (
            activityGeneratorAlgorithm ===
            constants.ACTIVITY_GEN_TIMED_TRADER_ALGO
          ) {
            entity = {
              ...entity,
              settings: {
                minAmount: fields.minAmount,
                maxAmount: fields.maxAmount,
                minDelayMs: fields.minDelayMs,
                maxDelayMs: fields.maxDelayMs,
                maxSpreadDiff: fields.maxSpreadDiff,
                maxSpreadTradeAmount: fields.maxSpreadTradeAmount,
                spreadTradePct: fields.spreadTradePct
              }
            };
          } else if (
            activityGeneratorAlgorithm ===
            constants.ACTIVITY_GEN_PRICE_SPIKES_TRADER_ALGO
          ) {
            entity = {
              ...entity,
              settings: {
                baseMinAmount: fields.baseMinAmount,
                baseMaxAmount: fields.baseMaxAmount,
                changePercentForTrade: fields.changePercentForTrade,
                priceCheckDelayMs: fields.priceCheckDelayMs,
                priceToAmountChangeRatio: fields.priceToAmountChangeRatio,
                tracedAmountResetIntervalS: fields.tracedAmountResetIntervalS
              }
            };
          }

          this.props.acceptAddUpdateModal(entity);
        }}>
        {({ errors, touched }) => {
          return (
            <Form>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='name'>Activity Generator</label>
                  <Field
                    name='name'
                    type='text'
                    className={
                      'form-control' +
                      (errors.name && touched.name ? ' is-invalid' : '')
                    }
                    disabled={actionType === constants.UPDATE}
                  />
                  <ErrorMessage
                    name='name'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='algorithm'>Algorithm</label>
                  <Field
                    name='algorithm'
                    type='text'
                    className={'form-control'}
                    disabled
                  />
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='targetExchange'>Target Exchange</label>
                  <Field name='targetExchange'>
                    {({ field, form, meta }) => (
                      <div>
                        <select
                          {...field}
                          disabled={actionType === constants.UPDATE}
                          className={
                            'form-control' +
                            (meta.error && meta.touched ? ' is-invalid' : '')
                          }
                          onChange={(e) => {
                            const { value } = e.target;
                            form.setFieldValue('targetSymbol', '');
                            this.props.loadSymbols(value, 'targetSymbols');
                            field.onChange(e);
                          }}>
                          <option value=''></option>
                          {exchanges.map((exchange, index) => (
                            <option key={index} value={exchange.name}>
                              {exchange.name}
                            </option>
                          ))}
                        </select>
                        {meta.touched && meta.error && (
                          <div className='invalid-feedback'>{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='targetSymbol'>Target Symbols</label>
                  <Field
                    name='targetSymbol'
                    as='select'
                    className={
                      'form-control' +
                      (errors.targetSymbol && touched.targetSymbol
                        ? ' is-invalid'
                        : '')
                    }
                    disabled={actionType === constants.UPDATE}>
                    <option value=''></option>
                    {targetSymbols.map((targetSymbol, index) => (
                      <option type={index} value={targetSymbol.internalSymbol}>
                        {targetSymbol.internalSymbol}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name='targetSymbol'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
              </div>

              {activityGeneratorAlgorithm ===
                constants.ACTIVITY_GEN_TIMED_TRADER_ALGO && (
                <>
                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='minAmount'>Min Trade Amount</label>
                      <FormikInputNumber name='minAmount' />
                    </div>
                    <div className='form-group col-6'>
                      <label htmlFor='maxAmount'>Max Trade Amount</label>
                      <FormikInputNumber name='maxAmount' />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='minDelayMs'>Min Delay MS</label>
                      <FormikInputNumber name='minDelayMs' />
                    </div>
                    <div className='form-group col-6'>
                      <label htmlFor='maxDelayMs'>Max Delay MS</label>
                      <FormikInputNumber name='maxDelayMs' />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='spreadTradePct'>Spread Trade %</label>
                      <FormikInputNumber name='spreadTradePct' />
                    </div>
                    <div className='form-group col-6'>
                      <label htmlFor='maxSpreadDiff'>Max Spread Diff, %</label>
                      <FormikInputNumber name='maxSpreadDiff' />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='maxSpreadTradeAmount'>
                        Max Spread Trade Amount
                      </label>
                      <FormikInputNumber name='maxSpreadTradeAmount' />
                    </div>
                  </div>
                </>
              )}
              {activityGeneratorAlgorithm ===
                constants.ACTIVITY_GEN_PRICE_SPIKES_TRADER_ALGO && (
                <>
                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='baseMinAmount'>
                        Base Min Trade Amount
                      </label>
                      <FormikInputNumber name='baseMinAmount' />
                    </div>
                    <div className='form-group col-6'>
                      <label htmlFor='baseMaxAmount'>
                        Base Max Trade Amount
                      </label>
                      <FormikInputNumber name='baseMaxAmount' />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='changePercentForTrade'>
                        Change Trade %
                      </label>
                      <FormikInputNumber name='changePercentForTrade' />
                    </div>
                    <div className='form-group col-6'>
                      <label htmlFor='priceCheckDelayMs'>
                        Price Check Delay MS
                      </label>
                      <FormikInputNumber name='priceCheckDelayMs' />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='form-group col-6'>
                      <label htmlFor='priceToAmountChangeRatio'>
                        Change Ratio Price to Amount
                      </label>
                      <FormikInputNumber name='priceToAmountChangeRatio' />
                    </div>
                    <div className='form-group col-6'>
                      <label htmlFor='tracedAmountResetIntervalS'>
                        Traced Amount Reset Interval S
                      </label>
                      <FormikInputNumber name='tracedAmountResetIntervalS' />
                    </div>
                  </div>
                </>
              )}

              <div className='row'>
                <div className='col-12 d-flex'>
                  <button type='submit' className='btn btn-primary ml-auto'>
                    Accept
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  render() {
    const { show, actionType, entityName } = this.props.addUpdateModal;
    const { hideAddUpdateModal } = this.props;

    return (
      <Modal
        className={className}
        isOpen={show && entityName === constants.ACTIVITY_GENERATOR}
        toggle={hideAddUpdateModal}>
        <ModalHeader
          className={`${bem.e('header')}`}
          toggle={hideAddUpdateModal}>
          {`${actionType} Activity Generator`}
        </ModalHeader>
        <ModalBody>{this.constructForm()}</ModalBody>
      </Modal>
    );
  }
}

export default ActivityGeneratorModal;
