//entities
export const CURRENCY = 'currency';
export const SYMBOL = 'symbol';
export const EXCHANGE = 'exchange';
export const PNL_BALANCE = 'pnlBalance';
export const MARKET_MAKER = 'marketMaker';
export const MARKET_MAKER_ORDER_BOOK = 'marketMakerOrderBook';
export const ACTIVITY_GENERATOR = 'activityGenerator';
export const ACTIVITY_GEN_SIMULATION = 'activityGeneratorSimulation';
export const FEED_GENERATOR = 'feedGenerator';
export const HEDGING = 'hedging';
export const EVENT = 'event';
export const HEDGING_EXCHANGE_KEY = 'hedgingExchangeKey';

//action type
export const UPDATE = 'update';
export const ADD = 'add';
export const DELETE = 'delete';
export const CANCEL = 'cancel';
export const MM_START = 'MMStart';
export const MM_STOP = 'MMStop';
export const AG_START = 'AGStart';
export const AG_STOP = 'AGStop';
export const FG_START = 'FGStart';
export const FG_STOP = 'FGStop';
export const HEDGING_START = 'HedgingStart';
export const HEDGING_STOP = 'HedgingStop';
export const ACCEPT = 'accept';
export const INFO = 'info';
export const RECONNECT = 'reconnect';
export const EDIT_EXCHANGE = 'editExchange';

//exchange client
export const EXCHANGE_CLIENT_PARAMOUNTDAX = 'paramountdax';

//MM status
export const MM_STATUS_STOPPED = 'stopped';
export const MM_STATUS_RUNNING = 'running';
export const AG_STATUS_STOPPED = 'stopped';
export const AG_STATUS_RUNNING = 'running';
export const FG_STATUS_STOPPED = 'stopped';
export const FG_STATUS_RUNNING = 'running';
export const FG_STATUS_STARTED = 'started';

//feed generator algorithms
export const FEED_GEN_RAND_WALK_ALGO = 'RandomWalker';
export const FEED_GEN_TIME_BUFFER_ALGO = 'TimedBuffer';
export const FEED_GEN_BOOK_SCALER_ALGO = 'BookScaler';

//activity generator algorithms
export const ACTIVITY_GEN_TIMED_TRADER_ALGO = 'TimedTrader';
export const ACTIVITY_GEN_PRICE_SPIKES_TRADER_ALGO = 'PriceSpikesTrader';

//event types
export const EVENT_TYPE_ERROR = 'error';
export const EVENT_TYPE_SETTINGS = 'settings';
