import React from 'react';
import { Table, Input } from 'reactstrap';
import CustomPagination from './CustomPagination';

class CustomTable extends React.Component {
  renderThead = () => {
    const { columns } = this.props;

    return (
      <thead>
        <tr>
          {columns.map((column, index) => {
            return (
              <th key={index} className={column.className || ''}>
                {column.header}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  renderTd = (column, row) => {
    if (column.inputType === 'textarea') {
      return <Input type='textarea' readOnly value={row[column.accessor]} />;
    }

    return row[column.accessor];
  };

  renderTbody() {
    const { data, columns, meta } = this.props;
    let begin = 0;
    let end = data.length;

    if (this.props.withPagination) {
      begin = (meta.page - 1) * meta.limit;
      end = begin + parseInt(meta.limit);
    }

    return (
      <tbody>
        {data.slice(begin, end).map((row, index) => {
          let rowClassName = '';

          return (
            <tr key={index} className={rowClassName}>
              {columns.map((column, index) => {
                let columnClassName = 'align-middle';
                if (column.className)
                  columnClassName = `${column.className} ${columnClassName}`;

                return (
                  <td key={index} className={columnClassName}>
                    {this.renderTd(column, row)}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  }

  render() {
    let className = 'table table-striped table-bordered text-center';
    if (this.props.className)
      className = `${className} ${this.props.className}`;
    const { meta, setPage, setLimit } = this.props;

    return (
      <>
        {this.props.withPagination && (
          <CustomPagination meta={meta} setPage={setPage} setLimit={setLimit} />
        )}
        <div className='table-wrapper'>
          <Table className={className}>
            {this.renderThead()}
            {this.renderTbody()}
          </Table>
        </div>
      </>
    );
  }
}

export default CustomTable;
