import * as ac from './actionCreators';
import * as constants from '../../../constants';
import * as api from '../../../api';
import { getErrorMessage } from '../../../utils/error';

export function loadInitialData() {
  return async (dispatch) => {
    dispatch(ac.loadInitialData.pending());

    try {
      //TODO: to make promise all
      const symbols = await api.getSymbols();
      symbols.sort((a, b) => (a.name > b.name ? 1 : -1));

      const currencies = await api.getCurrencies();
      currencies.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.loadInitialData.success({ symbols, currencies }));
    } catch (err) {
      dispatch(ac.loadInitialData.error(getErrorMessage(err)));
    }
  };
}

export function addUpdateSymbol(symbol) {
  return async (dispatch, getState) => {
    dispatch(ac.addUpdateSymbol.pending());

    try {
      const { actionType } = getState().UI.addUpdateModal;

      if (actionType === constants.UPDATE) {
        await api.updateSymbol(symbol.name, {
          settings: { ...symbol.settings }
        });
      } else {
        await api.addSymbol(symbol);
      }

      const symbols = await api.getSymbols();
      symbols.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(ac.addUpdateSymbol.success(symbols));
    } catch (err) {
      dispatch(ac.addUpdateSymbol.error(getErrorMessage(err)));
    }
  };
}

export function setField(path, value) {
  return (dispatch) => dispatch(ac.setField({ path, value }));
}

export function setDataUpdated(isDataUpdated) {
  return (dispatch) => dispatch(ac.setDataUpdated(isDataUpdated));
}
