import React from 'react';

const Checkbox = ({ title, ...props }) => {
  return (
    <>
      <label className='first-letter-upper'>{title}</label>
      <input type='checkbox' className='form-control' {...props} />
    </>
  );
};

export default Checkbox;
