import * as c from './constants';
import { asyncActionCreator, actionCreator } from '../../../utils/redux';

export const loadExchangeKeys = asyncActionCreator(
  c.LOAD_EXCHANGE_KEYS_PENDING,
  c.LOAD_EXCHANGE_KEYS_SUCCESS,
  c.LOAD_EXCHANGE_KEYS_ERROR
);

export const loadExchangeKey = asyncActionCreator(
  c.LOAD_EXCHANGE_KEY_PENDING,
  c.LOAD_EXCHANGE_KEY_SUCCESS,
  c.LOAD_EXCHANGE_KEY_ERROR
);

export const deleteExchangeKey = asyncActionCreator(
  c.DELETE_EXCHANGE_KEY_PENDING,
  c.DELETE_EXCHANGE_KEY_SUCCESS,
  c.DELETE_EXCHANGE_KEY_ERROR
);

export const addUpdateExchangeKey = asyncActionCreator(
  c.ADD_UPDATE_EXCHANGE_KEY_PENDING,
  c.ADD_UPDATE_EXCHANGE_KEY_SUCCESS,
  c.ADD_UPDATE_EXCHANGE_KEY_ERROR
);

export const setField = actionCreator(c.SET_FIELD);

export const setDataUpdated = actionCreator(c.SET_DATA_UPDATED);
