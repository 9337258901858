import React from 'react';
import * as constants from '../../../constants';

class AddEntityButton extends React.Component {
  handleClick = () => {
    const { currentEntityName } = this.props;

    this.props.showAddUpdateModal({
      entityName: currentEntityName,
      actionType: constants.ADD
    });
  };

  render() {
    return (
      <button
        className='btn btn-sm btn-primary ml-auto mr-2'
        type='button'
        onClick={this.handleClick}>
        <i className='fas fa-plus'></i> Add
      </button>
    );
  }
}

export default AddEntityButton;
