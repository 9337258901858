import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ExchangeLivePrices from './components';
import Monitoring from '../../../redux/modules/Monitoring';
import UI from '../../../redux/modules/UI';

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      loadInitialData: Monitoring.actions.loadInitialData,
      loadBests: Monitoring.actions.loadBests,
      setField: Monitoring.actions.setField,
      addOpenedTab: UI.actions.addOpenedTab
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  status: Monitoring.selectors.status(state),
  //   exchanges: Monitoring.selectors.exchanges(state),
  marketMakers: Monitoring.selectors.marketMakers(state),
  symbols: Monitoring.selectors.symbols(state),
  bests: Monitoring.selectors.bests(state),
  openedTabs: UI.selectors.openedTabs(state)
});

export default connect(mapStateToProps, mapActionsToProps)(ExchangeLivePrices);
