import React, { memo } from 'react';
import RCTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

const Tooltip = ({
  placement,
  overlay,
  arrowContent,
  children,
  ...additionalProps
}) => {
  return (
    <RCTooltip
      mouseEnterDelay={1}
      placement={placement || 'top'}
      overlay={<span className='center-align'>{overlay}</span>}
    //   overlayClassName={overlayClassName || 'tooltip-overlay'}
      arrowContent={arrowContent || <div className='rc-tooltip-arrow-inner' />}
    //   getTooltipContainer={() => document.querySelector('.v1 > div')}
      {...additionalProps}>
      {children}
    </RCTooltip>
  );
};

export default memo(Tooltip);
