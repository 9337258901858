const module = 'MARKET_MAKER_ORDER_BOOKS';

export const LOAD_INITIAL_DATA_PENDING = `${module}/LOAD_INITIAL_DATA_PENDING`;
export const LOAD_INITIAL_DATA_SUCCESS = `${module}/LOAD_INITIAL_DATA_SUCCESS`;
export const LOAD_INITIAL_DATA_ERROR = `${module}/LOAD_INITIAL_DATA_ERROR`;

export const LOAD_DATA_PENDING = `${module}/LOAD_DATA_PENDING`;
export const LOAD_DATA_SUCCESS = `${module}/LOAD_DATA_SUCCESS`;
export const LOAD_DATA_ERROR = `${module}/LOAD_DATA_ERROR`;

export const RESET_PAGE = `${module}/RESET_PAGE`;

export const SET_FIELD = `${module}/SET_FIELD`;

export const SET_DATA_UPDATED = `${module}/SET_DATA_UPDATED`;
